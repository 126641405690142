import React, { useState, useRef } from 'react';
import { useLocation,Link,useHistory } from "react-router-dom";
import styled from 'styled-components';
import userimage from '../../assets/images/user.jpg';
import moment from 'moment-timezone';
import { Row, Col,Button, CardBody, Alert, Container, Input, Label,  Modal,Text } from "reactstrap";
import { AvForm, AvField,AvInput } from "availity-reactstrap-validation";
import {
  CardMeta,
  CardHeader,
  CardDescription,
  CardContent,
  Card,
  Icon,
  Image,GridColumn, GridRow,Grid,ListItem, ListHeader, List,ListContent,
} from 'semantic-ui-react'
//import { PaperClipIcon } from '@heroicons/react/20/solid'
const OtpContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const OtpInput = styled.input`
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 18px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 0 5px;

  &:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  }

  @media (max-width: 600px) {
    width: 30px;
    height: 30px;
  }
`;

//const Formdatapribadi = ({ numDigits,onOtpChange,aktifasi  }) => {
//,backgroundColor:'#E3EDF8'  alamatuser={alamat2} emailuser={email2} nouser={nohp2} companyuser={company2}
const DatalistBayar = ({namauser,datapembayaran}) => {
	 const history = useHistory();
	
	const tglOrder=(tglcreate)=>{
		const tgl = new Date(tglcreate);
		const tglkonversi = moment(tgl).tz('Asia/Bangkok');
		const monthNames = [
				'Januari', 'Februari', 'Maret', 'April',
				'Mei', 'Juni', 'Juli', 'Agustus',
				'September', 'Oktober', 'November', 'Desember'
				];
		const tahun = tgl.getFullYear();
		const monthIndex = tgl.getMonth();
		const monthName = monthNames[monthIndex];
		const tglfix = tgl.getDate();
		//seTgltransaksi(`${tglfix}`)
		const hasil=tglfix+' '+monthName+' '+tahun
		return hasil;
}

const tglExp=(tglexpire)=>{
	    const tgl = new Date(tglexpire);
		const tglkonversi = moment(tgl).tz('Asia/Bangkok');
				const monthNames = [
				'Jan', 'Feb', 'Maret', 'April',
				'Mei', 'Juni', 'Juli', 'Agustus',
				'Sep', 'Okt', 'Nov', 'Des'
				];
		const jamnya=tglkonversi.format('HH:mm');
		const monthIndex = tgl.getMonth();
		const monthName = monthNames[monthIndex];
		const tglfix = tgl.getDate();
		const tglexpired =tglfix+' '+monthName+','+jamnya
		return tglexpired;
}

  const convertToUppercase = (inputString) => {
    return inputString.toUpperCase();
   };
   
       const formatAsRupiah = (number) => {
    return new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
    }).format(number);
  };

  	  const kembali=()=>{
        history.push("/");
   }

  return (
  
   <div className="row" style={{padding:'0px'}} >
       <div className="row">
		  <div  onClick={()=>kembali()} color="primary" style={{width:'85px',cursor:'pointer',height:'15px',position:'absolute',marginLeft:'-13px',zIndex:'4',borderTop:'15px solid #555',borderRight:' 15px solid transparent',borderBottom:' 15px solid #555',borderTopLeftRadius:'10px'}}>
			<span style={{color:'#fff',position:'absolute',marginTop:'-8px',marginLeft:'-4px',fontSize:'12px'}}>Kembali</span>
		  </div>
	   </div>
	    <div className="row justify-content-end bg-light"  style={{height:'100px',borderRadius:'10px'}}>
	        <h4 className="text-base font-bold mt-5 leading-4 text-gray-900 sm:truncate sm:text-base sm:tracking-tight">Menunggu Pembayaran</h4>
			 <p style={{marginTop:'-6px'}} className="text-sm leading-2 text-gray-300">{namauser}</p>
       </div>
	    <div style={{marginTop:'15px'}} className="row justify-content-center" >
		{datapembayaran.map(itembayar=>{
			return(
			     <div style={{borderBottom:'2px solid #ccc', paddingBottom:'35px',paddingTop:'10px'}}>
				 
				 		<Grid >
						     <GridRow>
									<GridColumn width={9}>
									   <List>
											<ListItem>
											  <ListHeader>Order</ListHeader>
											  {tglOrder(itembayar.tgltransaksi)}
											</ListItem>
											<ListItem>
											  <ListHeader>Metode pembayaran</ListHeader>
											  {convertToUppercase(itembayar.akunbank)}
											</ListItem>
											<ListItem>
											  <ListHeader>Nomor Virtual Account</ListHeader>
											  <span style={{fontSize:'16px'}}>{itembayar.kodegatepay}</span><span><Icon name="copy" /></span>
											</ListItem>
									   </List>
									</GridColumn>
									<GridColumn width={6}>
										 <List>
											<ListItem>
											  <span >Bayar sebelum</span>
											   <span style={{fontWeight:'900'}}>{tglExp(itembayar.expiretime)}</span>
											</ListItem>
										</List>
										<Grid>
											 <GridColumn>
												 <List>
														<ListItem>
														  <ListHeader><span style={{fontSize:'12px'}}>Total Pembayaran</span></ListHeader>
														  {formatAsRupiah(itembayar.jumlahbayar)}
														</ListItem>
												 </List>
											 </GridColumn>
										</Grid>
									</GridColumn>
								
							 </GridRow>
							 
							
							
						</Grid>
							 <Grid columns='equal' divided className="border-bottom" >
										<GridRow style={{height:'30px'}} >
										  <GridColumn className="bg-success" textAlign='center' style={{borderRadius:'4px',height:'30px'}}>
											
											  <span style={{fontWeight:'450',marginTop:'5px',color:'#fff'}}>Lihat detail</span>
											
										  </GridColumn>
										  <GridColumn className="bg-success" textAlign='center' style={{borderRadius:'4px',height:'30px',marginLeft:'5px'}}>
											
											  <span style={{fontWeight:'450',marginTop:'5px',color:'#fff'}}>Cara pembayaran</span>
											
										  </GridColumn>
										
										</GridRow>
									  </Grid>
				
				{/* <List>
					    <ListItem>
						  <ListContent floated='right'>
							 <ListHeader>Bayar sebelum</ListHeader>
						    {tglExp(itembayar.expiretime)}
						  </ListContent>
						  
						 
						  <ListContent>
						    <ListHeader>Order</ListHeader>
							{tglOrder(itembayar.tgltransaksi)}
						  </ListContent>
						</ListItem>
						
						<ListItem>
						  <ListHeader>Chicago</ListHeader>
						  Also quite a lovely city
						</ListItem>
						<ListItem>
						  <ListHeader>Los Angeles</ListHeader>
						  Sometimes can be a lovely city
						</ListItem>
						<ListItem>
						  <ListHeader>San Francisco</ListHeader>
						  What a lovely city
				        </ListItem>
					
				  </List>*/}
				</div>
			)			
		})}
		
		</div>
	   
	   
   </div>
 
  
  );
};

export default DatalistBayar;
