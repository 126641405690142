import {React,useEffect,useState} from 'react';
import axios from 'axios'
import jwt_decode from "jwt-decode";
import apiService from '../../api/apiService'
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import FeaturesHomeOne from '../HomeOne/FeaturesHomeOne';
import PricingHomeOne from '../HomeOne/PricingHomeOne';
import BlogHomeThree from '../HomeThree/BlogHomeThree';
import DownloadHomeThree from '../HomeThree/DownloadHomeThree';
import CounterArea from '../HomeTwo/CounterArea';
import ServicesHomeTwo from '../HomeTwo/ServicesHomeTwo';
import VideoPlayerHomeTwo from '../HomeTwo/VideoPlayerHomeTwo';
import Drawer from '../Mobile/Drawer';
import FooterHomeOne from './FooterHomeOne';
import HeaderHomeSix from './HeaderHomeSix';
import Profile2 from './Profile1';

function HomeSix() {
    const [drawer, drawerAction] = useToggle(false);
	const [loginData, setLoginData] = useState(null);
	const [token, setToken] = useState('');
	const [name, setName] = useState('');	
    const [expire, setExpire] = useState('');
		
		
	useEffect(() => {
	refreshToken();

}, []);

     const refreshToken = async () => {   
			     	const response6 = await axios.get(apiService+'/token',{withCredentials: true}).then(res => {
					  // console.warn('response', res.data)
					 let hasKey = res.data.hasOwnProperty('errors');
					 if(hasKey){
						setLoginData(null)
					 }else{
					     setToken(res.data.accessToken);
						 const decoded = jwt_decode(res.data.accessToken);
						 setName(decoded.username);
						 setExpire(decoded.exp);
						 setLoginData(res.data.accessToken)
						 
						 
						  cekPembayaran(decoded.email)
					 }
				})     
    }
	
	const cekPembayaran=(emailuser)=>{
		//console.warn('tidak ada order yang di bayar')
		    	const databayar={
					mail:emailuser
				}
				const konfig={
					headers:{
					   "Content-Type":"application/json"
					}
				}
				 axios.get(apiService+'/cekpayment',{ params: databayar},konfig).then(res => {

								let cekError=res.data.hasOwnProperty('errors');
								if(cekError){
									
									console.warn('tidak ada order yang di bayar')
								}else{
								
									
                                     if(res.data.length===0){
									
									    axios.get(apiService+'/cekpayment2',{ params: databayar},konfig).then(res2 => {
											let cekError2=res2.data.hasOwnProperty('errors');
											if(cekError2){
										
												console.warn('tidak ada order yang di bayar')
											}else{
												
												 if(res2.data.length===0){
													    axios.get(apiService+'/cekpayment3',{ params: databayar},konfig).then(res3 => {
															 updateDatapembayaran(emailuser,res3.data)
														})
												 }else{
													  updateDatapembayaran(emailuser,res2.data)
												 }
												
											}
										})
									 }else{
									//	 console.warn('data balik dariiiiiiiiiiiiiiiiiiiiiiiiiiiiiii masuk ada data',res.data)
									    updateDatapembayaran(emailuser,res.data)
									 }
									
								}
						
					   })
      }
	  
	  const updateDatapembayaran=async(idpenyewa,idorder)=>{
	     let dataupdate={
			 mailuser:idpenyewa,
			 idordernya:idorder
		 }
		 const konfig={
					headers:{
					   "Content-Type":"application/json"
					}
				}
	     const responseOrder = await axios.post(apiService+'/updatepayment', dataupdate,konfig).then(resultorder=>{
			// console.warn('hasil simpan keranjang',resultorder.data)


		 })
}
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HeaderHomeSix action={drawerAction.toggle} />
            <Profile2 />

            <FooterHomeOne className="appie-footer-about-area" />
        </>
    );
}

export default HomeSix;
