//import axios from 'axios';
 //const BASE_URL = 'http://localhost:4001';
 // const apiService = 'http://47.236.149.13:4001';
const apiService = 'http://serverpro.agustri.com/apifutsal';
// const apiService = 'http://192.168.1.13:4001';
 //const apiService = 'http://192.168.1.207:4001';
 //const apiService = 'http://localhost:4001/apifutsal';
 //const apiService = 'http://localhost:4001/apifutsal';
export default apiService;
//export default axios.create({
 //   baseURL: BASE_URL
//});

 /*const apiService = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
});




export default apiService;*/

//export const getUser = (userId) => {
//  return axios.get(`/users/${userId}`);
//};

//export const updateUser = (userId, userData) => {
 // return axios.put(`/users/${userId}`, userData);
//};

//export const deleteUser = (userId) => {
 // return axios.delete(`/users/${userId}`);
//};


//export const daftarbygoogle = () => {
//  return axios.get(`/users/${userId}`);
//};