import { all, fork } from "redux-saga/effects"

//public
import loginsaga from "./auth/login/saga"



export default function* rootSaga() {
  yield all([
 
    loginsaga(),

  ])
}