import * as actiontype from './actionTypes'

const initialState ={
    userq:[],
}

const logina=(state=initialState,action)=>{
      switch(action.type){
				 case actiontype.LOGIN_USER_SUKSES:{
					 console.warn('masuk reducer login',action)
				 return{
					 ...state,
					 userq:action.payload
				 };
	         }
			    
			 default : {
			     return {...state}
			 }
	  }

}

export default logina;

