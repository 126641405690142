import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import userimage from '../../assets/images/user.jpg';
import moment from 'moment-timezone';
import { withRouter,useHistory, Link } from "react-router-dom"
import { Row, Col,Button, CardBody, Alert, Container, Input,  Modal,Text } from "reactstrap";
import { AvForm, AvField,AvInput } from "availity-reactstrap-validation";
import {
  CardMeta,
  CardHeader,
  CardDescription,
  CardContent,
  Card,
  Icon,Label,
  Image,GridColumn, GridRow,Grid,ListItem, ListHeader, List,ListContent,
} from 'semantic-ui-react'
//import { PaperClipIcon } from '@heroicons/react/20/solid'
const OtpContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const OtpInput = styled.input`
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 18px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 0 5px;

  &:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  }

  @media (max-width: 600px) {
    width: 30px;
    height: 30px;
  }
`;

//const Formdatapribadi = ({ numDigits,onOtpChange,aktifasi  }) => {
//,backgroundColor:'#E3EDF8'  alamatuser={alamat2} emailuser={email2} nouser={nohp2} companyuser={company2}
const Datalisttransaksi = ({namauser,datatransaksi}) => {
		const history = useHistory();
	const tglOrder=(tglcreate)=>{
		const tgl = new Date(tglcreate);
		const tglkonversi = moment(tgl).tz('Asia/Bangkok');
		const monthNames = [
				'Januari', 'Februari', 'Maret', 'April',
				'Mei', 'Juni', 'Juli', 'Agustus',
				'September', 'Oktober', 'November', 'Desember'
				];
		const tahun = tgl.getFullYear();
		const monthIndex = tgl.getMonth();
		const monthName = monthNames[monthIndex];
		const tglfix = tgl.getDate();
		//seTgltransaksi(`${tglfix}`)
		const hasil=tglfix+' '+monthName+' '+tahun
		return hasil;
}

const tglExp=(tglexpire)=>{
	    const tgl = new Date(tglexpire);
		const tglkonversi = moment(tgl).tz('Asia/Bangkok');
				const monthNames = [
				'Jan', 'Feb', 'Maret', 'April',
				'Mei', 'Juni', 'Juli', 'Agustus',
				'Sep', 'Okt', 'Nov', 'Des'
				];
		const jamnya=tglkonversi.format('HH:mm');
		const monthIndex = tgl.getMonth();
		const monthName = monthNames[monthIndex];
		const tglfix = tgl.getDate();
		const tglexpired =tglfix+' '+monthName+','+jamnya
		return tglexpired;
}

  const convertToUppercase = (inputString) => {
    return inputString.toUpperCase();
   };
   
       const formatAsRupiah = (number) => {
    return new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
    }).format(number);
  };
  
    const potongString=(varjam)=>{
	const datajam= varjam.split(':')  
	const hasilsplit= datajam[0]+':'+datajam[1]
	
	return hasilsplit
  }
  
  const tglOrdertransaksi=(tglcreate)=>{
		const tgl = new Date(tglcreate);
		const tglkonversi = moment(tgl).tz('Asia/Bangkok');
		const monthNames = [
				'Jan', 'Feb', 'Maret', 'April',
				'Mei', 'Juni', 'Juli', 'Agustus',
				'Sep', 'Okt', 'Nov', 'Des'
				];
		const tahun = tgl.getFullYear();
		const monthIndex = tgl.getMonth();
		const monthName = monthNames[monthIndex];
		const tglfix = tgl.getDate();
		//seTgltransaksi(`${tglfix}`)
		const hasil=tglfix+' '+monthName+' '+tahun
		return hasil;
}

const goInvoice=(namauser,datatransaksimobile)=>{
	//console.warn('00000000000000000000000000000000',datatransaksimobile)
         	history.push({
                pathname:"/m-page-invoice",
                state :{usernama:namauser,data:datatransaksimobile}
              });
	
}
       const kembali=()=>{
        history.push("/");
   }

  return (
  
   <div className="row" style={{padding:'0px'}} >
       <div className="row">
		  <div onClick={()=>kembali()}  color="primary" style={{width:'85px',cursor:'pointer',height:'15px',position:'absolute',marginLeft:'-13px',zIndex:'4',borderTop:'15px solid #555',borderRight:' 15px solid transparent',borderBottom:' 15px solid #555',borderTopLeftRadius:'10px'}}>
			<span  style={{color:'#fff',position:'absolute',marginTop:'-8px',marginLeft:'-4px',fontSize:'12px'}}>Kembali</span>
		  </div>
	   </div>
	    <div className="row justify-content-end bg-light"  style={{height:'100px',borderRadius:'10px'}}>
	        <h4 className="text-base font-bold mt-5 leading-4 text-gray-900 sm:truncate sm:text-base sm:tracking-tight">Daftar Transaksi</h4>
			 <p style={{marginTop:'-6px'}} className="text-sm leading-2 text-gray-300">{namauser}</p>
       </div>
	   <div style={{marginTop:'15px'}} className="row justify-content-center">
		   {
			   datatransaksi.map(itemtransaksi=>{
				   return(
					 <div style={{borderBottom:'2px solid #ccc', paddingBottom:'45px',paddingTop:'10px'}}>
					 
							<Grid >
								 <GridRow>
										<GridColumn width={15}>
										   <List>
												<ListItem>
												  <ListHeader><span style={{fontWeight:'650',fontSize:'17px'}}>Booking Lapangan</span>      <Label pointing='left' color={itemtransaksi.status==='cancel'?'red':'green'}>{itemtransaksi.status==='cancel'?'Gagal':'Sukses'}</Label></ListHeader>
												   {tglOrdertransaksi(itemtransaksi.tgltransaksi)}
												</ListItem>
												<ListItem>
												
												      <ListContent floated='right'>
												           	<ListHeader>print</ListHeader>
													    	<Icon style={{cursor:'pointer'}} onClick={()=>goInvoice(namauser,itemtransaksi)} name="print" />
													  </ListContent>
													  
													 
													  <ListContent>
														<ListHeader>No Invoice</ListHeader>
														<span >{itemtransaksi.invno}</span>
													  </ListContent>
												
											
												</ListItem>
												  <ListHeader style={{marginTop:'15px',marginBottom:'0px'}}><span style={{fontWeight:'650',fontSize:'15px'}}>Detil order</span></ListHeader>
												{
													itemtransaksi.detilorder.map(itemdetil=>{
														return(
														   <>
														        <ListItem style={{paddingTop:'0px',marginBottom:'0px'}}>
												
												                			 <span >{itemdetil.namalap}</span><span style={{marginLeft:'5px',marginRight:'5px'}}>{'Jam '+potongString(itemdetil.jamawal)+' - '+potongString(itemdetil.jamakhir)}</span>
											                	</ListItem>
														   </>
														)
													})
												}
												
												<ListItem style={{marginTop:'20px'}}>
												  <ListHeader>Total Bayar</ListHeader>
												  <span style={{fontSize:'20px',marginTop:'5px'}}>{formatAsRupiah(itemtransaksi.jumlahbayar)}</span><span style={{marginLeft:'70px',marginTop:'3px',position:'absolute',fontSize:'12'}}>{itemtransaksi.status==='cancel'?'pembayaran expired':'pembayaran sukses'}</span>
												</ListItem>
										   </List>
										</GridColumn>
								
									
								 </GridRow>
								 
								
								
							</Grid>
								 <Grid columns='equal' divided className="border-bottom" >
											<GridRow style={{height:'30px'}} >
											  <GridColumn className="bg-success" textAlign='center' style={{borderRadius:'4px',height:'30px'}}>
												
												  <span style={{fontWeight:'450',marginTop:'5px',color:'#fff'}}>Detail Transaksi</span>
												
											  </GridColumn>
											  <GridColumn className="bg-success" textAlign='center' style={{borderRadius:'4px',height:'30px',marginLeft:'5px'}}>
												
												  <span style={{fontWeight:'450',marginTop:'5px',color:'#fff'}}>Order Lapangan lagi</span>
												
											  </GridColumn>
											
											</GridRow>
										  </Grid>
			    	    </div>
				    )
			   })
		   }
	   </div>
	   
   </div>
   
  
  );
};

export default Datalisttransaksi;
