import axios from "axios"
import { post,get,del,put } from "./apiHelpers"
import * as url from "./url_helper"


export const tambahdatasiswa = (data) => post(url.TAMBAH_SISWA, data) 
export const tampilkandatasiswa =() =>get(url.DAFTAR_SISWA)
export const hapusdatasiswa=(data)=>del(url.HAPUS_SISWA,data)
export const editdatasiswa=(data)=>put(url.EDIT_SISWA,data)

export const loginBymail=(data)=>post(url.LOGIN_USER,data,{withCredentials: true})