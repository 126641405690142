import React, { useEffect,useState,useRef } from "react";
import styled from 'styled-components';
import userimage from '../../assets/images/user.jpg';
import moment from 'moment-timezone';
import { useLocation,Link,useHistory } from "react-router-dom";
import { Row, Col,Button, CardBody, Alert, Container, Input,  Modal } from "reactstrap";
import { AvForm, AvField,AvInput } from "availity-reactstrap-validation";
import { PDFDownloadLink, Document, Page,usePDF,pdf,Font,renderToString,ReactPDF,BlobProvider,PDFViewer,View,Text,StyleSheet } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import PDFFile from './PDFFile';
// import generatePDF, { Resolution, Margin, Options } from "react-to-pdf";
import {
  CardMeta,
  CardHeader,
  CardDescription,
  CardContent,
  Card,
  Icon,Label,
  Image,GridColumn, GridRow,Grid,ListItem, ListHeader, List,ListContent,
} from 'semantic-ui-react'

//import { PaperClipIcon } from '@heroicons/react/20/solid'
const OtpContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const OtpInput = styled.input`
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 18px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 0 5px;

  &:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  }

  @media (max-width: 600px) {
    width: 30px;
    height: 30px;
  }
`;

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: `/https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf`
    },
    {
      src: `https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.6/fonts/Roboto_condensed/robotocondensed-bold-webfont.woff`,
      fontWeight: 'bold'
    }
  ]
})


//const Formdatapribadi = ({ numDigits,onOtpChange,aktifasi  }) => {
//,backgroundColor:'#E3EDF8'  alamatuser={alamat2} emailuser={email2} nouser={nohp2} companyuser={company2}
const Pagemobileinvoice = () => {
	const location = useLocation();
	const [invdesc,setInvdesc]=useState([]);
	const [total,setTotal] = useState(0)
	const [subtotal,setSubtotal] = useState(0)
	const [tax,setTax]=useState(0)
	const [akun,setAkun]=useState('')
	

	const tglOrder=(tglcreate)=>{
		const tgl = new Date(tglcreate);
		const tglkonversi = moment(tgl).tz('Asia/Bangkok');
		const monthNames = [
				'Jan', 'Feb', 'Maret', 'April',
				'Mei', 'Juni', 'Juli', 'Agustus',
				'Sep', 'Okt', 'Nov', 'Des'
				];
		const tahun = tgl.getFullYear();
		const monthIndex = tgl.getMonth();
		const monthName = monthNames[monthIndex];
		const tglfix = tgl.getDate();
		//seTgltransaksi(`${tglfix}`)
		const hasil=tglfix+' '+monthName+' '+tahun
		return hasil;
}

const tglExp=(tglexpire)=>{
	    const tgl = new Date(tglexpire);
		const tglkonversi = moment(tgl).tz('Asia/Bangkok');
				const monthNames = [
				'Jan', 'Feb', 'Maret', 'April',
				'Mei', 'Juni', 'Juli', 'Agustus',
				'Sep', 'Okt', 'Nov', 'Des'
				];
		const jamnya=tglkonversi.format('HH:mm');
		const monthIndex = tgl.getMonth();
		const monthName = monthNames[monthIndex];
		const tglfix = tgl.getDate();
		const tglexpired =tglfix+' '+monthName+','+jamnya
		return tglexpired;
}

  const convertToUppercase = (inputString) => {
    return inputString.toUpperCase();
   };
   
       const formatAsRupiah = (number) => {
    return new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
    }).format(number);
  };
  
    const potongString=(varjam)=>{
	const datajam= varjam.split(':')  
	const hasilsplit= datajam[0]+':'+datajam[1]
	
	return hasilsplit
  }
  
  const formattotbiayainv=(nominal)=>{
	
	   const formattedCurrency = new Intl.NumberFormat('id-ID', {
				  style: 'currency',
				  currency: 'IDR',
				  minimumFractionDigits: 0,
				}).format(nominal);
				
				return formattedCurrency
}
  
  const tglOrdertransaksi=(tglcreate)=>{
		const tgl = new Date(tglcreate);
		const tglkonversi = moment(tgl).tz('Asia/Bangkok');
		const monthNames = [
				'Jan', 'Feb', 'Maret', 'April',
				'Mei', 'Juni', 'Juli', 'Agustus',
				'Sep', 'Okt', 'Nov', 'Des'
				];
		const tahun = tgl.getFullYear();
		const monthIndex = tgl.getMonth();
		const monthName = monthNames[monthIndex];
		const tglfix = tgl.getDate();
		//seTgltransaksi(`${tglfix}`)
		const hasil=tglfix+' '+monthName+' '+tahun
		return hasil;
}

	function parseFloat2Decimals(value) {
    return parseInt(value)
    }
	
	

	
	
   useEffect(()=>{
	   
	      //if (location.state.data.detilorder.hasOwnProperty('jumlahbayar')) {
		//	setTotal(location.state.data.detilorder['jumlahbayar']);
		 // } else {
			//console.error(`Item ${itemToSum} tidak ditemukan dalam objek JSON.`);
		 // }
		  const datadetil=[]
		  let tot=0;
		  let subtot=0;
		 
		  location.state.data.detilorder.map(itemoder=>{
			  const pecah1=itemoder.jamawal.split(':')
			  const pecah2=itemoder.jamakhir.split(':')
			  let jam=Number(pecah2[0])-Number(pecah1[0])
			  const wktux=pecah1[0]+':'+pecah1[1]+'-'+pecah2[0]+':'+pecah2[1]
			  const pecahlapx=itemoder.namalap.split(' ')
			  const lapx='Lap.'+Number(pecahlapx[1])
			  subtot= (jam*125000)+subtot;
			  tot=tot+parseFloat2Decimals(itemoder.jumlahbayar);
			 // alert(itemoder.akunbank)
			  setAkun(itemoder.akunbank)
			  datadetil.push({
				  jamx:jam,
				  itemx:lapx,
				  tglx:itemoder.ordertgl,
				  totalx:itemoder.jumlahbayar,
				  detilwkt:wktux    				  
			  })
		  })
		  let tax=(subtot*11)/100;
		  setTax(tax)
		  setTotal(tot)
		  setSubtotal(subtot)
		  setInvdesc(datadetil)
      
      },[])
	  
 const convertToUppercase2 = (inputString) => {
    return inputString.toUpperCase();
   };


 
  
const styles = StyleSheet.create({
   page: {
    flexDirection: 'row',
    backgroundColor: '#FFFFFF',
  },
   alamatpel: {
    flexDirection: 'row',
    backgroundColor: '#FFFFFF',
	marginTop:40,
  },
   alamatpel1: {
    flexDirection: 'row',
    backgroundColor: '#FFFFFF',
	marginTop:15,
  },
    alamatpel12awal: {
    flexDirection: 'row',
    backgroundColor: '#FFFFFF',
	marginTop:15,
  },
    alamatpel12: {
    flexDirection: 'row',
    backgroundColor: '#FFFFFF',
	marginTop:0,
  },
  headertabel: {
    flexDirection: 'row',
    backgroundColor: 'grey',
	marginTop:20,
  },
   bodytabel: {
    flexDirection: 'row',
    backgroundColor: 'white',
	marginTop:0,
  },
     footertabel: {
    flexDirection: 'row',
    backgroundColor: 'white',
	marginTop:20,
  },
   footertabeldesc: {
    flexDirection: 'row',
    backgroundColor: 'white',
	marginTop:0,
  },
   grandtot: {
    flexDirection: 'row',
    backgroundColor: 'white',
    borderTop:'1px double #000',
	borderBottom:'1px solid #000',
	marginTop:0,
  },
  judul:{
	  flexDirection: 'row',
    backgroundColor: '#FFFFFF',
	borderBottom:'1px solid #CCC',
  },
  section: {
    margin: 0,
    padding: 0,
    flexGrow: 1,
  },
   sectionHeader: {
    margin: 0,
	width:'60%',
    padding: 0,
    flexGrow: 1,
  },
   sectionHeader2: {
    margin: 0,
	width:'40%',
    padding: 0,
    flexGrow: 1,
  },
    sectionHeader3: {
    margin: 0,
	width:'25%',
    padding: 0,
    flexGrow: 1,
  },
      sectionfooter: {
    margin: 0,
	width:'50%',
    padding: 0,
    flexGrow: 1,
  },
     sectionHeadertable: {
    margin: 0,
	width:'25%',
    padding: 3,
    flexGrow: 1,
  },
  sectionbodytableqty: {
    margin: 0,
	width:'15%',
    padding: 3,
    flexGrow: 1,
  },
   sectionbodyketer: {
    margin: 0,
	width:'35%',
    padding: 3,
    flexGrow: 1,
  },
  sectiontotal1: {
    margin: 0,
	width:'65%',
    padding: 3,
    flexGrow: 1,
  },
    sectionHeader3detil: {
    margin: 0,
	width:'75%',
    padding: 0,
    flexGrow: 1,
  },
  sectionjudul: {
    margin: 0,
    paddingBottom: 8,
    flexGrow: 1,
  },
  text1: {
    fontSize: 19,
	marginLeft:15,
	fontWeight:'medium',
	marginTop:16,
  },
});
  


// Create Document Component
const MyDocument = () => (
  <Document>
   <Page size="A4" >
      <View style={styles.judul}>
		  <View style={styles.sectionjudul}>
			<Text style={styles.text1}>Invoice</Text>
		  </View>		
	  </View>
	  <View style={styles.page}>
		  <View style={styles.sectionHeader}>
			<Text style={{fontSize:24,fontFamily: 'Roboto',fontWeight:'bold',marginLeft:40,marginTop:20}}>Satria Futsal</Text>
		  </View>	
          <View style={styles.sectionHeader2}>
			<Text style={{fontSize:15,marginTop:10}}>www.satriafutsal.com</Text><br />
			<Text style={{fontSize:15}}>telp:+123 123456789</Text>
		  </View>			  
	  </View>
	  {/*color:'#6c757d'*/}
	  <View style={styles.alamatpel}>
	      <View style={styles.section}>
			<Text style={{fontSize:16,marginLeft:20,fontWeight:'medium'}}>Invoice Order Lapangan</Text><br/>
			<Text style={{fontSize:14,marginLeft:20,fontWeight:50}}>invoice ini merupakan bukti pembayaran yang sah</Text>
		  </View>	
	  </View>
	  <View style={styles.alamatpel1}>
	      <View style={styles.section}>
			<Text style={{fontSize:14,marginLeft:20}}>Penyewa:</Text><br/>
			<Text style={{fontSize:16,marginLeft:20,fontWeight:900}}>{location.state.data.idpenyewa+' ('+location.state.data.detilpenyewa[0].nama+')'}</Text>
		    <Text style={{fontSize:14,marginLeft:20,fontWeight:900}}>{location.state.data.detilpenyewa[0].alamat}</Text>
		  </View>	
	  </View>
	  <View style={styles.alamatpel12awal}>
	       <View style={styles.sectionHeader3}>
			  <Text style={{fontSize:16,marginLeft:20,fontFamily: 'Roboto',fontWeight:'bold'}}>Nomor</Text>
		   </View>
           <View style={styles.sectionHeader3detil}>
		      <Text style={{fontSize:14}}>{location.state.data.invno}</Text>
		   </View>		   
	  </View>
	   <View style={styles.alamatpel12}>
	       <View style={styles.sectionHeader3}>
			  <Text style={{fontSize:16,marginLeft:20,fontFamily: 'Roboto',fontWeight:'bold'}}>Tgl Transaksi</Text>
		   </View>
           <View style={styles.sectionHeader3detil}>
		      <Text style={{fontSize:14}}>{tglOrdertransaksi(location.state.data.tgltransaksi)}</Text>
		   </View>		   
	  </View>
	  <View style={styles.alamatpel12}>
	       <View style={styles.sectionHeader3}>
			  <Text style={{fontSize:16,marginLeft:20,fontFamily: 'Roboto',fontWeight:'bold'}}>Status</Text>
		   </View>
           <View style={styles.sectionHeader3detil}>
		      <Text style={{fontSize:14}}> {location.state.data.status}</Text>
		   </View>		   
	  </View>
	  <View style={styles.headertabel}>
	       <View style={styles.sectionbodytableqty}>
			  <Text style={{fontSize:14,marginLeft:20,color:'white',fontWeight:'medium'}}>QTY</Text>
		   </View>
           <View style={styles.sectionbodytableqty}>
		      <Text style={{fontSize:14,color:'white',fontWeight:'medium'}}>Item</Text>
		   </View>	
           <View style={styles.sectionbodyketer}>
		      <Text style={{fontSize:14,color:'white',fontWeight:'medium'}}>Keterangan</Text>
		   </View>		
            <View style={styles.sectionHeadertable}>
		      <Text style={{fontSize:14,color:'white',fontWeight:'medium'}}>Harga/Jam</Text>
		   </View>		
            <View style={styles.sectionHeadertable}>
		      <Text style={{fontSize:14,color:'white',fontWeight:'medium'}}>Total</Text>
		   </View>			   
	  </View>
	  { invdesc.map(itemdetil=>{
		  return(
		   <View style={styles.bodytabel}>
			   <View style={styles.sectionbodytableqty}>
				  <Text style={{fontSize:14,marginLeft:20}}>{itemdetil.jamx}</Text>
			   </View>
			   <View style={styles.sectionbodytableqty}>
				  <Text style={{fontSize:14}}>{itemdetil.itemx}</Text>
			   </View>	
			   <View style={styles.sectionbodyketer}>
				  <Text style={{fontSize:14}}>{tglOrder(itemdetil.tglx)+', '+itemdetil.detilwkt}</Text>
			   </View>		
				<View style={styles.sectionHeadertable}>
				  <Text style={{fontSize:14}}>{formattotbiayainv(125000)}</Text>
			   </View>		
				<View style={styles.sectionHeadertable}>
				  <Text style={{fontSize:14}}>{formattotbiayainv(Number(itemdetil.jamx)*125000)}</Text>
			   </View>			   
		  </View>
		  )
	    })
	  }
	   <View style={styles.bodytabel}>
	           <View style={styles.sectiontotal1}>
				  <Text ></Text>
			   </View>
			   <View style={styles.sectionHeadertable}>
				  <Text style={{fontSize:16,fontWeight:'medium'}}>Subtotal</Text>
			   </View>		
				<View style={styles.sectionHeadertable}>
				  <Text style={{fontSize:14}}>{formattotbiayainv(subtotal)}</Text>
			   </View>	
	   </View>
	      <View style={styles.bodytabel}>
	           <View style={styles.sectiontotal1}>
				  <Text ></Text>
			   </View>
			   <View style={styles.sectionHeadertable}>
				  <Text style={{fontSize:16,fontWeight:'medium'}}>Tax 11%</Text>
			   </View>		
				<View style={styles.sectionHeadertable}>
				  <Text style={{fontSize:14}}>{formattotbiayainv(tax)}</Text>
			   </View>	
	   </View>
	   <View style={styles.grandtot}>
	           <View style={styles.sectiontotal1}>
				  <Text ></Text>
			   </View>
			   <View style={styles.sectionHeadertable}>
				  <Text style={{fontSize:16,fontFamily: 'Roboto',fontWeight:'bold'}}>Total</Text>
			   </View>		
				<View style={styles.sectionHeadertable}>
				  <Text style={{fontSize:14}}>{formattotbiayainv(total)}</Text>
			   </View>	
	   </View>
	   	 <View style={styles.footertabel}>
	           <View style={styles.sectionfooter}>
				  <Text style={{fontSize:17, fontFamily: 'Roboto',marginLeft:20, fontWeight: 'bold' }}>Syarat dan ketentuan</Text>
			   </View>
			   <View style={styles.sectionfooter}>
				  <Text style={{fontSize:17, fontFamily: 'Roboto',fontWeight:'bold'}}>Metode Pembayaran</Text>
			   </View>		
				
	   </View>
	    <View style={styles.footertabeldesc}>
	           <View style={styles.sectionfooter}>
				  <Text style={{fontSize:14, fontFamily: 'Roboto',marginLeft:20}}>Harap melakukan pembayaran 30 menit sebelum jam booking.</Text>
			   </View>
			   <View style={styles.sectionfooter}>
				  <Text style={{fontSize:16, fontFamily: 'Roboto'}}>{convertToUppercase2(akun)}</Text>
			   </View>		
				
	   </View>
	  
    </Page>
  </Document>
);

const tesdown=()=>{
	
	ReactPDF.render(<MyDocument />, `${__dirname}/example.pdf`);
}




//console.warn('XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX',location.state.data)
  return (
   
   <div className="row " >
       <Grid >
			<GridRow style={{borderBottom:'1px solid #ccc',marginTop:'6px'}}>
				 <GridColumn width={1}>
					 
				  </GridColumn>
				  <GridColumn className="pl-0" width={3}>
					 <span style={{fontSize:'18px', fontWeight:'650'}}>Invoice</span>
				  </GridColumn>
				  <GridColumn width={10}>
			
				  </GridColumn>
				  <GridColumn width={1}>
				    <PDFDownloadLink document={<MyDocument />} fileName="somename.pdf">
					  {({ blob, url, loading, error }) =>
						loading ? 'Loading document...' : (<Icon name="download" size="large" />)
					  }
					</PDFDownloadLink>
					  
				  </GridColumn>
			</GridRow>
	    </Grid>
		<Grid>
		      <GridRow >
				   <GridColumn className="pl-5" width={8}>
					 <h4 >Satria Futsal</h4>
				   </GridColumn>
				  
				  <GridColumn className="p-0" width={7}>						
					   	<ListItem >
						  <ListHeader>www.satriafutsal.com</ListHeader>
						  <span>telp:+123 123456789</span>
						</ListItem>
				  </GridColumn>
			</GridRow>
			{/*<span style={{fontSize:'31px',fontWeight:'450',marginLeft:'30px',marginTop:'5px'}}>Satria Futsal</span>*/}
		</Grid>
		<Grid className="pl-4" style={{marginTop:'20px'}}>
			<GridRow  style={{marginBottom:'0px !important',paddingBottom:"0px !important"}}>
			   
			    <GridColumn width={14}>
				   <List>
						<ListItem>
						  <ListHeader><span >Invoice Order Lapangan</span></ListHeader>
						   <span style={{fontSize:'12px'}}>invoice ini merupakan bukti pembayaran yang sah</span>
						</ListItem>
				   </List>
				 </GridColumn>
			</GridRow>
	
			<GridRow className="p-0 mt-0">
			 
			    <GridColumn width={14}>
				   <List>
						<ListItem>
						  <span style={{fontSize:'13px'}}>Penyewa :</span>
						  <ListHeader><span >{location.state.data.idpenyewa+' ('+location.state.data.detilpenyewa[0].nama+')'}</span></ListHeader>
						  {/* <ListHeader><span style={{fontSize:'13px'}}>00.000.000.0-035.000</span></ListHeader>*/}
						   <span style={{fontSize:'12px'}}>{location.state.data.detilpenyewa[0].alamat}</span>
						  
						</ListItem>
				   </List>
				 </GridColumn>
			</GridRow>
			<GridRow className="pt-2 mt-0">			 
			    <GridColumn className="pl-0" >
				    <Grid className="pl-3 pt-2">
						<GridRow className="pt-3 pb-0">
							  <GridColumn width={4}>
								<span style={{fontWeight:'600',fontSize:'12px'}}>Nomor</span>
							   </GridColumn >
							 
							   <GridColumn width={7}>
								   <span style={{fontSize:'12px'}}>{location.state.data.invno}</span>
							   </GridColumn >							   
						</GridRow>
						<GridRow className="pt-0 pb-0">
							  <GridColumn width={4} className="pr-0">
								<span style={{fontWeight:'600',fontSize:'12px'}}>Tgl Transaksi</span>
							   </GridColumn >
							
							   <GridColumn width={8}>
								   <span style={{fontSize:'12px'}}>{tglOrdertransaksi(location.state.data.tgltransaksi)}</span>
							   </GridColumn >							   
						</GridRow>
						<GridRow className="pt-0">
							  <GridColumn width={4}>
								<span style={{fontWeight:'600',fontSize:'12px'}}>Satus</span>
							   </GridColumn >
							  
							   <GridColumn width={7}>
								  {location.state.data.status}
							   </GridColumn >							   
						</GridRow>
					</Grid>
					
				   {/* <List>
						 <ListItem>
						 <ListContent floated='right'>
							 xxxxxxxxxxxxxx
						  </ListContent>
						  
						 
						  <ListContent>
						    <ListHeader>Nomor</ListHeader>
							
						 </ListContent>
						
						</ListItem>
				   </List>*/}
				 </GridColumn>
			 </GridRow>
			 <GridRow className="p-0 mt-0">
			      <Grid className="pl-3 mt-0">
				        <GridRow color="grey" className="p-0 mt-0">
							 	<GridColumn className="p-0" width={1}><span style={{fontSize:'12px'}}>Qty</span></GridColumn>	
                                <GridColumn className="p-0" width={2}><span style={{fontSize:'12px'}}>item</span></GridColumn>	
                                <GridColumn className="p-0" width={7}><span style={{fontSize:'12px'}}>ket</span></GridColumn>	
                                <GridColumn className="p-0" width={3}><span style={{fontSize:'12px'}}>Rp/jam</span></GridColumn>		
                                <GridColumn className="p-0" width={2}><span style={{fontSize:'12px'}}>total</span></GridColumn>									
						</GridRow>
						{
							invdesc.map(itemdetil=>{
								return(
								   <GridRow className="p-0 mt-0">
						              <GridColumn className="p-0" width={1}><span style={{fontSize:'11px'}}>{itemdetil.jamx}</span></GridColumn>
                                      <GridColumn className="p-0" width={2}><span style={{fontSize:'11px'}}>{itemdetil.itemx}</span></GridColumn>		
                                      <GridColumn className="p-0" width={7}><span style={{fontSize:'12px'}}>{tglOrder(itemdetil.tglx)+', '+itemdetil.detilwkt}</span></GridColumn>	
                                      <GridColumn className="p-0" width={3}><span style={{fontSize:'11px'}}>{formattotbiayainv(125000)}</span></GridColumn>	
                                      <GridColumn className="p-0" width={2}><span style={{fontSize:'11px'}}>{formattotbiayainv(Number(itemdetil.jamx)*125000)}</span></GridColumn>										  
					            	</GridRow>
								)
							})
						}
						  <GridRow className="p-0 mt-0 border-bottom">
						              <GridColumn className="p-0" width={1}><span style={{fontSize:'11px'}}></span></GridColumn>
                                      <GridColumn className="p-0" width={2}><span style={{fontSize:'11px'}}></span></GridColumn>		
                                      <GridColumn className="p-0" width={7}><span style={{fontSize:'12px'}}></span></GridColumn>	
                                      <GridColumn className="p-0" width={3}><span style={{fontSize:'12px',fontWeight:'500'}}>Subtotal</span></GridColumn>	
                                      <GridColumn className="p-0"  width={2}><span style={{fontSize:'11px',fontWeight:'500'}}>{formattotbiayainv(subtotal)}</span></GridColumn>										  
					       </GridRow>
						    <GridRow className="p-0 mt-0">
						              <GridColumn className="p-0" width={1}><span style={{fontSize:'11px'}}></span></GridColumn>
                                      <GridColumn className="p-0" width={2}><span style={{fontSize:'11px'}}></span></GridColumn>		
                                      <GridColumn className="p-0" width={7}><span style={{fontSize:'12px'}}></span></GridColumn>	
                                      <GridColumn className="p-0" width={3}><span style={{fontSize:'11px',fontWeight:'500'}}>Tax 11%</span></GridColumn>	
                                      <GridColumn className="p-0"  width={2}><span  style={{fontSize:'11px',fontWeight:'500'}}>{formattotbiayainv(tax)}</span></GridColumn>										  
					       </GridRow>
						     <GridRow color="black" className="p-0 mt-0">
						              <GridColumn className="p-0" width={1}><span style={{fontSize:'11px'}}></span></GridColumn>
                                      <GridColumn className="p-0" width={2}><span style={{fontSize:'11px'}}></span></GridColumn>		
                                      <GridColumn className="p-0" width={7}><span style={{fontSize:'12px'}}></span></GridColumn>	
                                      <GridColumn className="p-0" width={3}><span style={{fontSize:'11px',fontWeight:'500'}}>Total</span></GridColumn>	
                                      <GridColumn className="p-0" width={2}><span style={{fontSize:'11px',fontWeight:'500'}}>{formattotbiayainv(total)}</span></GridColumn>										  
					       </GridRow>
						
				  </Grid>			    
			</GridRow>
			<GridRow className="mt-3">
			      <GridColumn className="p-0" width={7}>
				      <List >
					      <ListItem >
							  <ListHeader><h3 style={{fontSize:'12px'}}>Syarat dan ketentuan :</h3></ListHeader>
							  <span style={{fontSize:'11px'}}>Harap melakukan pembayaran 30 menit sebelum jam booking.</span>
						  </ListItem>
					  </List>
				  </GridColumn>
				  <GridColumn width={1}></GridColumn>
                  <GridColumn className="p-0" width={7}>
				         <List >
					      <ListItem >
							  <ListHeader><h3 style={{fontSize:'12px'}}>Metode Pembayaran :</h3></ListHeader>
							  <span style={{fontSize:'11px'}}>{convertToUppercase2(akun)}</span>
						  </ListItem>
					  </List>
				  </GridColumn>
			</GridRow>
		</Grid>
		
	   
   </div>
   
  
  );
};

export default Pagemobileinvoice;
