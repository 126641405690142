import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './assets/css/bootstrap.min.css';
import 'semantic-ui-css/semantic.min.css'
import "bootstrap/dist/js/bootstrap.bundle.min";
import './assets/css/custom-animated.css';
import './assets/css/default.css';
import './assets/css/output.css';
import './assets/css/font-awesome.min.css';
import './assets/css/magnific-popup.css';
import './assets/css/magnific.dark.css';
import './assets/css/magnific.rtl.css';
import './assets/css/main.css';
import './assets/css/style.css';
//import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './store'
import { GoogleOAuthProvider } from '@react-oauth/google';

ReactDOM.render(
 <GoogleOAuthProvider clientId="621705449112-j7drtcdnvclh9lplkho7vfi19d4pkson.apps.googleusercontent.com">
    <React.StrictMode>
	    <Provider store={store}>
        <App />
		    </Provider>
    </React.StrictMode>
	  </GoogleOAuthProvider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
/*reportWebVitals();


import { Provider } from 'react-redux';
import { store } from './store/store'

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')*/