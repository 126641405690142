import React, { useState,useEffect,useRef } from "react"
import axios from 'axios';
import jwt_decode from "jwt-decode";
import apiService from "../../api/apiService"
import heroThumb from '../../assets/images/hero-thumb-1.png';
import { useLocation,Link,useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare, faCoffee,faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope,faCartShopping } from '@fortawesome/free-solid-svg-icons'
import {FormGroup,Input,Label} from 'reactstrap'
import SweetAlert from "react-bootstrap-sweetalert"
import moment from 'moment-timezone';

const Pembayaran=({logurlpay,setLogurlpay})=> {
	 const location = useLocation();
	   const [loginData, setLoginData] = useState(null);
		const [name, setName] = useState('');
		const [token, setToken] = useState('');
	    const [expire, setExpire] = useState('');
		const [datakeranjang,setDatakeranjang]=useState([]);
		const [pay,setPay]=useState([]);
		const [isChecked,setisChecked]=useState(false);
		const [totalbiaya,setTotalbiaya]=useState(null);
		const [biayatodb,setBiayatodb]=useState(null);
		const [statuscancel,setStatuscancel]=useState(false);
		const [idorderbayar,setIdorderbayar]=useState('');
		const [orderpilih,setOrderpilih]=useState({
			user:'',
			mail:'',
			amt:0,
			listorder:null
		});
	    const [statuspilih,setStatuspilih]=useState(false);
		const [dataorder,setDataorder]=useState([])
		const [countpay,setCountpay]=useState(0);
		const [tokenPay,setTokenpay]=useState("");
		const [currentuser,setCurrentuser]=useState('');
		
		 const history = useHistory();
	    const refreshToken = async () => {
      //  try {
            /*const response = await axios.get('http://localhost:5000/token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);*/
			   	const response6 = await axios.get(apiService+'/token',{withCredentials: true}).then(res => {
					  // console.warn('response', res.data)
					 let hasKey = res.data.hasOwnProperty('errors');
					 if(hasKey){
					     history.push("/login");
						 //  userLogout()
						 setLoginData(null)
					 }else{
					     setToken(res.data.accessToken);
						 const decoded = jwt_decode(res.data.accessToken);
						 setName(decoded.username);
						 setExpire(decoded.exp);
						 setLoginData(res.data.accessToken)
						  getDetailorder(decoded.email)
						  setCurrentuser(decoded.email)

						    // const updatedorderpilih = { ...orderpilih,
							//		user:decoded.username,
							//		mail:decoded.email
							//  };
				          //  setOrderpilih(updatedorderpilih);
					 }

				})
      //  } catch (error) {
      //      if (error.response) {
              //   history.push("/");
      //      }
      //  }
    }
	
	  const userLogout =async()=>{
	  const response6 = await axios.get(apiService+'/logout',{withCredentials: true}).then(res => {
		  let hasKey = res.data.hasOwnProperty('success')
		  if(hasKey){
			  if(res.data.success[0].jenis==='auth'){
				  setLoginData(null)
			
			  }
		  }
	  })
  }

   const axiosJWT = axios.create();
   // console.warn('expire datenyaaa...',expire)
   // console.warn('expire perbandingan...',currentDate1.getTime())
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
          /*  const response = await axios.get('http://localhost:5000/token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);*/
		    const response7 = await axios.get(apiService+'/token',{withCredentials: true}).then(res => {
				  	 let hasKey = res.data.hasOwnProperty('errors');
					 if(hasKey){
					 // history.push("/");
					   userLogout()
	                 	     history.push("/login");
					 setLoginData(null)
					 }else{
						config.headers.Authorization = `Bearer ${res.data.accessToken}`;
						setToken(res.data.accessToken);
						const decoded = jwt_decode(res.data.accessToken);
						setName(decoded.username);
					    setExpire(decoded.exp);
						setLoginData(res.data.accessToken)
					 }
			})
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

		const getDetailorder=async(usermail)=>{
		const restDetailorder=await axios.get(apiService+'/datadetailorder',{params:{mailuser:usermail}}).then(res =>{
		//	console.warn('Data detail order1',res.data)
		    	res.data.map(function(item) {
				         let objct={
							 idpenyewa:usermail,
							 id_order:item.id_order,
							 Nama:item.Nama,
							 jamawal:item.jamawal,
							 jamakhir:item.jamakhir,
							 jumlahbayar:item.jumlahbayar,
							 tglbooking:item.tgl_order,
							 checked:false
						 }
					    setDatakeranjang(prevState => [...prevState, objct]);
				})
			//setDatakeranjang(prevState => [...prevState, res.data]);
			//setdatakeranjang(res.data);
		})
	}





	const handleCheckboxChange = async(keranjang,index1)=> {
			 	const response6 = await axios.get(apiService+'/token',{withCredentials: true}).then(res => {
					  // console.warn('response', res.data)
					 let hasKey = res.data.hasOwnProperty('errors');
					 if(hasKey){

								     history.push("/login");
						             setLoginData(null)

					 }else{

					   			//console.warn('tes check',keranjang.checked)
								let jumlah=0;
								const newArray = datakeranjang.map((item, i) => {
								  if (i === index1) {
									  if(item.checked){
										  //   console.warn('nilai',item.id_order);
									//	  setDataorder(prevArray => prevArray.filter((_, index) => index !== index1));
											 return { ...item, checked: false };
									  }else{
										  //  setDataorder(prevArray => [...prevArray, item.id_order]);
											// console.warn('nilai',item.id_order);
											 return { ...item, checked: true };
									  }
								  } else {
									return item;
								  }
								});
								setDatakeranjang(newArray);

								if(!keranjang.checked){
									setDataorder(prevArray => [...prevArray, keranjang.id_order]);
								}else{
								//	setDataorder(prevArray => prevArray.filter((_, index) => index !== index1));
								}

								  var indexi = dataorder.indexOf(keranjang.id_order)
								 // console.warn('nilai',indexi);
								  if (indexi !== -1) {
								  dataorder.splice(indexi, 1);
							//	setDataorder(prevArray => [...prevArray, dataorder]);
							   // this.setState({people: array});
							  }
	                 }
			})


    };

   useEffect(() => {
    // Fetch data or perform actions that should update the array
 //   const newData = [];
  //   datakeranjang.map((item, i) => {
   //   newData.push(item.id_order)
  //  });
	//setDataorder(newData)
    //setDatakeranjang(newArray);
    // Update the state array
    //setMyArray(newData);
  }, []);

const getDayName = (dateString) => {
  const days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];
  const date = new Date(dateString);
  const dayIndex = date.getDay();
  return days[dayIndex];
};

const waktuOrder=(tglorder)=>{
	       const tgl = new Date(tglorder);
	//	const tglkonversi = moment(tgl).tz('Asia/Bangkok');
				const monthNames = [
				'Jan', 'Feb', 'Maret', 'April',
				'Mei', 'Juni', 'Juli', 'Agustus',
				'September', 'Oktober', 'November', 'Desember'
				];
	//	const jamnya=tglkonversi.format('HH:mm');
		const monthIndex = tgl.getMonth();
		const monthName = monthNames[monthIndex];
		const tahun = tgl.getFullYear();
		const tglfix = tgl.getDate();
		const tglORDER1 =tglfix+' '+monthName+' '+tahun;
		return tglORDER1;
}

const tglBookingan=(tgl,jamakhir)=>{
	
	const tglb = new Date(tgl);
	const tglc = new Date()
	const tesb=moment(tglb).format("DD-MM-YYYY")
	const tesc=moment(tglc).format("DD-MM-YYYY")
	
	const jamskrg=moment(tglc).format('hh:mm:ss A')
	const pecah=jamskrg.split(' ')
	const pecahjm=pecah[0].split(':')
	const pecahjmakhir=jamakhir.split(':')
//	console.warn('jam boookinngggg',tesb)
	//console.warn('tgl sekarang 2',])
	let jamfixnow=0
	if(pecah[1]==='PM'){
		jamfixnow=Number(pecahjm[0])+12
	}else{
		jamfixnow=Number(pecahjm[0])
	}
	
	 if(tesb>=tesc){
		 if(tesb===tesc){
			 if(Number(pecahjmakhir[0])>jamfixnow){
				  return true
			 }else{
				 return false
			 }
		 }else{
		   return true
		 }
	
	}else{
		//return true
		console.warn('22222222222222')
	 }
	//return true
}

const tglSekarang=(tgl2)=>{
	 const tgl = new Date(tgl2);
	 //const tes=moment(tgl).format("DD-MM-YYYY")
	 return tgl
}

	const getTotalbayar=()=>{
		let totalbayar=0;
		let jumlahbyr=0;
		let Btodb=0;

		 datakeranjang.map((total, i) => {
			 if(total.checked){
				 jumlahbyr=jumlahbyr+1;
				 let parsial=(total.jumlahbayar).split('.');
				 let datajumlah=parsial[0].replace(/[,]/g, '.');
				 let jumlahfix=datajumlah.replace(/[.]/g, '');
				 let bayar =parseFloat2Decimals(jumlahfix);
				 Btodb=bayar+Btodb;
				// console.warn('pembayaran',Btodb);
               //  const result = parseFloat2Decimals(datajumlah);
				// totalbayar=result+totalbayar;
				 // console.warn('pembayaran2',totalbayar);

			 }
		 })

		  setCountpay(jumlahbyr);
		   const formattedCurrency = new Intl.NumberFormat('id-ID', {
				  style: 'currency',
				  currency: 'IDR',
				  minimumFractionDigits: 2,
				}).format(Btodb);
				setTotalbiaya(formattedCurrency)
				setBiayatodb(Btodb)

		// console.warn('jumlah  bayarnya=',formattedCurrency)

		 // console.warn('data terpilih',dataorder)
	}

	function parseFloat2Decimals(value) {
    return parseInt(value)
    }

	const hapusOrder=async(idorder,mailuser)=>{
     	const response6 = await axios.get(apiService+'/token',{withCredentials: true}).then(res => {
			  // console.warn('response', res.data)
			 let hasKey = res.data.hasOwnProperty('errors');
			 if(hasKey){
				 history.push("/login");
			     setLoginData(null)
			 }else{

	     			  let datahapus={
						  penyewa:mailuser,
						  idordernya:idorder
					  }
					   setDatakeranjang([]);
					   const responseHapusOrder = axios.post(apiService+'/hapusOrder', datahapus).then(resulthapus=>{
						   //console.warn('data setelah hapus',resulthapus.data)
								    resulthapus.data.map(function(item) {
									 let objct={
										 idpenyewa:mailuser,
										 id_order:item.id_order,
										 Nama:item.Nama,
										 jamawal:item.jamawal,
										 jamakhir:item.jamakhir,
										 jumlahbayar:item.jumlahbayar,
										  tglbooking:item.tgl_order,
										 checked:false
									 }
									setDatakeranjang(prevState => [...prevState, objct]);
							       })
					   })
			  }

		 })
	}

	useEffect(() => {
	refreshToken();
    }, []);
	useEffect(() => {
	getTotalbayar();


    });
	
	  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  
  
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount


   useEffect(() => {
 //   const addMidtransScript = async () => {
      //change this to the script source you want to load, for example this is snap.js sandbox env
      const midtransScriptUrl = 'https://app.sandbox.midtrans.com/snap/snap.js'
      //change this according to your client-key
      const myMidtransClientKey = 'SB-Mid-client-CdIoeAyfCFXBCy2T'
      let script = document.createElement('script')
      script.src = midtransScriptUrl
      script.async = true
      // optional if you want to set script attribute
      // for example snap.js have data-client-key attribute
      script.setAttribute('data-client-key', myMidtransClientKey)
  //    script.onload = () => {
    //    setSdkReady(true)
     // }
      document.body.appendChild(script)
  //  }

	   return ()=>{
		   document.body.removeChild(script)
	   }
		//  addMidtransScript()
		// if (!order || order._id !== orderId) {
		//   dispatch(getOrderDetails(orderId))
		// }
	  }, [])

  useEffect(()=>{
	  if(tokenPay){
		  window.snap.pay(tokenPay,{
			  onSuccess:async(result)=>{
				//  localStorage.setItem("Pembayaran",JSON.stringify(result))
				//  console.warn('pembayaran ter create')
				  setTokenpay("")
				//  updatestatus(result.order_id)
			  },
			  onPending:(result)=>{
				  //console.warn('Transaksi Pending')
				    updateDatabayar();					
				    setTokenpay("")
					updatestatus(result)
			  },
			  onError:(error)=>{
				    setTokenpay("")
			  },
			  onClose:()=>{
				 // console.warn('Transaksi Batal')
				 setStatuscancel(true)
				// updateBatalbayar();
				  setTokenpay("")
			  }
		  })
	  }
  },[tokenPay])

const updatestatus=async(dataparam)=>{
//	console.warn('INFOOOOOOOOOOOOOOOOOO',data)





	       await axios.post(apiService+'/updatesatusbayar',dataparam).then(resultstatus => {
			    let hasKey = resultstatus.data.hasOwnProperty('errors');
			   if(hasKey){

			   }else{
				    if(logurlpay){
					setLogurlpay(false)
				}else{					
					const requestbayar={
					 idpayment:resultstatus.data.success[0].databalik.order_id,
			   //    emailpel:mailuser			
					}			 
					history.push({
					pathname:"/order-bayar",
					state :requestbayar
					});
				}
						
			   }
		   })
}

	const updateDatabayar=async()=>{
//	console.warn('data id orderrrrrrrrrrr',idorderbayar)
		     const databayar={
		  	 	user:name,
		     	mail:currentuser,
			    amt:biayatodb,
			    listorder:dataorder


		 }
		        setDatakeranjang([]);
		             await axios.post(apiService+'/updateDatabayar',databayar).then(res => {
                 		 res.data.datasisa.map(function(item) {
				         let objct={
							 idpenyewa:currentuser,
							 id_order:item.id_order,
							 Nama:item.Nama,
							 jamawal:item.jamawal,
							 jamakhir:item.jamakhir,
							 jumlahbayar:item.jumlahbayar,
							 tglbooking:item.tgl_order,
							 checked:false
						 }
					    setDatakeranjang(prevState => [...prevState, objct]);
				        })
			})

	}



	const pembayaran=async()=>{
       // const response6 = await axios.get(apiService+'/caristatus',{params: {idbayar:'order-20240530203253136'}}).then(res => {
			
		//})
			const response6 = await axios.get(apiService+'/token',{withCredentials: true}).then(async res => {
			  // console.warn('response', res.data)
			 let hasKey = res.data.hasOwnProperty('errors');
			 if(hasKey){
				 history.push("/login");
			     setLoginData(null)
			 }else{
				const databayar={
					user:name,
					mail:currentuser,
					amt:biayatodb,
					listorder:dataorder
				}
				const konfig={
					headers:{
					   "Content-Type":"application/json"
					}
				}
				await axios.get(apiService+'/getpayment',{ params: databayar},konfig).then(res => {

								let cekError=res.data.hasOwnProperty('errors');
								if(cekError){
									setStatuspilih(true)
									//pesan Error saat tidak ada yang di pilih untuk di bayar
								}else{
								    setTokenpay(res.data.token);
									
									//console.warn('data detil order',res.data.datadetail.transaction_details.order_id)
                                   //  console.warn('id ordernyaaaaaaaaaaaaaaaaaaaaaaaaaa',res.data.idorderan)
						  			setIdorderbayar(res.data.idorderan);
									 createInvoice(res.data.idorderan);
								   // updateDatabayar();
								}
								// tokenID = res.data
									//console.warn('Data interchange',res.data.datasisa)
								// setTokenpay(res.data.token)
					   })
			 }
		})
	}
	
	const createInvoice=async(idpayment)=>{
	 const databayarlangsung={
    emailuser:currentuser,
    idorderpayment:idpayment
    }
    const createInv=await axios.post(apiService+'/createinvoice', databayarlangsung).then(resultinv=>{
		
	})
}
	
	const batalbayar=()=>{
	//	console.warn('batal bayarrrrrrrrrrrrrrrrr',idorderbayar)
	     const databatal={
			 idpayment :idorderbayar,
			 emailuser:currentuser
		 }
	     axios.post(apiService+'/batalpayment',databatal).then(res => {
			 
		 })
		setStatuscancel(false)
	}
		//console.warn('tanggal sekaranggggg....................',tglBookingan())
	//	console.warn('data order2',dataorder)
	//c//onsole.warn('data dari header',datakeranjang)
    return (
        <>
            <section className="appie-hero-area ">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="row ">
							     <div className="card">
								  <div className="card-header">
									<span style={{fontSize:'18px',fontWeight:'650'}}>Keranjang</span>
								  </div>
								  <div className="card-body">
								  <ul>
								  {datakeranjang.map((keranjang,index)=>{
									  return(
									    <li  key={index}>
										  <div style={{marginBottom:'8px'}} className="row">
											<h5 style={{fontSize:'16px',fontWeight:'650'}} className="card-title">{'#'+keranjang.id_order+' '+keranjang.Nama}</h5>
											<p style={{marginTop:'-5px',fontSize:'13px'}} className="card-text">Deskripsi Booking :{' Jam: '+keranjang.jamawal+' - '+keranjang.jamakhir}</p>
											<span style={{fontSize:'12px'}}>{getDayName(keranjang.tglbooking)+' '+waktuOrder(keranjang.tglbooking)}</span>
										  </div>
										  {										
											 tglBookingan(keranjang.tglbooking,keranjang.jamakhir) ? (
												 
												  <div style={{borderBottom:'1px solid #000'}} className="d-flex flex-row-reverse bd-highlight">

												  <div className="p-0 bd-highlight"><FontAwesomeIcon style={{cursor:'pointer'}} onClick={()=>hapusOrder(keranjang.id_order,keranjang.idpenyewa)} icon={faTrashCan} /></div>
												  <div style={{marginLeft:'5px',marginRight:'5px'}}>|</div>
												  <div className="p-0 bd-highlight">
														<FormGroup check>
														  <Label check>
															<Input type="checkbox"
															   checked={keranjang.checked}
															   onChange={()=>handleCheckboxChange(keranjang,index)}
															/>{' '}
															Bayar
														  </Label>
														</FormGroup>
												  </div>

												</div> 
											):(
											   <div style={{borderBottom:'1px solid #000'}} className="d-flex flex-row-reverse bd-highlight">
											     <div className="p-0 bd-highlight"><FontAwesomeIcon style={{cursor:'pointer'}} onClick={()=>hapusOrder(keranjang.id_order,keranjang.idpenyewa)} icon={faTrashCan} /></div>
												  <div style={{marginLeft:'5px',marginRight:'5px'}}>|</div>
											      	  <div className="p-0 bd-highlight"><span className="text-muted" style={{fontSize:'13px'}}>Orderan sudah expire, tidak bisa di lanjutkan</span></div>
											   </div>
											)
											 
										  }
										   

									    </li>)
								   })}
								   </ul>
								  </div>
								</div>
							</div>

                        </div>
                        <div className="col-md-4">
                           <div className="row">
						      <div className="card">
							  <div className="card-header text-center">
								Rincian Pembayaran
							  </div>
							  <div className="card-body" style={{padding:'20px 0px 0px 0px'}}>
							    <div style={{borderBottom:'1px solid #000',paddingBottom:'10px'}} className="d-flex flex-column bd-highlight mb-3">
								{datakeranjang.map(total=>{
									return(
									    <>
										   {total.checked? (
										       <div style={{fontSize:'13px'}} className="p-0 row bd-highlight">
												  <div className="col-6">Booking {total.jamawal+' - '+total.jamakhir }</div>
												  <div className="col-6">Rp {total.jumlahbayar}</div>
										       </div>
										   ):null}

									    </>
									)
								})
								}

								</div>
								<div className="row">
								  <div className="col-6">
								    <h5 className="card-title">Total Bayar</h5>
								  </div>
								  <div className="col-6">
								   <span style={{fontSize:'14px'}}>
								  {countpay>0?totalbiaya:'-'}
								   </span>
								  </div>
                                </div>
							  </div>
							  <div className="card-footer text-muted">
								<button onClick={()=>pembayaran()} style={{width:'100%'}} className="btn btn-primary">Pilih Pembayaran</button>
							  </div>
							</div>
						   </div>
                        </div>
                    </div>
                </div>
				   {statuscancel ? (
								<SweetAlert
								  title="Konfirmasi Pembayaran order"
								   btnSize="sm"
								   size="sm"
								   confirmBtnText="Tutup"
								  onConfirm={() => {								
									batalbayar()
								  }}
								>
								Pembayaran di batalkan
								</SweetAlert>
							  ) : null}

					   {statuspilih ? (
								<SweetAlert
								  title="Konfirmasi Order"
								   btnSize="sm"
								   size="sm"
								   confirmBtnText="Tutup"
								  onConfirm={() => {
									setStatuspilih(false)
								  }}
								>
								Pilih order yang mau di bayar
								</SweetAlert>
							  ) : null}
            </section>
        </>
    );
}

export default Pembayaran;
