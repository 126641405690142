import {useState} from 'react';

import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Modal
} from "reactstrap" 


import React from 'react'

function Jam(props) {
 	  const jambooking=[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24]
	
	 return (
	 
				    <Col className="jambook" xs={props.winsize < 650 ? 6  : 3} >
						 <p> tes</p>
					  </Col>
	 
		) 
	
}

export default Jam;