//import PropTypes from 'prop-types'
//import MetaTags from 'react-meta-tags';
import React, { useEffect,useState } from "react"

import { Row,Button, Col, CardBody, Card, Alert, Container,Modal,Label } from "reactstrap"
import apiService from '../../api/apiService';
// Redux
//import { connect } from "react-redux"
import { withRouter,useHistory, Link } from "react-router-dom"
import axios from 'axios'
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"
import OtpField from './OtpField';
import Loader from '../../components/Helper/Loader';
// actions
//import { loginUser, apiError } from "../../store/actions"
import Sms from "../../assets/images/iconsms.png"
import { connect,useDispatch,useSelector } from "react-redux";
// import images
//import logoLightPng from "../../assets/images/logo-light.png"
import * as actionType from "../../store/actions";
import logoDark from "../../assets/images/logo-dark.png"
import { googleLogout, useGoogleLogin,GoogleLogin  } from '@react-oauth/google';
const Login = (props) => {
	  const dispatch = useDispatch()
		   const { responseLOGIN,actions } = props;
// handleValidSubmit
const [jenisinput,setJenisinput]=useState(true)
const [username,setUsername]=useState('')
const [usermail,setUsermail]=useState('')
const [nomorhp,setNomorhp]=useState(0)
const [pesanUsern,setPesanusern]=useState('')
const [otpnya,setOtpnya]=useState(null)
const [formotp,setFormotp]=useState(false)
const [passregister,setPassregister]=useState('')
const [pesanPass,setPesanpass]=useState('')
const [loglanjut,setLoglanjut]=useState(true)
const [aktifnya,setAktifnya]=useState(false)
const [modal_small, setmodal_small] = useState(false)
const [modal_mailverifid,setModal_mailverifid]=useState(false)
const [modallogin, setModallogin] = useState(false)
const [waktu,setWaktu]=useState(Date.now())
const [cektime,setCektime]=useState(false)
const [smoothtime,setSmoothtime]=useState(false)
const [formotpmailVerifi,setFormotpmailverifi]=useState(false)
const [loading, setLoading] = useState(false);
    const history = useHistory();

  const handleValidSubmit = async(event, values) => {
	  const {datauser,pwd}=values
	 // console.warn('Values',values)
 //  props.loginUser(values, props.history)

	
		if(!loglanjut){
			console.warn('masuk with email')
			const request3 ={
				mailuser:datauser,pass:pwd
			}

				const resloginbyemailuser= axios.post(apiService+'/loginuserbyemail',request3,{withCredentials: true}).then(resloginuserbyemail => {
					  //  console.warn('data dari nodeAPI',resloginuserbyemail.data)
					      let hasKey3 = resloginuserbyemail.data.hasOwnProperty('errors');
						 if(hasKey3){
							  if(resloginuserbyemail.data.errors[0].jenis==='passnomatch'){
								 setPesanpass(resloginuserbyemail.data.errors[0].msg)
							  }
						 }else{
							// console.warn('tessssssssssssssssssssssssssssssssssssssss',resloginuserbyemail.data.success[0].jenis)
						    if(resloginuserbyemail.data.success[0].jenis==='email'){
								if(!resloginuserbyemail.data.success[0].accessToken){
									alert('terjadi kesalahan')
								}else{
								//	console.warn('masukk')
									 //history.push("/");
									    const loforPayurl={logurl:true}
									    history.push({
										pathname:"/",
										state :loforPayurl
										});
									  
								}
							 }
						 }
				})


		}else{
			//console.warn('masuk emai username')
     	    const request1={
			datamail:datauser
					}
				//	await actions.userMasuk(request1);
			    //     cekResponse()
				const responselogin= axios.post(apiService+'/loginuser',request1).then(res => {
					console.warn('databalik..',res.data)
					//if(res.data.jenis ==='passnull'){
					//	setModallogin(true)
					//}
						 let hasKey = res.data.hasOwnProperty('errors');
						 if(hasKey){
							 if(res.data.errors[0].jenis==='userno' ){
								 setPesanusern(res.data.errors[0].msg)
							 }else if(res.data.errors[0].jenis==='noakun'){
								 setPesanpass(res.data.errors[0].msgpassword)
								 setPesanusern(res.data.errors[0].msguser)
							 }else if(res.data.errors[0].jenis==='hpnotfound'){
								  setPesanusern(res.data.errors[0].msg)
							 }else if(res.data.errors[0].jenis==='passnull'){
								 setModallogin(true)
							 }
						 }else{
							if(res.data.success[0].jenis==='nohp'){
								 setmodal_small(true)
							 }else if(res.data.success[0].jenis==='emailexist'){
							 }else if(res.data.success[0].jenis==='emailpassword'){
								  setJenisinput(false)
								  setLoglanjut(false)
							 }
						 }
				})
		}
  }



 // const cekResponse=()=>{
//	  console.warn('data balik redux',responseLOGIN)
//  }

   function tog_small() {
    setmodal_small(!modal_small)
    removeBodyCss()
  }

     function tog_mailverifid() {
    setModal_mailverifid(!modal_mailverifid)
    removeBodyCss()
  }

    function tutup() {
       setmodal_small(false)
	   setWaktu(Date.now());
	   setFormotp(false)
	   setCektime(false)
    }

   function tutupmailverifid() {
	   setModal_mailverifid(false)
	      setWaktu(Date.now());
		  	   setCektime(false)
			   setFormotpmailverifi(false)
   }

	   function tog_modallogin() {
    setModallogin(!modallogin)
    removeBodyCss()
  }
	function tutupModallogin(){
		setModallogin(false)
	}

   function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

    function handleLoginClick() {
    setLoading(true);
  };

   	const handlelogin=useGoogleLogin({
       onSuccess:   (codeResponse) => {
		           const request1={token: codeResponse.access_token}
			       // const response2 =  axios.post('http://localhost:4001/google-login', request1)
				 /* const res = axios.post('http://localhost:4001/google-login', {
				  method: 'POST',
				  body: JSON.stringify({
					token: codeResponse.access_token,
				  }),
				  headers: {
					'Content-Type': 'application/json',
				  },
				  });*/
				 //  const data = response2.json();
				     setLoading(true)
				const response6 = axios.get(apiService+'/daftarbygoogle',{ params: request1}).then(res => {
					console.warn('email tidak terverifikasi',res.data)
		             	//let arr3 = res.data;
						// setLoginData(arr3);
                       //  sessionStorage.setItem('loginData', JSON.stringify(arr3));

						/* let hasKey = res.data.hasOwnProperty('errors');
						 if(hasKey){
							 if(res.data.errors[0].jenis==='noakun'){
									 console.warn('email tidak terverifikasi')
							 }
						 }*/
                         setLoading(true)
						 let hasSucces=res.data.hasOwnProperty('success')
						 if(hasSucces){
							 		//console.warn('data balik',res.data.success[0].verifid)
							 if(res.data.success[0].verifid && res.data.success[0].sts==='exist'){

								 const request4={
									 usermail:res.data.success[0].email
								 }
								 const responselogin= axios.post(apiService+'/loginbygooglelogin',request4,{ withCredentials: true }).then(reslog => {
								 //const responselogin= axios.post(apiService+'/loginbygooglelogin',request4,{withCredentials: true}).then(reslog => {
									          setLoading(true)
									 if(reslog.data.success[0].jenis==='email'){
										  setLoading(false)
                                       const loforPayurl={logurl:true}
									    history.push({
										pathname:"/",
										state :loforPayurl
										});
									  }
									//  console.warn('data balik data',reslog.data )
								 })

							 }else if(res.data.success[0].verifid && res.data.success[0].sts==='noexist'){

									setUsermail(res.data.success[0].email)
									setUsername(res.data.success[0].username)
								    setModal_mailverifid(true)
								   setLoading(false)
							 }
						 }else{
							 console.warn('terjadi kesalahan di daftar by gmail')
						 }
				   })

		        /*	const response6 = axios.get('http://localhost:4001/google-login',{ params: request1}).then(res => {
		             	let arr3 = res.data;
						//setLoginData(arr3);
                        //sessionStorage.setItem('loginData', JSON.stringify(arr3));
         				//console.warn('data balik',arr3)
				   })*/
		},
	     onRequest:()=>{handleLoginClick()},
        onError: (error) => {console.log('Login Failed:', error)}


    });



	const loginBynohp=(varnomor)=>{

		    const request1={
				nomorhpx:varnomor
			}
			const response6 = axios.get(apiService+'/daftarotp',{params:request1}).then(res => {

				let cekSukses=res.data.hasOwnProperty('success');
				if(cekSukses){
					setOtpnya(res.data.success[0].kode)
					setFormotp(true)
				    setWaktu(Date.now()+61000);
				}else{
					alert('terjadi kesalahan request otp')
				}

			})
	}

  const calculateTimeLeft = () => {
		//  const expiryTimestamp = Date.now() + 60000;
      const difference = waktu - Date.now();
	//console.warn('difference',difference)
	//const difference =60000;
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        detik: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  };
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    useEffect(() => {
     const timer = setTimeout(() => {
     setTimeLeft(calculateTimeLeft());
	 }, 1000);

   return () => clearTimeout(timer);
   });

     const timerComponents = [];
   Object.keys(timeLeft).forEach(interval => {
	// console.warn('ISI timeLeft',timeLeft[interval])
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span key={interval}>

        {timeLeft[interval]} {interval}{''}
      </span>
    );
  });

////////////////////////////////////PENGGUNAAN USE EFFECT//////////////////////////////////
  useEffect(()=>{
	 // if(timerComponents.length){
	//	  const cekTime=timerComponents.split(' ')
	//			console.warn('TMERRR....',cekTime[0])
	//  }
	   if(timeLeft.detik < 60){
			   if(timeLeft.detik===59){
				 setCektime(true)
				 }
				
	   }
		
  })
  useEffect(()=>{
	            if(timeLeft.detik===0){
				 // setCektime(false)
				 setAktifnya(true)
				}
  })
  
  
 //console.warn('Cek time',aktifnya)
 
 
  const kirimPassword=()=>{
	  if(passregister){
		  const requestpass={
			  email:usermail,passworr:passregister
		  }
					const responselogin= axios.put(apiService+'/registerpass',requestpass).then(resupdate => {
						console.warn('databalik',resupdate.data)
						let cekupdate=resupdate.data.hasOwnProperty('success');
						if(cekupdate){
							  if(resupdate.data.success[0].sts==='success'){
								  setJenisinput(false)
								  setModallogin(false)
								  setLoglanjut(false)
							  }
						}
					})
	  }
  }

	const handleOtpSubmit = (otp) => {
    // Lakukan sesuatu dengan OTP yang diinputkan
   // console.warn('isi OTP',otp)
       if(otp.length===6){
			if(timerComponents.length){
				if(otpnya===otp){
					//alert('otp benar')
					 const request1={nohplogin:nomorhp}
					 const responselogin= axios.post(apiService+'/loginbyhp',request1,{withCredentials: true}).then(res => {
						 console.log('data otp balik',res.data)
							let cekSukses=res.data.hasOwnProperty('success');
							if(cekSukses){
								  if(!res.data.success[0].accessToken){
										 alert('terjadi kesalahan')
									}else{
										   const loforPayurl={logurl:true}
									    history.push({
										pathname:"/",
										state :loforPayurl
										});
									}
							}else{
								alert('terjadi kesalahan')
							}
					 })
				}else{
					alert('otp salah')
				}

			}
	  }
	}


	//setAlertotp(false)
    //setKodecompare2(otp)
  const inputpasshandle=(e)=>{
	  setPassregister(e.target.value)
  }
  const inputHandler = (e) => {
	  setJenisinput(true)
	  setLoglanjut(true)
	  setUsermail(e.target.value)
	   setNomorhp(e.target.value)
	  setPesanusern('')
	  setPesanpass('')
   //setform({ ...form, [e.target.name]: e.target.value })
  // setInputnmr(e.target.value)
// console.warn('inputan',e.target.value)
 /*  if((e.target.value).includes('@')){
	  setJenisinput(false)
   }else{
	   setNomorhp(e.target.value)
	   setJenisinput(true)
   }
     setPesanusern('')*/
  }
  const handlerpass =(e)=>{
	  setPesanpass('')
  }


 	const handleOtpSubmitemail =(otp)=>{
	//	console.warn('otp email...',otp);
		     if(otp.length===6){
					if(timerComponents.length){
						if(otpnya===otp){
							const request22={
								emailuser:usermail,
								uname:username
							}
							     const responsedaftarbyemailverified = axios.post(apiService+'/daftardatabyemailverified',request22).then(resdaftar => {
							        	let cekSuksesreg=resdaftar.data.hasOwnProperty('success');
											if(cekSuksesreg){

													const requestbyemail={emailuser:resdaftar.data.success[0].emailnya}
													 const responselogin= axios.post(apiService+'/loginbyemailotp',requestbyemail,{withCredentials: true}).then(res => {
															let cekSukses=res.data.hasOwnProperty('success');
															if(cekSukses){
																  if(!res.data.success[0].accessToken){
																		 alert('terjadi kesalahan')
																	}else{
																		   const loforPayurl={logurl:true}
																			history.push({
																			pathname:"/",
																			state :loforPayurl
																			});
																	}
															}else{
																alert('terjadi kesalahan')
															}
													 })

											}
							     })

						}
					}
			 }
	}
  const otpByemail=(emailnya)=>{
	     const requestotpmail={
				usermailotp:emailnya
			}
			const response6 = axios.get(apiService+'/daftaremailotp',{params:requestotpmail}).then(res => {

				let cekSukses=res.data.hasOwnProperty('success');
				if(cekSukses){
					setOtpnya(res.data.success[0].kode)
				//	setFormotp(true)
				    setFormotpmailverifi(true)
				    setWaktu(Date.now()+61000);
				}else{
					alert('terjadi kesalahan request otp')
				}

			})


  }
  
  const kirimulangotp=(nomornya)=>{
	  loginBynohp(nomornya);
	  setAktifnya(false)
  }

//console.warn('akti',timerComponents)
  return (
    <React.Fragment>
        {loading && (
                <div className={`appie-loader ${loading ? 'active' : ''}`}>
                    <Loader />
                </div>
            )}
      <div className="account-pages my-5 pt-sm-5">
	   
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <h3 className="text-center mt-5 mb-4">
                    <Link to="/" className="d-block auth-logo">
					{/*<img src={logoDark} alt="" height="30" className="auth-logo-dark" />
					<img src={logoLightPng} alt="" height="30" className="auth-logo-light" />*/}
                    </Link>
                  </h3>
                  <div className="p-3">
                    <h4 className="text-muted font-size-18 mb-1 text-center">Selamat Datang</h4>
                    <p className="text-muted text-center">Masuk ke akun Satria Futsal.</p>
                    <AvForm
                      className="form-horizontal mt-4"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >
                      {props.error && typeof props.error === "string" ? (
                        <Alert color="danger">{props.error}</Alert>
                      ) : null}

                      <div className="mb-3">
                        <AvField
                          name="datauser"
                          label="Nomor Hp atau Email"

                          className="form-control"
                          placeholder="Ketikan email atau nomor Hp"
                          onChange={inputHandler}
                           validate={{
									required: {value: true, errorMessage: 'Tolong Email atau Nomor Hp di isi'}
								  }}
                        />
						<span style={{width: '100%',fontSize:'0.8em',color:'red', marginRight: '.5em',marginTop:'-16px',position:'absolute'}}>{ pesanUsern }</span>
                      </div>
                      {
						  jenisinput ? null :(
								  <div style={{marginTop:'25px'}} className="mb-3">
								<AvField
								  name="pwd"
								  label="Password"

								  type="password"

								  placeholder="Ketikan Password"
								    onChange={handlerpass}
									 validate={{
									required: {value: true, errorMessage: 'Tolong Password di isi'}
								  }}
								/>
								<span style={{width: '100%',fontSize:'0.8em',color:'red', marginRight: '.5em',marginTop:'-16px',position:'absolute'}}>{ pesanPass }</span>
							  </div>
						  )
					  }


                      {/*<div className="mb-3 row mt-4">
                        <div className="col-6">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customControlInline"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customControlInline"
                            >
                              Remember me
                            </label>
                          </div>
                        </div>

                      </div>*/}

					      <div className="mb-3 row" style={{marginTop:'40px'}} >
                          <Button style={{width:'100%'}} color="primary" type="submit">{loglanjut? 'Masuk':'Selanjutnya'}</Button>
                        </div>
						<div className="mt-4">
						    <p className="text-muted text-center">atau login dengan</p>
						</div>
						<div className="mt-4">
							   <Button className="text-muted" style={{width:'100%',backgroundColor:'#fff',color:'#000'}} onClick={() => handlelogin()}>
					     <i  style={{marginRight:'5px'}} className="fab fa-google fa-1x"></i>
					  Google </Button>
						{/*  <GoogleLogin
							onSuccess={credentialResponse => {
							  console.log(credentialResponse);
							}}

							onError={() => {
							  console.log('Login Failed');
							}}

						/>*/}
						</div>

                      <div className="form-group mb-0 row">
                        <div className="col-12 mt-4">
                          <Link to="/forgot-password" className="text-muted"><i className="mdi mdi-lock"></i> Lupa password?</Link>
                        </div>
                      </div>

                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Belum daftar akun ?{" "}
                  <Link
                    to="/register"
                    className="text-primary"
                  >
                    {" "}
                    Daftar sekarang{" "}
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()}
                  <span className="d-none d-sm-inline-block"> - di Desain <i className="mdi mdi-heart text-danger"></i> oleh.</span>
                </p>
              </div>
            </Col>
          </Row>

		         <Modal
                       backdrop="static" keyboard={false}
                      isOpen={modal_small}
                      toggle={() => {
                        tog_small()
                      }}
					  style={{
						    position: 'absolute',
							width:'85%',
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)'
					  }}
                    >
                      <div className="modal-header border-0">

                        <button
                          onClick={() => {
							                 tutup()
                          }}
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
					    {formotp && cektime ? (
						       <h5 style={{  textAlign: 'center', fontWeight: 'bold',fontSize: 18, marginTop: 0}}>Masukan kode Verifikasi</h5>
							):(
							   <h5 style={{  textAlign: 'center', fontWeight: 'bold',fontSize: 18, marginTop: 0}}>Pilih metode Verifikasi</h5>
							)
						}

	                  <div >
			   <Row>
			   <Col
                  style={{  textAlign: 'center'}}
				  sm={{
					offset: 1,
					order: 5,
					size: 10
				  }}
				>
				{
					formotp && cektime ?
				     ( <p>Kode verifikasi telah di kirim dalam pesan text ke {nomorhp}</p>):
					 ( <p>Pilih salah satu metode dibawah ini untuk mendapatkan kode verifikasi.</p>)

				}

				</Col>

			   </Row>
			   <Row>
			   </Row>
	               <Col style={{  marginTop:'40px', boxShadow: '1px 2px 9px #F4AAB9',marginBottom:'30px',padding:'10px'}}
					  sm={{
						offset: 1,
						order: 5,
						size: 10
					  }}
					>
					{ formotp && cektime ? (
					     <div >
							 <div style={{
							   alignItems: "center",
								 justifyContent: "center"
							 }}>
									<div className="text-center">
										{cektime ? (
											<Label>{timerComponents.length ? timerComponents : 'Waktu telah berakhir!'}</Label>
											):null
										}
									</div>
							        <OtpField numDigits={6} aktifasi={aktifnya} onOtpChange={handleOtpSubmit} />
									<div className="text-center">
										 {cektime ? (timerComponents.length ? null: <Label onClick={()=>kirimulangotp(nomorhp)}  style={{fontSize:'12px',cursor:'pointer'}}>Kirim ulang kode OTP</Label> ):null
										 }
									 </div>
						      </div>
						</div>

					) :(
						<div onClick={()=>loginBynohp(nomorhp)} style={{cursor:'pointer'}} className="media mb-0">
							<img
							  className="d-flex me-3 rounded-circle"
							  src={Sms}
							  alt="Lexa"
							  height="60"
							/>
							<div  style={{marginTop:10}} className="media-body">
									  <h5 style={{fontSize:'14px'}} className="mt-0 text-muted">Kode OTP dikirim ke</h5>
									  <p className="mt-0 text-muted">{nomorhp}</p>

							</div>
						</div>
						)
					}

				</Col>
			  </div>

                      </div>
                    </Modal>


					{/*///////////////////////////////////////////MODAL SET PASSSWORD UNTUK LOGIN EMAIL USER //////////////////////*/}
					  <Modal
                       backdrop="static" keyboard={false}
                      isOpen={modallogin}
                      toggle={() => {
                        tog_modallogin()
                      }}
					  style={{
						    position: 'absolute',
							width:'500px',
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)'
					  }}
                    >
                      <div className="modal-header border-0">

                        <button
                          onClick={() => {
							  tutupModallogin()
                          }}
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
					  <div className="modal-body">
						 <h5 style={{  textAlign: 'center', fontWeight: 'bold',fontSize: 16, marginTop: 0}}>Mohon ketikan Password untuk melengkapi informasi dan keamanan akun anda</h5>
				      </div>
					   <Row>
						   <Col
							  style={{  textAlign: 'center'}}
							  sm={{
								offset: 1,
								order: 5,
								size: 10
							  }}
							>
								  <AvForm >
								  <AvField name="password" label="Password" type="password" onChange={inputpasshandle}
								       validate={{
									required: {value: true, errorMessage: 'Tolong Password di isi'}
								       }}
								  />

								</AvForm>
							</Col>

					   </Row>
					    <Row style={{marginBottom:'30px',marginTop:'15px'}}>
						   <Col
							  style={{  textAlign: 'center'}}
							  sm={{
								offset: 1,
								order: 5,
								size: 10
							  }}
							>
						    <Button  onClick={()=>kirimPassword()} style={{  color: '#fff', letterSpacing: '1px', border: 'none',backgroundColor: '#4070f4', cursor: 'pointer'}}>
									 Kirim
							</Button>
							</Col>

					   </Row>
			       </Modal>
				   {/*//////////////////////////////////////////LOGIN BY EMAIL NOT EXIST THEN REGISTER//////////////////////////////////////////////////////////////////////*/}
                    <Modal

                       backdrop="static" keyboard={false}
                      isOpen={modal_mailverifid}
                      toggle={() => {
                        tog_mailverifid()
                      }}
					  style={{
						    position: 'absolute',
							width:'500px',
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
							 borderRadius: '30px'
					  }}
                    >
                      <div className="modal-header border-0">

                        <button
                          onClick={() => {
							  tutupmailverifid()
                          }}
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
					  <div className="modal-body">
						 <h5 style={{  textAlign: 'center', fontWeight: 'bold',fontSize: 18, marginTop: 0}}>{formotpmailVerifi ? 'Verifikasi kode':'Email belum terdaftar'}</h5>
				      </div>
					   <Row>
						   <Col
							  style={{  textAlign: 'center'}}
							  sm={{
								offset: 1,
								order: 5,
								size: 10
							  }}
							>
                            {formotpmailVerifi ? (
							    <p>Masukan kode verifikasi yang di kirim ke <span style={{fontWeight:900}}>{usermail}</span></p>
							):(
							    <p>Daftar menggunakan email <span style={{fontWeight:900}}>{usermail}</span></p>
							)}

							</Col>

					   </Row>
					    <Row style={{marginBottom:'30px',marginTop:'15px'}}>
						   <Col
							  style={{  textAlign: 'center'}}
							  sm={{
								offset: 1,
								order: 5,
								size: 10
							  }}
							>
							{/*  <Button   style={{  color: '#fff', letterSpacing: '1px', border: 'none',backgroundColor: '#4070f4', cursor: 'pointer'}}>
															 Daftar
							</Button>	*/
								formotpmailVerifi ? (
									<div style={{cursor:'pointer'}} className="media mb-0">
													  {cektime ? (
													     	<div  style={{  borderRadius: '15px',paddingTop:'10px',paddingBottom:'10px',marginTop:10,marginBottom:45,boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px'}} className="media-body">
																<Label>{timerComponents.length ? timerComponents : ( <Label style={{fontSize:'16px',color:'red'}}>Waktu telah berakhir!</Label>)}</Label>
																<OtpField numDigits={6} onOtpChange={handleOtpSubmitemail} />
																	{timerComponents.length ? null : (
																	  <Label onClick={()=>{otpByemail(usermail)}} style={{cursor:'pointer',fontSize:'12px',color:'blue',marginTop:'10px'}}>Kirim ulang OTP</Label>
																	)}
															</div>
												    	 ):(
														    <div  style={{  borderRadius: '15px',paddingTop:'10px',paddingBottom:'10px',marginTop:10,marginBottom:45}} className="media-body">

																<Label>{'   '}</Label>
															</div>
														 )
												       }


									</div>
								): (
									<div onClick={()=>otpByemail(usermail)} style={{cursor:'pointer'}} className="media mb-0">

											<div  style={{  borderRadius: '25px',paddingTop:'10px',paddingBottom:'10px',marginTop:10,marginBottom:45,boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px'}} className="media-body">
													  <h5 style={{fontSize:'14px'}} className="mt-0 text-muted">Kode OTP dikirim ke</h5>
													  <p className="mt-0 text-muted">{usermail}</p>

											</div>
									</div>
								)

							}

							</Col>

					   </Row>
			       </Modal>
	   </Container>
      </div>
    </React.Fragment>
  )
}
//export default Login

const mapStateToProps = (state) => {
  //  console.log(state);
    return ({
        responseLOGIN: state.logina.userq,

    })
};

const mapDispatchToProps = (dispatch) => ({
    actions: {
        userMasuk: (payload) => {
			//console.warn('data payload',payload)
            dispatch(actionType.loginUser(payload));
        },


    },
});
export default connect(mapStateToProps, mapDispatchToProps)(Login);
