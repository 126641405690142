import React, { useEffect } from 'react';
import axios from 'axios'
function Paysukses() {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const paramValue = params.get('order_id');
    //console.warn('param---------------',paramValue); 
	let datacari={
		idorder:paramValue
	}
	 const responseOrder =  axios.post('http://localhost:4001/datatransaksimidtrans', datacari).then(result=>{
		 console.warn('data dari midtrans',result.data)
	 })
  }, []);

  return <div className="bg-success">My Component</div>;
}

export default Paysukses;