import React, { useState, useRef } from 'react';
import { useLocation,Link,useHistory } from "react-router-dom";
import styled from 'styled-components';
import logo from '../../assets/images/logo.png';
import userimage from '../../assets/images/user.jpg';
import moment from 'moment-timezone';
import { Row, Col,Button, CardBody, Alert, Container, Input, Label,  Modal,Text } from "reactstrap";
import { AvForm, AvField,AvInput } from "availity-reactstrap-validation";
import {
  CardMeta,
  CardHeader,
  CardDescription,
  CardContent,
  Card,
  Icon,
  Image,GridColumn, GridRow,Grid,ListItem, ListHeader, List,ListContent,
} from 'semantic-ui-react'
//import { PaperClipIcon } from '@heroicons/react/20/solid'
const OtpContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const OtpInput = styled.input`
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 18px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 0 5px;

  &:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  }

  @media (max-width: 600px) {
    width: 30px;
    height: 30px;
  }
`;


const Tespage = React.forwardRef((props, ref) => {
//	const Tespage=(props)=> {
		const {datainfoice,deskripsiinv,datatotbayarinv}=props
	 const history = useHistory();
	   const location = useLocation();
  const WaktuInvoice=(tglorder)=>{
	       const tgl = new Date(tglorder);
		const tglkonversi = moment(tgl).tz('Asia/Bangkok');
				const monthNames = [
				'01', '02', '03', '04',
				'05', '06', '07', '08',
				'09', '10', '11', '12'
				];
		const jamnya=tglkonversi.format('HH:mm');
		const monthIndex = tgl.getMonth();
		const monthName = monthNames[monthIndex];
		const tahun = tgl.getFullYear();
		const tglfix = tgl.getDate();
		const tglORDER1 =tahun+'/'+monthName+'/'+tglfix
		return tglORDER1;
}

const jamdetilinv=(tglbook,jmawal,jmakhir)=>{
	let format1= jmawal+':00'
	let format2=jmakhir+':00'
	return tglOrder(tglbook)+', '+format1+' - '+format2
}

const tglOrder=(tglcreate)=>{
		const tgl = new Date(tglcreate);
		const tglkonversi = moment(tgl).tz('Asia/Bangkok');
		const monthNames = [
				'Januari', 'Februari', 'Maret', 'April',
				'Mei', 'Juni', 'Juli', 'Agustus',
				'September', 'Oktober', 'November', 'Desember'
				];
		const tahun = tgl.getFullYear();
		const monthIndex = tgl.getMonth();
		const monthName = monthNames[monthIndex];
		const tglfix = tgl.getDate();
		//seTgltransaksi(`${tglfix}`)
		const hasil=tglfix+' '+monthName+' '+tahun
		return hasil;
}
const grandtot=(total)=>{
	let vat=(total*11)/100
	let grand=total+vat
		const formattedCurrency = new Intl.NumberFormat('id-ID', {
				  style: 'currency',
				  currency: 'IDR',
				  minimumFractionDigits: 0,
				}).format(grand);
				
				return formattedCurrency
}

const hitungtotbiaya=(akhir,awal)=>{
	const hitung=Number(akhir)-Number(awal)
	const hasil=hitung*125000
	   const formattedCurrency = new Intl.NumberFormat('id-ID', {
				  style: 'currency',
				  currency: 'IDR',
				  minimumFractionDigits: 0,
				}).format(hasil);
	return formattedCurrency
}

const hitungvat=(nominal)=>{
	let hasilakhir=(nominal*11)/100
	const formattedCurrency = new Intl.NumberFormat('id-ID', {
				  style: 'currency',
				  currency: 'IDR',
				  minimumFractionDigits: 0,
				}).format(hasilakhir);
				
				return formattedCurrency
}

const formattotbiayainv=(nominal)=>{
	
	   const formattedCurrency = new Intl.NumberFormat('id-ID', {
				  style: 'currency',
				  currency: 'IDR',
				  minimumFractionDigits: 0,
				}).format(nominal);
				
				return formattedCurrency
}

  return (
  
     <div className="row" ref={ref}>
		   <div className="container-md">
			  <div className="row border-bottom pt-3 pb-3">
			
				<div className="col-2">
				  INVOICE
				</div>
				<div className="col-8">
				  
				</div>
				<div className="col-2 text-end">
				  Print
				</div>
			  </div>
			  
			  <div className="row pt-3 pb-3" style={{borderBottom:'2px solid #ccc'}}>
				<div className="col-1">
				  
				</div>
				<div className="col-2">
				  <img style={{widht:'360px',height:'50px',marginTop:'20px'}} src={logo} alt="" />    
				</div>
				<div className="col-6">
				  
				</div>
				<div className="col-3">
				    <span>www.satriafutsal.com</span><br/>		
                    <span>+123 123456789</span>	<br/>		
                    <span>JL. Hayam wuruk No 54</span><br />	
                    <span>Kec Petemon, Surabaya, Jawa Timur</span>							
				</div>
			  </div>
			  
			  <div className="row" >
				<div className="col-3 pt-4">
				    <div className="row">
					    <h6 className="mb-0" style={{fontSize:'15px'}}><b>Tgl Order : </b>{WaktuInvoice(datainfoice.tgltransaksi)}</h6><br />
						<h6 style={{fontSize:'14px',fontWeight:'600',marginTop:'10px'}}><b>Nomor :</b></h6><br />
						<p style={{fontSize:'12px'}}>{datainfoice.invno}</p>
					</div>					
				</div>
				<div className="col-5 pt-3">
				     <div className="row">				
						<span style={{fontSize:'16px',fontWeight:'550'}}>Pelanggan :</span><br />
						<span style={{fontSize:'13px'}}>{datainfoice.namapel}</span><br />
						<span style={{fontSize:'13px'}}>{datainfoice.alamatpel}</span><br />
						<span style={{fontSize:'13px'}}>{datainfoice.perusahaanpel}</span><br />
						<span style={{fontSize:'13px'}}>{datainfoice.emailpel}</span><br />						
						<span style={{fontSize:'13px'}}>{datainfoice.hppel}</span>						
					 </div>
				</div>
				<div className="col-4">
				    	<div className="text-center bg-light p-3 mt-3 mr-6">
							<h5 style={{fontSize:'16px'}} className="bg-primary mt-0 p-2 text-white d-sm-inline-block">Metode Pembayaran</h5>
							<h6 style={{fontSize:'12px'}} >Online Banking, Transfer, Retail Outlet :</h6>
							<p style={{fontSize:'12px'}} className="mb-0 text-muted">satriaFutsal@gmail.com</p>
							<p style={{fontSize:'12px'}} className="mb-0 text-muted">Visa, Master Card, Chaque</p>
						</div>   
				</div>			
			  </div>
			  <div className="row mt-4" >
			         <div className="bg-secondary col-2 text-white p-2">Quantitas</div>
					 <div className="bg-secondary col-2 text-white p-2">item</div>
					 <div className="bg-secondary col-4 text-white p-2">Deskripsi</div>
					 <div className="bg-secondary col-2 text-white p-2">Harga/Jam</div>
					 <div className="bg-secondary col-2 text-white p-2">Total</div>
			  </div>
			  {	deskripsiinv.map(itemdesc=>{
					return(
					
					      <div className="row" >
							 <div className="col-2 ">{Number(itemdesc.jamakhir)-Number(itemdesc.jamawal)+' Jam'}</div>
							 <div className="col-2 ">{itemdesc.namalap}</div>
							 <div className="col-4 ">{jamdetilinv(itemdesc.ordertgl,itemdesc.jamawal,itemdesc.jamakhir)}</div>
							 <div className="col-2 ">Rp. 125.000</div>
							 <div className="col-2 ">{hitungtotbiaya(itemdesc.jamakhir,itemdesc.jamawal)}</div>
			              </div>				
					)																
				})
	          }				
			     <div className="row pt-2" style={{borderTop:'2px solid #ccc'}} >
					 
					 <div className="col-8 "></div>
					 <div className="col-2 "><span style={{fontSize:'16px',fontWeight:'550'}}>Subtotal</span></div>
					 <div className="col-2 ">{formattotbiayainv(datatotbayarinv)}</div>
				  </div>
				   <div className="row "  >					 
					 <div className="col-8 "></div>
					 <div className="col-2 "><span style={{fontSize:'16px',fontWeight:'550'}}>Tax 11%</span></div>
					 <div className="col-2 ">{hitungvat(datatotbayarinv)}</div>
				  </div>
				    <div className="row bg-dark pt-2"  >					 
					 <div className="col-8 "></div>
					 <div className="col-2 text-white"><span style={{fontSize:'16px',fontWeight:'550'}}>Total</span></div>
					 <div className="col-2 text-white">{grandtot(datatotbayarinv)}</div>
				  </div>
				  
				   <div className="row justify-content-center">
						<div className="col-6">
							<h5 className="mt-4">Syarat dan Ketentuan :</h5>
							<ul className="pl-3">
								<li><small>Harap melakukan pembayaran 30 menit sebelum jam booking. </small></li>
								<li><small>Pembayaran bisa di lakukan melalui ATM, Qris, Gerai Outlet dan di Satria futsal.</small></li>
																	 
							</ul>
						</div>                                        
						<div className="col-6 align-self-end">
							<div className="w-25 float-right">
								<small>Hormat kami</small>
								<img src="assets/images/signature.png" alt="" className="" height="48"/>
								<p className="border-top">Signature</p>
							</div>
						</div>
					</div>
                                        <hr />
					<div className="row d-flex justify-content-center">
						<div className="col-lg-12 col-xl-4 ml-auto align-self-center">
							<div className="text-center text-muted"><small>Trima kasih sudah berkunjung ke Satria Futsal!</small></div>
						</div>
					  
					</div>
			  
			  
			</div>		  
		</div> 
  
  );
})

export default Tespage;
