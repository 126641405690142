import { combineReducers } from "redux"



// Authentication
import logina from "./auth/login/reducer"



const rootReducer = combineReducers({

  logina,

})

export default rootReducer