import React, { useState, useRef } from 'react';
import styled from 'styled-components';
const OtpContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const OtpInput = styled.input`
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 18px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 0 5px;

  &:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  }

  @media (max-width: 600px) {
    width: 30px;
    height: 30px;
  }
`;

const OtpField = ({ numDigits,onOtpChange,aktifasi  }) => {
  const [otp, setOtp] = useState(new Array(numDigits).fill(''));
  const otpFields = useRef([]);

  const handleChange = (e, index) => {
    const value = e.target.value;

    if (isNaN(value)) {
      return;
    }

    const newOtp = [...otp];
    newOtp[index] = value.slice(-1);
    setOtp(newOtp);

    if (value !== '') {
      if (index === numDigits - 1) {
        otpFields.current[index].blur();
      } else {
        otpFields.current[index + 1].focus();
      }
    }

	 if (onOtpChange) {
      onOtpChange(newOtp.join(''));
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && otp[index] === '') {
      if (index === 0) {
        return;
      }

      otpFields.current[index - 1].focus();
    }
  };

  return (
    <OtpContainer>
      {otp.map((digit, index) => (
      <OtpInput
          disabled={aktifasi}
          key={index}
          type="text"
          maxLength="1"
          value={digit}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          ref={(el) => (otpFields.current[index] = el)}
        />
      ))}
     </OtpContainer>
  );
};

export default OtpField;
