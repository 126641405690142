import React, { useState,useEffect,useRef } from "react"
import Calendar from 'react-calendar';
import jwt_decode from "jwt-decode";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Modal,Button,CardTitle
} from "reactstrap"
import Time from './Time.js'
import Jam from './Jam.js'
import { withRouter,useHistory, Link } from "react-router-dom"
import shapeTwo from '../../assets/images/shape/shape-2.png';
import shapeThree from '../../assets/images/shape/shape-3.png';
import shapeFour from '../../assets/images/shape/shape-4.png';
import logo from '../../assets/images/logo.png';
import heroThumbOne from '../../assets/images/hero-thumb-1.png';
import heroThumbTwo from '../../assets/images/hero-thumb-2.png';
import axios from 'axios';
import dayjs from 'dayjs';
import apiService from '../../api/apiService'
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
//import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';
import { makeStyles } from '@mui/styles';
import moment from 'moment'
 import dateFormat from 'dateformat';
import TestimonialHomeTwo from './TestimonialHomeTwo';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import testmonialUser from '../../assets/images/testimonial-user-1.png';
import SweetAlert from "react-bootstrap-sweetalert"
import { faCheckSquare, faCoffee } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope,faCartShopping,faStar } from '@fortawesome/free-solid-svg-icons'
//  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
  import { connect,useDispatch,useSelector } from "react-redux";

const useStyles = makeStyles({
  root: {

    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white'

  },
});
function HeroHomeOne({ className,datasharing, sharedState,setSharedState,logurlpay,setLogurlpay,datakeranjang,setDatakeranjang }) {
      
	     const [qrisUrl, setQrisUrl] = useState('');
		const [date, setDate] = useState(new Date())
		const [showTime, setShowTime] = useState(false)
		const [wktredy,setWktredy] = useState([])
		const [Lap, setLap]=useState([])
		const [modal_center, setmodal_center] = useState(false)
		const [currentuser,setCurrentuser]=useState('');
		const [value2, setValue2] = React.useState(null);
		const [tglpilih,setTglpilih]=useState(new Date())
		// const [value, setValue] = React.useState(dayjs('2022-04-17'));
		const [jamaktif,setJamaktif]=useState([])
		const jambooking=[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24]
		const [value, setValue] = React.useState(dayjs(new Date()));
		const [value3, setValue3] = React.useState(null);
		const [widthr,setWidtr]=useState(0);
		const [clock,setClock]=useState(false)
		const [keranjangexis,setKeranjangexis]=useState(false)
		const [jamnotice,setJamnotice]=useState('Jam Akhir sewa');
		const [jamnonaktif,setJamnonaktif]=useState([]);
		const [jamurgent,setJamurgent]=useState([])
		//const [jamwarning,setJamwarning]=useState([])
		const [cartjam,setCartjam]=useState([]);
		const [pajak,setPajak]=useState(0);		
		const [noorder,setNoorder]=useState(0);
		const [grandtotal,setGrandtotal]=useState(0);
		const [jmepet,setJmepet]=useState(false)
		const [loginData, setLoginData] = useState(null);
        const [idpay,setIdpay]= useState('');
		const [stsjmbook,setStsjmbook] = useState(false);
		const [name, setName] = useState('');
		const [tokenPay,setTokenpay]=useState("");
		const [token, setToken] = useState('');
		const [expire, setExpire] = useState('');
		const history = useHistory();
		const [statuscancel,setStatuscancel]=useState(false);
		const [jampesan,setJampesan]=useState({
		jamawalpesan:null,
		jamakhirpesan:null
		});
		const [dataorder,setDataorder] = useState({
		idpelanggan:'',
		lapangan:'',
		jamawalorder:null,
		jamakhirorder:null,
		tglorder:null
		})

		const tampilwaktu=async()=>{
		//  await	alert(value)
		//  const request={tanggal : date}
		}

		const [with_title, setwith_title] = useState(false)
		const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = (date) => {

  //  setSelectedDate(date);
  };
  
  
  const fetchQrisBarcodeData = async () => {
 /* const requestData = {
    payment_type: 'qris',
    transaction_details: {
      order_id: `order-20240609141855879`, // ID pesanan unik
      gross_amount: 10000,
    },
    qris: {
      acquirer: 'gopay',
    },
  };*/
 const requestData={idorder:'order-20240609141855879'}

  try {
    const response = await axios.post(apiService+'/tampilqrcode', requestData);
	// const qrisAction = response.data.actions.find(action => action.name === 'generate-qr-code');
	//	console.warn('Dataaaaaaaaaaaaaaaaaaa',response)
   // return response.data.actions.find(action => action.name === 'generate-qr-code').url;
  } catch (error) {
    console.error('Error fetching QRIS barcode data:', error.response ? error.response.data : error.message);
    return null;
  }
};
  
  const getQrisData = async () => {
    const qrisData = await fetchQrisBarcodeData();
	

    //setQrisUrl(qrisData);
  };

  useEffect(() => {
    getQrisData();
  }, []);
  
  
  
     const classes = useStyles();
     function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }

   function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function handleAccept(a){
	//  console.warn(a)
  //  alert('Selected date:', a);
  }

  const sliderRef = useRef();
    const sliderNext = () => {
        sliderRef.current.slickNext();
    };
    const sliderPrev = () => {
        sliderRef.current.slickPrev();
    };
    const settings = {
          dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,


      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />
    };

	function CurrencyFormat({ amount }) {
  // Function to format the currency
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
    }).format(value);
  };
  return <span>{formatCurrency(amount)}</span>;
}


  const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
};

  function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block"}}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block"}}
      onClick={onClick}
    />
  );
}



  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

const pesanJam=()=>{
// setCartjam(prevState => [...prevState, jampesan]);
	//console.warn('Jam start dan akhir',jampesan)
/*	const request={
		waktu:[{jamawal:'2:00', jamakhir:'4:00'},{jamawal:'1:00', jamakhir:'2:00'}],
		lapangan:idlapangan,
		pelanggal:idpelanggan,
		email:emailpelanggan
	}*/
}



///////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////PENYIMPANAN KERANJANG//////////////////////////////////////////////////////////////
const tambahKeranjang=async()=>{
    //  console.warn('data order',dataorder)
    if(dataorder.jamawalorder===null || dataorder.jamakhirorder===null){
      setStsjmbook(true)
    }else{
       const responseOrder = await axios.post(apiService+'/simpankeranjang', dataorder).then(resultorder=>{
      // console.warn('hasil simpan keranjang',resultorder.data)
	   let hasKey = resultorder.data.hasOwnProperty('errors');
           if(hasKey){
               setKeranjangexis(true)
           }else{
               setSharedState(resultorder.data.jumlah)
               setLap(resultorder.data.datalapangan)
               setDatakeranjang(resultorder.data.datadetil)
           }
     
        setmodal_center(false)
         setClock(false)
		 setCartjam([]);
	 
	   setPajak(0);
	   setGrandtotal(0)
       })
    }


}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////BAYAR LANGSUNG////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const Bayarlangsung=async()=>{

     ///  updateBayarlangsung();
	    const requestbayar={
		     idpelanggan:'',
             idlap:'',
             tglsewa:'',
             jamAwal:'',
             jamAkhir:'',
             statusbayar:''
		 }

     if(dataorder.jamawalorder===null || dataorder.jamakhirorder===null){
       setStsjmbook(true)
     }else{
          const response6 = await axios.get(apiService+'/token',{withCredentials: true}).then(res => {
            // console.warn('response', res.data)
           let hasKey = res.data.hasOwnProperty('errors');
           if(hasKey){
             history.push("/login");
               setLoginData(null)
           }else{
              langsung()
           }
        })
     }


}

const langsung=async()=>{
	  const databayar={
              amt:grandtotal,
              idpelanggan:dataorder.idpelanggan,
	       		  unamepelanggan:name,
              lapangan:dataorder.lapangan,
              jamawalorder:dataorder.jamawalorder,
              jamakhirorder:dataorder.jamakhirorder,
              tglorder:dataorder.tglorder
            }
            const konfig={
              headers:{
                 "Content-Type":"application/json"
              }
            }
             await axios.get(apiService+'/getpaymentlangsung',{ params: databayar},konfig).then(res => {
                   // console.warn('databali dari serverrrrrr',res.data)
                    let cekError=res.data.hasOwnProperty('errors');
                    if(cekError){
                   //   setStatuspilih(true)
                      //pesan Error saat tidak ada yang di pilih untuk di bayar
                    }else{
                    //   setTokenpay(res.data.token);
                       let hasKey = res.data.hasOwnProperty('errors');
                       if(hasKey){
                         console.warn('errorrr dari create token')
                       }else{
                       //   console.warn('sukses dari create token',res.data)
                          setTokenpay(res.data.token);
                          setIdpay(res.data.idayment);
						  createInvoice(res.data.idayment);
                       }

                      // setIdpay(res.data.idorderpay);
                      //  console.warn('Data interchange',res.data.idorderpay)
                    //   tambahdataorder()
                      //console.warn('data detil order',res.data.datadetail.transaction_details.order_id)
                      //console.warn('id ordernya',res.data.idorderan)
                      //setIdorderbayar(res.data.idorderan);
                      // updateDatabayar();
                    }
                    // tokenID = res.data
                      //console.warn('Data interchange',res.data.datasisa)
                    // setTokenpay(res.data.token)
                 })
}

const createInvoice=async(idpayment)=>{
	 const databayarlangsung={
    emailuser:dataorder.idpelanggan,
    idorderpayment:idpayment
  }
    const createInv=await axios.post(apiService+'/createinvoice', databayarlangsung).then(resultinv=>{
		
	})
}

const updatalangsungcancel=async(mailuser,idorder)=>{
	     const dataordercancel={
			emailuser:mailuser,
			idorderpayment:idorder
		 }

		// const tesss={idorderpay:dataidpay}
         await axios.post(apiService+'/updateordercancel', dataordercancel).then(resultorder=>{
			// console.warn('dataaaa...... cancel ',resultorder.data)
			 let hasKey = resultorder.data.hasOwnProperty('errors');
			 if(hasKey){
				 
			 }else{
				 setmodal_center(false); 
				  setStatuscancel(false)
				    setClock(false)
			 }
                

         })
}


const updateBayarlangsung=async(dataparam)=>{
 /* const requestbayar={
    idpayment:idpay,
    emailpel:mailuser
  }
  const requestbayar={
               idpayment:'order-20231207091019724',
                emailpel:'agus.treee@gmail.com'
  }
   history.push({
     pathname:"/order-bayar",
     state :requestbayar
   });
     setmodal_center(false);*/
 /*const databayarlangsung={
    emailuser:mailuser,
    idorderpayment:idpay
  }*/
	
    const dataorderbayarlangsung = await axios.post(apiService+'/updatepembayaranlangsung', dataparam).then(resultbayar=>{
       let hasKey = resultbayar.data.hasOwnProperty('errors');
       if(hasKey){

       }else{
		  // console.warn('data dari api server',logurlpay)  
                if(logurlpay){
					setLogurlpay(false)
				}else{					
					const requestbayar={
					 idpayment:resultbayar.data.success[0].databalik.order_id,
			   //    emailpel:mailuser			
					}			 
					history.push({
					pathname:"/order-bayar",
					state :requestbayar
					});
				}
			 
                setmodal_center(false);
       }

     })
}

const updateBayarlangsungredirect=async(dataparam)=>{	
    const dataorderbayarlangsung = await axios.post(apiService+'/updatepembayaranlangsung', dataparam).then(resultbayar=>{
       let hasKey = resultbayar.data.hasOwnProperty('errors');
       if(hasKey){

       }else{
		   //console.warn('data dari api server',resultbayar.data)
             const requestbayar={
               idpayment:resultbayar.data.success[0].databalik.order_id,
           //    emailpel:mailuser			
             }
			 
            /*	history.push({
                pathname:"/order-bayar",
                state :requestbayar
              });*/
                setmodal_center(false);
       }

     })
}

useEffect(() => {

//   const addMidtransScript = async () => {
//change this to the script source you want to load, for example this is snap.js sandbox env
const midtransScriptUrl = 'https://app.sandbox.midtrans.com/snap/snap.js'
//change this according to your client-key
const myMidtransClientKey = 'SB-Mid-client-CdIoeAyfCFXBCy2T'

let script = document.createElement('script')
script.src = midtransScriptUrl
script.async = true
// optional if you want to set script attribute
// for example snap.js have data-client-key attribute
script.setAttribute('data-client-key', myMidtransClientKey)

//    script.onload = () => {
//    setSdkReady(true)
// }
document.body.appendChild(script)
//  }

return ()=>{
 document.body.removeChild(script)
}
//  addMidtransScript()
// if (!order || order._id !== orderId) {
//   dispatch(getOrderDetails(orderId))
// }
}, [])

useEffect( ()=>{
		if(tokenPay){
			window.snap.pay(tokenPay,{
			 // onSuccess:async(result)=>{
			  //  localStorage.setItem("Pembayaran",JSON.stringify(result))
			  //  console.warn('pembayaran ter create')
			//   console.warn('idpayycddddddddddddddddddddddddddddddddddddddddyyyy',idpay)			  
			  //await  updateBayarlangsungredirect(result)			  
			//	setTokenpay("")
			 // },
			  onPending:async(result)=>{
				//  console.warn('idpayyyyyy',idpay)
			 //  console.warn('Transaksi Pendingnggggggggggggggggggggggggggggggggggggggggggg',result);
				await updateBayarlangsung(result)
			//     tambahdataorder()
				//  updateDatabayar();
				  setTokenpay("")
			  },
			  onError:(error)=>{
				  setTokenpay("")
			  },
			  onClose:()=>{
			 //   console.warn('Transaksi Batal',idpay)
			    setStatuscancel(true)			
				setTokenpay("")
			  }
			})
		}
},[tokenPay])


const batalbayar=()=>{
	    updatalangsungcancel(dataorder.idpelanggan, idpay)
		
}


/*////////////////////////////PILIH JAM BOOKING///////////////////////////////////////*/
const pilihjam=async(jam,jamdisable,idlapang)=>{
	//getDetails();


    //const jam1=Number(jam);
	//let jamu='';
	   if(jam>9){
		  setNewTimeFromString(jam+':00');
	   }else{
		   setNewTimeFromString('0'+jam+':00');
	   }
	  let arrtemp=[];

	//  list.sort((a, b) => (a.qty > b.qty) ? 1 : -1)
	  const sortaa = jamdisable.sort((a, b) => a-b)
	//     sortaa.map(function(item) {
	//					       console.warn('isi array',item)

	//					  })
	  if(jam<sortaa[sortaa.length-1]){
		  for(let i=0; i<sortaa.length;i++){

				if(jam<sortaa[i]){
					for(let j=(jam+1); j<=sortaa[i]; j++){
							arrtemp.push(j)
					}
					break;
				}
		   }
	  }else{
		  //	console.warn('isi array',sortaa[sortaa.length-1])
		  for(let n=(jam+1); n<=24;n++){
			  if(n===24){
				   arrtemp.push(0)
			  }else{
				   arrtemp.push(n)
			  }

		  }
	  }

	  /*
			jamawalorder:null,
			jamakhirorder:null,
			tglorder:null*/
   const updatedDataorder = { ...dataorder,
                  lapangan:idlapang
			  };

   setDataorder(updatedDataorder);


	//   for(let x=jam; x<24; x++){
		//   console.warn('panjang array',sortaa.length)
		  // for(let i=0; i<sortaa.length;i++){
			//   if(x<= sortaa[i]){
			//	    arrtemp.push(i)
			//	      break;
			 //  }
		  // }
		 //  if(!jamdisable.includes(x)  ){

		  // }
	 //  }

	 	/*   for(let j=1; j<=24; j++){
		       if(j>jam){


					   if(!jamdisable.includes(j-1) && jamdisable.includes(jam+1) && jamdisable.includes(j) && j < (jam+1)){
						  // setJamnonaktif(jamnonaktif => [...jamnonaktif,j] );
						  arrtemp.push(j)
						// setJamnonaktif(j)
					   }else{
						   if(!jamdisable.includes(j-1) && !jamdisable.includes(jam+1) && !jamdisable.includes(j)){

						   }
					   }
			   }
			   //setJamnonaktif(jamnonaktif => [...jamnonaktif,newValue] );
		  if(jamdisable.includes(j)){
				   setJamnonaktif(jamnonaktif => [...jamnonaktif,j] );
			   }else{
				   if(jamdisable.includes(j-1)){
					    setJamnonaktif(jamnonaktif => [...jamnonaktif,j] );
				   }else{
					    setJamnonaktif(jamnonaktif => [...jamnonaktif,j] );
				   }
			   }

	   }*/
	   setJamnonaktif(arrtemp)
	 //  setJamnonaktif(jamdisable);
	 //  console.warn('array jam yang terpilih',jamdisable)
	  // console.warn('jam yang terpilih',jam)
	   	const response6 = await axios.get(apiService+'/token',{withCredentials: true}).then(restoken => {

		  	 let hasKey = restoken.data.hasOwnProperty('errors');
					 if(hasKey){
						// setLoginData(null)
						// setExpire(0);
						masukLogin()
					 }else{
					  //   setToken(res.data.accessToken);
						 const decoded = jwt_decode(restoken.data.accessToken);
						  //  return decoded.exp;
						  // setName(decoded.username);
                          console.warn('data company',decoded.company)
						  if(decoded.alamat && decoded.username && decoded.company){
						  setExpire(decoded.exp);
						  setmodal_center(true);
                          cariNoOrder();
						}else{

							      const datauser={
									  emailuser:decoded.email,
									  hpuser:decoded.nohp,
									  alamatuser:decoded.alamat,
									  companyuser:decoded.company,
									  usernam:decoded.username,
                                      panelactiv:'biodata'
								  }
									history.push({
									 pathname:"/user-profile",
									 state :datauser
									});
						}
						//  console.warn('Alamat penyewa..',decoded.alamat)
						// setLoginData(res.data.accessToken)

					 }
	})

	  // Call setNewTimeFromString with your time string
}
 const cariNoOrder=()=>{
    const resOrder = axios.get(apiService+'/carinoorder', dataorder).then(resultorder=>{
      //  console.warn('NOMOR ORDER',resultorder.data)
        setNoorder(resultorder.data)
    })
 }

 //console.warn('jam yang tidak aktif',jamnonaktif)
const shouldDisableTime = (time) => {
    // Implement your logic to disable specific times
    // For example, disable times before 9 AM and after 6 PM
    return time.getHours() < 9 || time.getHours() > 18;
  };


 const setNewTimeFromString = (timeString) => {
    const [hours, minutes] = timeString.split(':');
    const newTime = new Date();
    newTime.setHours(hours);
    newTime.setMinutes(minutes);
    setValue3(newTime);
  };

  // Example usage:
 function pilihjam2(jam2){

	// let wkt1 =value3.toLocaleString('en-US', { timeZone: 'Asia/Jakarta' });
	// setValue3(jam2)
	if(jam2===null){

	}else{
let jamawl1=0;
let jamawl2=0;
   // let indochinaTime1 = new Date(value3);
	//let indochinaTime = moment(indochinaTime1).tz('Asia/Bangkok');	
   // let datesd = moment().format('MM/DD/YYYY hh:mm:ss A');
   	    const tgl = new Date(value3);
		const tglkonversi = moment(tgl).tz('Asia/Bangkok');	

 // const formattedDate = format(tglkonversi, 'MM/dd/yyyy hh:mm:ss a');
	const jm1=tglkonversi.format('hh:mm:ss A');		

  //  console.warn('pilihan JAM',jamnya)
var poswaktu1=jm1.split(' ');
var piecesr = poswaktu1[0].split(":");

 let valjamawal=0;
if(poswaktu1[1]==='AM'){
	 valjamawal=Number(piecesr[0]);
}else{
	if(Number(piecesr[0])===12){
		 valjamawal=Number(piecesr[0]);
	}else{
		 valjamawal=Number(piecesr[0])+12;
	}

}

 const tgl2 = new Date(jam2);
		const tglkonversi2 = moment(tgl2).tz('Asia/Bangkok');	

const jm2=tglkonversi2.format('hh:mm:ss A');		
//let jm2=(jam2).toLocaleTimeString();
var poswaktu2=jm2.split(' ');
var piecesr2 = poswaktu2[0].split(":");


 let valjamakhir=0;
if(poswaktu2[1]==='AM'){
	 if(Number(piecesr2[0])===12){
		  valjamakhir=Number(piecesr2[0])+12;
	 }else{
		 valjamakhir=Number(piecesr2[0]);
	 }

}else{
	if(Number(piecesr2[0])===12){
		 valjamakhir=Number(piecesr2[0]);
	}else{
		  valjamakhir=Number(piecesr2[0])+12;
	}

}

setJampesan({
	jamawalpesan:jm1,
	jamakhirpesan:jm2
})


   const updatedDataorder = { ...dataorder,
                    jamawalorder:jm1,
					jamakhirorder:jm2
					//tglorder:null
			  };

   setDataorder(updatedDataorder);
//console.warn("jamwal",valjamawal)
//console.warn("jamwakhir",valjamakhir)

///////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////PERHITUNGAN TOTAL ORDER LAPANGAN//////////////////////////////////////////////////////////////
const jmPesan={
		 jamSTART:valjamawal,
		 jamEnd:valjamakhir,
		 totalJam:(valjamakhir-valjamawal),
		 biaya:125000*(valjamakhir-valjamawal)
	 }
let totpajak=((125000*(valjamakhir-valjamawal))*11)/100;

setCartjam(prevState => [...prevState, jmPesan]);
setPajak(totpajak);
setGrandtotal((125000*(valjamakhir-valjamawal))+totpajak)
setClock(true)



/*	idpelanggan:'',
			lapangan:'',
			jamawalorder:null,
			jamakhirorder:null,
			tglorder:null*/
  // const updatedDataorder = { ...dataorder,
  //                lapangan:
	//			  };

  //  setDataorder(updatedUser);
//setTglsewa(pieces[0])
//console.warn('value jam 1',piecesr[0]);
//console.warn('value jam 2',poswaktu2[1]);
/*     if(poswaktu1[1]==='PM'){
        jamawl1=valjamawal+12;
	 }else{
		jamawl1= valjamawal;
	 }

	 if(poswaktu2[1]==='PM'){
		 jamawl2=valjamakhir+12;
	 }else{
		 jamawl2=valjamakhir;
	 }*/
//console.warn('value jam 1',jamawl1);
//console.warn('value jam 2',jamawl2);
	/*  if(poswaktu1[1]==='PM' && jamawl1===12){
		     alert('jam sudah berganti hari')
	  }else{
		 if(jamawl2<jamawl1){
			// alert('pilihan jam tidak benar')
			// setwith_title(true)
			setJamnotice('Cek kembali Jam akhir sewa')
		  }else{
			 alert('pilihan jam sudah benar')
		  }
	  }*/

	}
 }

  function setFechaDesde(date,event){
	  
	  
	    setTglpilih(date)
	    const tgl = new Date();
		const tglkonversi = moment(tgl).tz('Asia/Bangkok');		
		const tahun = tgl.getFullYear();
		const monthIndex = tgl.getMonth();		
		const tglfix = tgl.getDate();
		const jamnya=tglkonversi.format('HH:mm');
	    const pecahjam=jamnya.split(':')
		const datajam=[]
		const datajamurgent=[]
	  jambooking.map((item,index)=>{
		  //console.warn('waktunyaaaaaaaaaaaaa',item)
		  if(item<=Number(pecahjam[0])){
			  if(item ===Number(pecahjam[0])){
				  if(tglfix===date){
					     datajamurgent.push(item)
					     if(Number(pecahjam[1]>30)){
						  datajamurgent.push(Number(item)+1)
					      }
				  }else{
					   datajam.push(item) 
				  }
				 
			  }else{
				  datajam.push(item)
				 
			  }
		  }
		
	  })
	  setJamurgent(datajamurgent)
	  setJamaktif(datajam)
      // console.warn(JSON.stringify(event));
      //  console.warn(JSON.stringify(date));
	  let tanggalnya=dateFormat(date, "yyyy-mm-dd");
	  // let tanggal=JSON.stringify(date);
	  // const datei = moment(date).format('DD MMM, YYYY');
	  const updatedDataorder = { ...dataorder,
	  tglorder:tanggalnya
	  };
	  setDataorder(updatedDataorder)
	  setSelectedDate(tanggalnya);
	 // console.warn(tanggalnya)
	  const response =  axios.get(apiService+'/getbookinglapangan',{ params: { tanggal : tanggalnya }}).then(res => {
		              //console.warn('Data lapangan dan bookingan ',res.data)
                        let arr = res.data;
						setLap(res.data)
						//setWktredy(res.data)
						//  arr.map(function(item) {
						     // setLap(prevState => [...prevState, item.lapangan]);

						//  })
                     //  console.warn('data order',res.data)
                          //setLap(res.data.idlapangan)
					       setShowTime(true)
						   // setDatawarna(prevState => [...prevState, selectedGroup.value]);
                    })
					
			/*	const response2 =	axios.get(apiService+'/getdataexpbooking',{params:{tanggal:tanggalnya}}).then(res3=>{
				 // 	console.warn('Data lapangan dan bookingan expireddd ',res3.data)
					   const hasilbookingexp=[]
					   res3.data.map(item=>{
						       const tglsekarang=new Date();
						       const tgljmwarn = new Date(item.wktuexp);
	                        	const tglkonversi2 = moment(tgljmwarn).tz('Asia/Bangkok');	
								const tglkonversi3 = moment(tglsekarang).tz('Asia/Bangkok');	
									const jamnya=tglkonversi2.format('HH:mm');
									const jamnow=tglkonversi3.format('HH:mm');
									const pecahjamnow=jamnow.split(':')
									const pecahjamexp=jamnya.split(':')
									console.warn('jam expired',jamnya)
										console.warn('jam sekarang',jamnow)
									const pecahjamawal=item.jamaw.split(':')
									const pecahjamakhir=item.jamakh.split(':')
									if(jamnya>jamnow){
										for(let i=Number(pecahjamawal[0]); i<Number(pecahjamakhir[0]); i++){
											hasilbookingexp.push(i)
										}
									}
                                   // console.warn('Data lapangan dan bookingan ',tglkonversi2) 								
					   })
					   setJamwarning(hasilbookingexp)
					})*/
    }

/*const customTheme = createMuiTheme({
    overrides: {
      MuiMobileTimePicker: {
        // Style the root container of the MobileTimePicker component
        root: {
          color: 'red', // Example font color
        },
      },
      // You can style other components as needed
    },
  });*/

const tutupFormorder=()=>{
   setCartjam([]);
   setClock(false);
   setPajak(0);
   setGrandtotal(0)
	 setmodal_center(false)

}

   const refreshToken = async () => {
      //  try {
            /*const response = await axios.get('http://localhost:5000/token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);*/
			const api = axios.create({
    baseURL: apiService,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
});
			   	const response6 = await api.get('/token').then(res => {
					  // console.warn('response', res.data)
					 let hasKey = res.data.hasOwnProperty('errors');
					 if(hasKey){

						setLoginData(null)
					 }else{
					
					     setToken(res.data.accessToken);
						 const decoded = jwt_decode(res.data.accessToken);
						 setName(decoded.username);
						 setExpire(decoded.exp);
						 setLoginData(res.data.accessToken)
						  const updatedDataorder = { ...dataorder,
						  idpelanggan: decoded.email
						  };
						  setDataorder(updatedDataorder)
						  getDataorder(decoded.email)
						  getDetailorder(decoded.email)
						  cekPembayaran(decoded.email)

					 }


				})
      //  } catch (error) {
      //      if (error.response) {
              //   history.push("/");
      //      }
      //  }
    }

	const cekPembayaran=(emailuser)=>{
		//console.warn('tidak ada order yang di bayar')
		    	const databayar={
					mail:emailuser
				}
				const konfig={
					headers:{
					   "Content-Type":"application/json"
					}
				}
				 axios.get(apiService+'/cekpayment',{ params: databayar},konfig).then(res => {

								let cekError=res.data.hasOwnProperty('errors');
								if(cekError){
									//setStatuspilih(true)
									//pesan Error saat tidak ada yang di pilih untuk di bayar
							//		console.warn('tidak ada order yang di bayar')
								}else{
									//setTokenpay(res.data.token)
									
                                     if(res.data.length===0){
									//	 console.warn('data balik dariiiiiiiiiiiiiiiiiiiiiiiiiiiiiii masuk kosong',res.data)
									    axios.get(apiService+'/cekpayment2',{ params: databayar},konfig).then(res2 => {
											let cekError2=res2.data.hasOwnProperty('errors');
											if(cekError2){
												//setStatuspilih(true)
												//pesan Error saat tidak ada yang di pilih untuk di bayar
											//	console.warn('tidak ada order yang di bayar')
											}else{
												
												 if(res2.data.length===0){
													    axios.get(apiService+'/cekpayment3',{ params: databayar},konfig).then(res3 => {
															 updateDatapembayaran(emailuser,res3.data)
														})
												 }else{
													  updateDatapembayaran(emailuser,res2.data)
												 }
												
											}
										})
									 }else{
									//	 console.warn('data balik dariiiiiiiiiiiiiiiiiiiiiiiiiiiiiii masuk ada data',res.data)
									    updateDatapembayaran(emailuser,res.data)
									 }
									
								}
								// tokenID = res.data
									//console.warn('Data interchange',res.data.datasisa)
								// setTokenpay(res.data.token)
								/*status_code: "407"								
								status_message: "Success, transaction is found"								
								transaction_id: "f5ce3e51-6357-45e1-ab5c-1f1e68539b9d"								
								transaction_status: "expire"	*/
					   })
}

const updateDatapembayaran=async(idpenyewa,idorder)=>{
	     let dataupdate={
			 mailuser:idpenyewa,
			 idordernya:idorder
		 }
		 const konfig={
					headers:{
					   "Content-Type":"application/json"
					}
				}
	     const responseOrder = await axios.post(apiService+'/updatepayment', dataupdate,konfig).then(resultorder=>{
			// console.warn('hasil simpan keranjang',resultorder.data)


		 })
}

	const getDetailorder=async(usermail)=>{
		const restDetailorder=await axios.get(apiService+'/datadetailorder',{params:{mailuser:usermail}}).then(res =>{
		//	console.warn('Data detail order1',res.data)
		    	res.data.map(function(item) {
					    setDatakeranjang(prevState => [...prevState, item]);

				})
			//setDatakeranjang(prevState => [...prevState, res.data]);
			//setdatakeranjang(res.data);
		})
	}

	  const userLogout =async()=>{
	  const response6 = await axios.get(apiService+'/logout',{withCredentials: true}).then(res => {
		  let hasKey = res.data.hasOwnProperty('success')
		  if(hasKey){
			  if(res.data.success[0].jenis==='auth'){
				  setLoginData(null)
				 
			  }
		  }
	  })
  }
	
	const getDataorder=async(usermail)=>{
    
		    const resDataorder = await axios.get(apiService+'/dataorder',{params:{email:usermail}}).then(res => {
				  //console.warn('rest data order')
				setSharedState(res.data[0].jumlah)
			})
	}
	    const axiosJWT = axios.create();
	     const currentDate1 = new Date();
  // console.warn('expire datenyaaa...',expire)
     // console.warn('expire perbandingan...',currentDate1.getTime())
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
          /*  const response = await axios.get('http://localhost:5000/token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);*/
		    const response7 = await axios.get(apiService+'/token').then(res => {
				  	 let hasKey = res.data.hasOwnProperty('errors');
					 if(hasKey){
					 // history.push("/");
                  //   userLogout()
					 setLoginData(null)
					 }else{
						config.headers.Authorization = `Bearer ${res.data.accessToken}`;
						setToken(res.data.accessToken);
						const decoded = jwt_decode(res.data.accessToken);
						setName(decoded.username);
					    setExpire(decoded.exp);
						setLoginData(res.data.accessToken)
					 }

			})
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

useEffect(() => {
	refreshToken();

}, []);

	const masukLogin=()=>{
		//alert('Login dulu')
		 history.push("/login");
	}

const Jamhabis=()=>{
	setJmepet(true)
}

	//console.warn('lebar....',windowSize.width )

//	console.warn('JAMAKTIFFFFFFFFFFFFFFFF.....',jamaktif)
	//console.warn('JAMURGENTTTNNNNNNNNNNNN.....',jamurgent)
 //console.warn('jam warninggggggg.....',jamwarning)
//console.warn('Data id pembayaran...',idpay)
    return (
        <>
            <section className={`appie-hero-area ${className || ''}`}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className={windowSize.width < 1000 ? "col-sm-12":"col-md-6"}>
                            <div className="appie-hero-content">
                                <span>Satria Futsal</span>
                                <h1 className="appie-title">
                                      Main Futsal, Bingung cari lapangan!!
                                </h1>
                                <p>
                                    Satria Futsal menyediakan beberapa lapangan,
                                    area bersih, nyaman dan terbuka.
                                </p>
					              <Row  className="d-flex align-items-center justify-content-center">
										<div style={{marginRight:'5.5px'}}>
											<LocalizationProvider  dateAdapter={AdapterDayjs}>
												  <DemoContainer  components={['DatePicker', 'DatePicker']}>
													<DatePicker
													  label="Cari lapangan sesuai waktumu"
													  value={value}
												        disablePast={true}
                                                      onChange={(date, event) => setFechaDesde(date,event)}
													/>
												  </DemoContainer>
											</LocalizationProvider>
										</div>
				                  </Row>
								  {/* ///////////////TAMPILAN LAPANGAN DAN JAM BOOKING ////////////////////////*/}
								             <div >
												 {/*//////////////////////////////////////SLIDER///////////////////////////////*/}

												   	 <Slider style={{marginTop:'20px'}}  {...settings}>
														 {  Lap.map(laps => {
														 return(

														         <div className="row">

																	     <Row  style={{marginBottom:'20px'}} xs={12}>
																		     <Col md={2}></Col>
																			 <Col style={{textAlign:'center',borderRadius: '5px'}} md={8}><p style={{fontFamily:'Roboto',fontWeight:500}}>{laps.lapangan}</p></Col>
																			 <Col md={2}></Col>
																		 </Row>

																	   <div className="row">
																	   {  jambooking.map(jam => {
																		   {/*  return(
																					  <div  style={{paddingBottom:'5px'}} className={windowSize.width < 650 ? "col-sm-4"  : "col-sm-2"}>
																						<div  style={{borderRadius: '5px',textAlign:'center',cursor:'no-drop'}} className="bg-danger"><p style={{fontStyle:'normal',fontSize:'14px',color:'#fff'}}>{jam}</p></div>
																					  </div>
																		   )*/}
																		    if(jamaktif.includes(jam)){
																				   if(tglpilih > new Date()){
																					      if((laps.jamterbooking).includes(jam)){
																					             
																									   return(
																										  <div  style={{paddingBottom:'5px'}} className={windowSize.width < 720 ? "col-lg-3 col-6"  : "col-sm-2"}>
																											<div  style={{borderRadius: '5px',textAlign:'center',cursor:'no-drop'}} className="bg-warning"><p style={{fontStyle:'normal',fontSize:'14px',color:'#fff',borderRadius: '5px'}}>{jam<10 ? ('0'+jam+':00'):(jam+':00')}</p></div>
																										  </div>
																										 )
																								  																						
																					   
																						 }else{
																							  if((laps.jamexpwarning).includes(jam)){
																									     return(
																										  <div  style={{paddingBottom:'5px'}} className={windowSize.width < 720 ? "col-lg-3 col-6"  : "col-sm-2"}>
																											<div  style={{borderRadius: '5px',textAlign:'center',cursor:'no-drop'}} className="bg-info"><p style={{fontStyle:'normal',fontSize:'14px',color:'#fff',borderRadius: '5px'}}>{jam<10 ? ('0'+jam+':00'):(jam+':00')}</p></div>
																										  </div>
																										 )
																								  }else{
																										  return(
																										  <div  style={{paddingBottom:'5px'}} className={windowSize.width < 720 ? "col-lg-3 col-6"  : "col-sm-2"}>
																											<div onClick={()=>expire > 0 ? (pilihjam(jam,(laps.jamterbooking),laps.idlap)):(masukLogin())} style={{fontStyle:'normal',borderRadius: '5px',textAlign:'center',cursor:'pointer',backgroundColor:'#3ECF4C'}}><p style={{fontStyle:'normal',fontSize:'14px',color:'#fff'}}>{jam<10 ? ('0'+jam+':00'):(jam+':00')}</p></div>
																										  </div>
																										)
																								  }
																				         }
																				   }else{
																					   return(
																						  <div  style={{paddingBottom:'5px'}} className={windowSize.width < 720 ? "col-lg-3 col-6"  : "col-sm-2"}>
																							<div  style={{borderRadius: '5px',textAlign:'center',cursor:'no-drop'}} className="bg-danger"><p style={{fontStyle:'normal',fontSize:'14px',color:'#fff',backgroundColor:'#F64920',borderRadius: '5px'}}>{jam<10 ? ('0'+jam+':00'):(jam+':00')}</p></div>
																						  </div>
																						 )
																				   }
																			}else{
																				  if(jamurgent.includes(jam)){
																					         return(
																							  <div  style={{paddingBottom:'5px'}} className={windowSize.width < 720 ? "col-lg-3 col-6"  : "col-sm-2"}>
																								<div  onClick={()=>Jamhabis()} style={{borderRadius: '5px',textAlign:'center',backgroundColor:'#fd7e14',cursor:'pointer'}} ><p style={{fontStyle:'normal',fontSize:'14px',color:'#fff',borderRadius: '5px'}}>{jam<10 ? ('0'+jam+':00'):(jam+':00')}</p></div>
																							  </div>
																							 )
																				  }else{
																					   if((laps.jamterbooking).includes(jam)){
																						  
																							return(
																							  <div  style={{paddingBottom:'5px'}} className={windowSize.width < 720 ? "col-lg-3 col-6"  : "col-sm-2"}>
																								<div  style={{borderRadius: '5px',textAlign:'center',cursor:'no-drop'}} className="bg-warning"><p style={{fontStyle:'normal',fontSize:'14px',color:'#fff',borderRadius: '5px'}}>{jam<10 ? ('0'+jam+':00'):(jam+':00')}</p></div>
																							  </div>
																							 )																							 
																							 
																						   
																						 }else{
																							 
																							 if((laps.jamexpwarning).includes(jam)){
																								    return(
																										  <div  style={{paddingBottom:'5px'}} className={windowSize.width < 720 ? "col-lg-3 col-6"  : "col-sm-2"}>
																											<div  style={{borderRadius: '5px',textAlign:'center',cursor:'no-drop'}} className="bg-info" ><p style={{fontStyle:'normal',fontSize:'14px',color:'#fff',borderRadius: '5px'}}>{jam<10 ? ('0'+jam+':00'):(jam+':00')}</p></div>
																										  </div>
																										 )
																							 }else{
																								  return(
																								  <div  style={{paddingBottom:'5px'}} className={windowSize.width < 720 ? "col-lg-3 col-6"  : "col-sm-2"}>
																									<div onClick={()=>expire > 0 ? (pilihjam(jam,(laps.jamterbooking),laps.idlap)):(masukLogin())} style={{fontStyle:'normal',borderRadius: '5px',textAlign:'center',cursor:'pointer',backgroundColor:'#3ECF4C'}}><p style={{fontStyle:'normal',fontSize:'14px',color:'#fff'}}>{jam<10 ? ('0'+jam+':00'):(jam+':00')}</p></div>
																								  </div>
																							   	)
																							 }
																						 }
																				  }
																			}
																	      })
																	   }
																	   </div>
															     </div>
															   )
														            })
														 }
												  	</Slider>
											 </div>

								  <Row className="d-flex align-items-center justify-content-center">
								      <div>

											 {/* <div   style={{minHeight:'250px', marginTop:'20px'}}>
												 {  Lap.map(laps => {

													 return(

															<Row style={{marginTop:'10px',paddingLeft: 10, paddingRight: 10}}  md={12} >
															  <Row className="d-flex align-items-center justify-content-center" md={8}><Container>Lapangan</Container></Row>
														      <Row md={8}>
															    {
																	 jambooking.map(jam => {

																		 if((laps.jamterbooking).includes(jam)){
																				 return (
																					  <Col style={{paddingLeft: 0, paddingRight: 0}} xs={windowSize.width < 650 ? 6  : 3} >

																						  <Button style={{width:'100%',marginTop:'10px'}} color="danger">{jam}</Button>
																					  </Col>
																			     	)
																		 }else{
																			  return (
																					  <Col style={{paddingLeft: 0, paddingRight: 0}}  xs={windowSize.width < 650 ? 6  : 3} >

																						  <Button style={{width:'100%',marginTop:'10px'}} color="success">{jam}</Button>
																					  </Col>
																			     	)
																		 }
																	})
																}
																</Row>


															</Row>
															)
												      })
												 }
											 </div>*/}
										</div>
								  </Row>

						{/*   <ul>
                                    <li>
                                        <a href="#">
                                            <i className="fab fa-apple" /> Download for iOS
                                        </a>
                                    </li>
                                    <li>
                                        <a className="item-2" href="#">
                                            <i className="fab fa-google-play" /> Download for
                                            Android
                                        </a>
                                    </li>S
						</ul>*/}
                            </div>
                        </div>
                        <div style={{marginTop:windowSize.width < 1000 ? "-10px":"0px"}} className={windowSize.width < 1000 ? "col-sm-12":"col-lg-6"}>
                           	 <div className="appie-hero-thumb">
						       {/* ===================8/5/2023 image HERO UTAMA==================*/}
							  <div
                                    className="thumb wow animated fadeInUp"
                                    data-wow-duration="2000ms"
                                    data-wow-delay="200ms"
                                >
                                    <img src={heroThumbOne} alt="" />
                                </div>
                              {/*}   <div
                                    className="thumb-2 wow animated fadeInRight"
                                    data-wow-duration="2000ms"
                                    data-wow-delay="600ms"
                                >
                                    <img src={heroThumbTwo} alt="" />
                              </div>*/}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hero-shape-1">
                    <img src={shapeTwo} alt="" />
                </div>
                <div className="hero-shape-2">
                    <img src={shapeThree} alt="" />
                </div>
                <div className="hero-shape-3">
                    <img src={shapeFour} alt="" />
                </div>
            </section>

			{/*////////////////////////////////MODAL UNTUK FORM///////////////////////////////////////*/}
			          <Modal
				      backdrop="static" keyboard={false}
					  size="lg"
                      isOpen={modal_center}
                      toggle={() => {
                      tog_center()
                      }}
                      centered={true}
                      >
                    <div className="modal-header">
                      <h5 className="modal-title mt-0">Center Modal</h5>
                      <button
                        type="button"
                        onClick={() => tutupFormorder()}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                   			              	 <Row >
											 {/*//////////////////////////////FORM BOOKING LAPANGAN//////////////////////////////////////////////*/}
											     <Col  xl={4}>
											          <Row className="d-flex align-items-center justify-content-center">
																<div>
																  <div className="modal-body">
																				{/* 	<LocalizationProvider dateAdapter={AdapterDayjs}>
																				<DemoContainer
																				components={['MobileTimePicker','DatePicker', 'MobileTimePicker', 'MobileTimePicker','TimePicker']}
																				sx={{ minWidth: 100 }}
																				>
																					<MobileDatePicker label={'"Pilih Tanggal"'} defaultValue={dayjs('2022-04-17')} />
																					<MobileTimePicker  slotProps={{ textField: { size: 'small', helperText: 'Jam mulai sewa' } }} label={'"Jam mulai"'} views={['hours']} />
																					<MobileTimePicker slotProps={{ textField: { size: 'small', helperText: 'Jam Akhir sewa' } }} label={'"Jam Akhir"'} views={['hours']} />
																				</DemoContainer>
																				</LocalizationProvider>
																				<TimePicker
																				label="Controlled picker"
																				value={value1}
																				renderInput={(params)=><TextField {...params} />}
																				onChange={(newValue) => setValue1(newValue)}
																				/>		*/}
																					  <div style={{marginBottom:"20px"}}>
																					  </div>
																						 <LocalizationProvider dateAdapter={AdapterDateFns}>
																							<Stack space={3}>
																							  <div style={{marginBottom:"20px"}}>
																								  <MobileTimePicker
																								  ampm={false}
																									slotProps={{ textField: {  helperText: 'Jam mulai sewa' } }}
																									label="Jam awal"
																									views={['hours']}
																									value={value3}
																									  disabled={true}
																									onChange={(newValue) => {
																									  setValue3(newValue);
																									  }
																									}
																								  renderInput={(params) => <TextField {...params} />}
																								/>
																							  </div>
																							  <div>
																								<MobileTimePicker
																									label="Jam akhir"
																									slotProps={{ textField: {  helperText: jamnotice } }}
																									ampm={false}
																									views={['hours']}
																									value={value2}
																									onChange={(newValue2) => {
																									 pilihjam2(newValue2)
																									  }
																									}
                                                                                                    disabled={clock}
																									shouldDisableClock={(timeValue, view) => view === 'hours' && !jamnonaktif.includes(timeValue)}
																									closeOnSelect={true}
																								  renderInput={(params) => <TextField {...params}   />}

																								/>
																							  </div>
																						  </Stack>
																						</LocalizationProvider>

																 </div>
														    </div>
										     	</Row>
												<Row >
												       <Col>
													            <Card >
																       	 {/*  <Button onClick={()=>pesanJam()} color="primary">
																	      <i className="fa fa-shopping-cart fa-lg"  ></i>
																		  <i className="fa fa-plus fa-lg"  ></i>
																		 </Button>*/}
                                                                </Card>
													   </Col>

												</Row>

													{/*<Col  md="2">
													 {with_title ? (
														<SweetAlert
														style={{ position: 'relative', zIndex:  '1300 !important'  }}
														  title="The Internet?"
														  warning
														  onConfirm={() => {
															setwith_title(false)
														  }}
														>
														  That thing is still around?
														</SweetAlert>
													  ) : null}
													</Col>*/}
											</Col>
									        <Col xl={8}>

														<CardBody>
														{/*<CardTitle>Rincian Transaksi</CardTitle>*/}
														   <Row>
																<Col xs="12">
																	<Card>
																		<CardBody>

																			<Row>
																				<Col xs="12">
																					<div className="invoice-title">
																						<h6 className="float-end font-size-5 mt-2"><strong>Order #{noorder}</strong></h6>
																						<h4 className="mt-1">
																							<img alt="logo" src={logo}  height="35" />
																						</h4>
																					</div>
																					<hr />
																					{/*<Row>
																						<Col xs="6">
																							<address >
																								<strong style={{fontSize:14}} >Pembayaran:</strong><br />
																									John Smith<br />
																											1234 Main<br />
																											Apt. 4B<br />
																								Springfield, ST 54321*
																										</address>
																						</Col>
																						<Col xs="6" className="text-end">
																							<address>
																								<strong  style={{fontSize:14}}>Alamat pelanggan:</strong><br />
																									Kenny Rigdon<br />
																											1234 Main<br />
																											Apt. 4B<br />
																								Springfield, ST 54321
																										</address>
																						</Col>
																					</Row>
																					<Row>
																						<Col xs="6" className="mt-2">
																							<address>
																								<strong  style={{fontSize:14}}>Metoda Bayar:</strong><br />
																								Visa ending **** 4242<br />
																								jsmith@email.com
																										</address>
																						</Col>
																						<Col xs="6" className="mt-2 text-end">
																							<address>
																								<strong  style={{fontSize:14}}>Tanggal Transaksi:</strong><br />
																											October 7, 2016<br /><br />
																							</address>
																						</Col>
																					</Row>*/}
																				</Col>
																			</Row>

																			<Row>
																				<Col xs="12">
																					<div>
																						<div >
																							<h8 className="font-size-2"><strong>Rincian Pesanan</strong></h8>
																						</div>
																						<div >
																							<div className="table-responsive">
																								<table className="table">
																									<thead>
																										<tr>

																											<td colSpan='2' style={{width:170}} className="text-center"><strong style={{fontSize:12}}>Awal - Akhir</strong></td>
																											<td className="text-center"><strong style={{fontSize:12}}>Jumlah</strong>
																											</td>
																											<td className="text-end"><strong style={{fontSize:12}}>Total</strong></td>
																										</tr>
																									</thead>
																									<tbody style={{textAlign:'center'}}>

																									{
																										cartjam.map(carts => {
																										    return(
																											   	<tr>
																													<td colSpan='2'>{(carts.jamSTART < 10 ? ('0'+carts.jamSTART+':00'):(carts.jamSTART+':00'))+' - '+( carts.jamEnd < 10 ? ('0'+carts.jamEnd+':00'):(carts.jamEnd+':00'))}</td>
																													<td className="text-center">{carts.totalJam+' Jam'}</td>
																													<td className="text-end"><CurrencyFormat amount={carts.biaya} /></td>
																												</tr>
																											)

																										})

																									}


																										{/*<tr>
																											<td>BS-400</td>
																											<td className="text-center">$20.00</td>
																											<td className="text-center">3</td>
																											<td className="text-end">$60.00</td>
																										</tr>
																										<tr>
																											<td>BS-1000</td>
																											<td className="text-center">$600.00</td>
																											<td className="text-center">1</td>
																											<td className="text-end">$600.00</td>
																										</tr>
																										<tr>
																											<td className="thick-line"></td>
																											<td className="thick-line"></td>
																											<td className="thick-line text-center">
																												<strong>Subtotal</strong></td>
																											<td className="thick-line text-end">$670.99</td>
																										</tr>*/}

																										<tr>

																											<td colSpan='2' style={{width:170}} className="text-center"></td>
																											<td className="no-line text-center">
																												<strong  style={{fontSize:12}}>Pajak 11%</strong></td>
																											<td className="no-line text-end"><p strong style={{fontSize:14}}><CurrencyFormat amount={pajak} /></p></td>
																										</tr>
																										<tr>
																											<td className="no-line"></td>
																											<td className="no-line"></td>
																											<td className="no-line text-center">
																												<strong  style={{fontSize:14}}>Total</strong></td>
																											<td className="no-line text-end">
																												<strong style={{fontSize:14}}><CurrencyFormat amount={grandtotal} /></strong></td>
																										 </tr>
																									</tbody>
																								</table>
																							</div>

																							<div className="d-print-none">
																								<div className="float-end">

																								{/*		<i className="fa fa-print fa-2x"  ></i>

																								<Link to="#" onClick={printInvoice} className="btn btn-success waves-effect waves-light"><i className="fa fa-print"></i></Link>{" "}
																									<Link to="#" className="btn btn-primary waves-effect waves-light">Send</Link>*/}
																								</div>
																							</div>
																						</div>
																					</div>

																				</Col>
																			</Row>

																		</CardBody>

																	</Card>
																</Col>
															</Row>

														</CardBody>


											</Col>
									{/*/////////////////////////////AKHIR FORM BOOKING LAPANGAN//////*/}

																  </Row>
								   <div className="modal-footer">
								                                <Button  onClick={()=>tambahKeranjang()} style={{backgroundColor:'#01A368',border:"0px"}} className="ms-1">
															          <FontAwesomeIcon icon={faCartShopping} />
																</Button>
									                            <Button onClick={()=>Bayarlangsung()} style={{backgroundColor:'#F64920',border:"0px",marginRight:40}} className="ms-1">
															        Bayar
																</Button>
								   </div>
                  </Modal>
				  
				 

                  {/*///////////////////////////////SWEET ALERT////////////////////////////////////*/}
                    {stsjmbook ? (
       								<SweetAlert
       								  title='Pilih jam booking dahulu'
       								   btnSize="sm"
       								   size="sm"
       								   confirmBtnText="Ok"
       								  onConfirm={() => {
       									setStsjmbook(false)
       								  }}
       								>
       								Periksa kembali jam order
       								</SweetAlert>
       							  ) : null}
								  
				  {/*/////////////////////////////////////////////////////////PEMABATALAN BAYAR//////////*/}
				  	   {statuscancel ? (
								<SweetAlert
								  title="Konfirmasi Pembayaran order"
								   btnSize="sm"
								   size="sm"
								   confirmBtnText="Tutup"
								  onConfirm={() => {								
									batalbayar()
								  }}
								>
								Pembayaran di batalkan
								</SweetAlert>
							  ) : null}
							  
			     {/*/////////////////////////////////JAM MEPET///////////////////////////////////////////*/}
					 {
						 jmepet?(
						   <SweetAlert
								  title="Informasi Booking lapangan"
								   btnSize="sm"
								   size="sm"
								   confirmBtnText="Tutup"
								  onConfirm={() => {								
									setJmepet(false)
								  }}
								>
								Segera ke Lapangan Satria Futsal<br/>
								Booking lapangan hanya bisa di lakukan di tempat<br/>
								30 menit sebelum jam booking
								</SweetAlert>
						 ):null
					 }
					 
					    {/*/////////////////////////////////DATA KERANJANG SUDAH ADA///////////////////////////////////////////*/}
					 {
						 keranjangexis?(
						   <SweetAlert
								  title="Informasi Data keranjang"
								   btnSize="sm"
								   size="sm"
								   confirmBtnText="Tutup"
								  onConfirm={() => {								
									setKeranjangexis(false)
								  }}
								>
								Jam booking sudah ada di keranjang<br/>
								mohon cek keranjang dan selesaikan pembayaran
								</SweetAlert>
						 ):null
					 }
        </>
    );
}

//export default HeroHomeOne;
const mapStateToProps = (state) => {
  //  console.log(state);
    return ({
        datasharing:1,

    })
};

const mapDispatchToProps = (dispatch) => ({
    actions: {
        userMasuk: (payload) => {
			//console.warn('data payload',payload)
          //  dispatch(actionType.loginUser(payload));
        },


    },
});
export default connect(mapStateToProps, mapDispatchToProps)(HeroHomeOne);
