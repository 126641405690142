//import PropTypes from "prop-types"
import React, { useEffect,useState,useRef } from "react"
//import MetaTags from 'react-meta-tags';
import { Row, Col,Button, CardBody, Card, Alert, Container, Input, Label } from "reactstrap"
import axios from 'axios'
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"
//import Parse from 'parse/dist/parse.min.js';
// action
//import { registerUser, apiError, registerUserFailed } from "../../store/actions"
//import OTPGenerator from 'otp-generator';
// Redux
//import { connect } from "react-redux"
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { useLocation,Link,useHistory } from "react-router-dom";
import apiService from '../../api/apiService'
//SweetAlert
//import Timer from './Timer'
//import CountdownTimer from './CountdownTimer';
import OtpField from './OtpField';
import SweetAlert from "react-bootstrap-sweetalert"
// import images
//import logoLightPng from "../../assets/images/logo-light.png"
import logoDark from "../../assets/images/logo-dark.png"
import Sms from "../../assets/images/iconsms.png"
import { googleLogout, useGoogleLogin,GoogleLogin  } from '@react-oauth/google';
import Loader from '../../components/Helper/Loader';
import styled from 'styled-components';
const OtpContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const OtpInput = styled.input`
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 18px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 0 5px;

  &:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  }
`;

const Verifikasi = props => {
  // handleValidSubmit
 // const handleValidSubmit = (event, values) => {
  //  props.registerUser(values)
 // }
 const [tokenku,setTokenku]=useState('');
 	  const [loginData, setLoginData] = useState(
       sessionStorage.getItem('loginData')
      ? JSON.parse(sessionStorage.getItem('loginData'))
      : null
  );
 const location = useLocation();
const [username, setUsername] = useState('');
const [passwrd, setPasswwrd]=useState('');
const [password1, setPassword1] = useState('');
const [currentUser, setCurrentUser] = useState(null);
const [judul,setjudul]=useState('')
const [existmsg,setExistmsg]=useState('')
const [stsnohp,setStsnohp]=useState(false)
const [dataoke,setDataoke]=useState(false)
const [stsvalid,setStsvalid]=useState(false)
const [msgdatavalid,setMsgdatavalid]=useState('')
const [simpan,setSimpan]=useState(false)
const [waktu,setWaktu]=useState(Date.now())
const [prosesotp,setProsesotp]=useState(false)
const [prosesotpHP,setProsesotpHP]=useState(false)
const [otpulang,setOtpulang]=useState(true)
const [kodecompare,setKodecompare]=useState(0)
const [kodecompare2,setKodecompare2]=useState(null)
const [kodeotpuniq,setKodeotpuniq]=useState(0)
const [uname,setUname]=useState('')
const [kontenpwd,setKontenpwd]=useState(false)
const [cektime,setCektime]=useState(false)
//const [timeotpmail,setTimeotpmail]=useState(location.state.jnsver==='mobile'?false:true)
const [formpass,setFormpass]=useState(false)
const [formpassbymail,setFormpassbymail]=useState(false)
const [alertotp,setAlertotp]=useState(false)

//const [opsver,setOpsver]=useState(location.state.jnsver==='mobile')

const [verifiUser,setVerifiuser]=useState(false)
// const [otp, setOTP] = useState('');
const [expiryTime, setExpiryTime] = useState(null);
const [otp2, setOtp] = useState(new Array(6).fill(''));
const otpFields = useRef([]);
  const history = useHistory ();
   const prosesDaftar=async()=>{
	
   }

const setStatussave =()=>{

}

/*function sendMessageOnWhatsApp() {
  var phoneNumber = '081217401644'; // Replace with the phone number you want to send the message to
  var message = 'Hello, I have a question.'; // Replace with the message content

  var url = 'https://api.whatsapp.com/send?phone=' + encodeURIComponent(phoneNumber) + '&text=' + encodeURIComponent(message);
  
  window.open(url);
}*/

/*  useEffect(() => {
    // Mengatur waktu kadaluwarsa sejalan dengan pembuatan OTP
    const now = new Date();
    const expiry = new Date(now.getTime() + 1 * 60000); // Misalnya, waktu kadaluwarsa ditetapkan menjadi 5 menit dari waktu saat ini
    setExpiryTime(expiry);
  }, []);*/

 const generateOTP = () => {
	 
  //  const generatedOTP = OTPGenerator.generate(6, { digits: true, alphabets: false, upperCase: false, specialChars: false });
  //  setOTP(generatedOTP);
  };
  
  /*const generateOTP2=()=>{
	  let range='1234567890'
	  let otpval=''
	  for(let i=0;i<6;i++){
		  otpval +=[Math.floor(Math.random()*10)]
	  }
	  setOTP(otpval);
  }*/
  
  
    const handleChange = (e, index) => {
    const value = e.target.value;

    if (isNaN(value)) {
      return;
    }

    const newOtp = [...otp2];
    newOtp[index] = value.slice(-1);
    setOtp(newOtp);

    if (value !== '') {
      if (index === 6 - 1) {
        otpFields.current[index].blur();
      } else {
        otpFields.current[index + 1].focus();
      }
    }
	
	
  };
  
  
  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && otp2[index] === '') {
      if (index === 0) {
        return;
      }

      otpFields.current[index - 1].focus();
    }
  };
  

  useEffect(()=>{
	 // if(timerComponents.length){
	 //	  const cekTime=timerComponents.split(' ')
	 //			console.warn('TMERRR....',cekTime[0])
	 // }
	  //    console.warn('timeLeftnyaaaaaaaaaaaaaa...',timeLeft.detik)
	   if(timeLeft.detik < 60){
		   if(timeLeft.detik===59){
		
			 setCektime(true)
		   }
	   }
	
  })
  
    const calculateTimeLeft = () => {
		//  const expiryTimestamp = Date.now() + 60000;
      const difference = waktu - Date.now();
	//console.warn('difference',difference)
	//const difference =60000;
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        detik: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  };
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    useEffect(() => {
     const timer = setTimeout(() => {
     setTimeLeft(calculateTimeLeft());
	 }, 1000);

   return () => clearTimeout(timer);
   });
   //   console.warn('timeLeft',timeLeft)
     const timerComponents = [];
   Object.keys(timeLeft).forEach(interval => {
	 // console.warn('ISI timeLeft',timeLeft[interval])
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span key={interval}>
        {timeLeft[interval]} {interval}{' '}
      </span>
    );
  });
     
  // 10 menit dari waktu sekarang
 // const isExpired = expiryTime && new Date() > expiryTime;
 // console.warn('isExpired time',isExpired)
  //  console.warn('OTP time',otp)
 	const handlelogin=useGoogleLogin({
       onSuccess:   (codeResponse) => {
		           const request1={token: codeResponse.access_token}
			       // const response2 =  axios.post('http://localhost:4001/google-login', request1)
				 /* const res = axios.post('http://localhost:4001/google-login', {
				  method: 'POST',
				  body: JSON.stringify({
					token: codeResponse.access_token,
				  }),
				  headers: {
					'Content-Type': 'application/json',
				  },
				  });*/
				 //  const data = response2.json();
				
					
					
		        	const response6 = axios.get(apiService+'/google-login',{ params: request1}).then(res => {  
		             	let arr3 = res.data; 	
						// setLoginData(arr3);
                       //  sessionStorage.setItem('loginData', JSON.stringify(arr3));
         				//console.warn('data balik',arr3)			
				   })
		},
	
        onError: (error) => console.log('Login Failed:', error)
		
    });	
	
	const kirimOTP=async(nomorhp)=>{
	   
		    const request1={nomorhpx:nomorhp}
		 	const response6 = axios.get(apiService+'/daftarotp',{params:request1}).then(res => { 
			   
				 let hasKey = res.data.hasOwnProperty('success');	
				 if(hasKey){
					 if(res.data.success[0].sts){
						 	setProsesotp(false)
		                 setWaktu(Date.now()+61000)
						 
						//  console.warn('data OTP GENERATE', res.data.success[0].kode)
						setKodecompare(res.data.success[0].kode)
						 
					 }
				 }
			})
		// const generatedOTP = OTPGenerator.generate(6, { digits: true, alphabets: false, upperCase: false, specialChars: false });
	}
	
   const handleOtpSubmit = (otp) => {
    // Lakukan sesuatu dengan OTP yang diinputkan
    //console.warn('isi OTP',otp)
	setAlertotp(false)
    setKodecompare2(otp)
  };
	
	const kirimOTP2=async()=>{
		
	/*	if(otpulang && timerComponents.length){
		//	console.warn('isi kodecompare',kodecompare)
					if(kodecompare2===kodecompare){
						//alert('cocok')
						if(location.state.jnsver==='mobile'){
							setVerifiuser(true)
						}else{		
                            setKontenpwd(true)						
						        
							 
						}
					}else{
						setAlertotp(true)
					}
				
					//console.warn('isi kodecompare',kodecompare)
		
		}else{*/
		
			if(location.state.jnsver==='email'){
					/////////////////kirim ulang otp ke EMAIL//////////////////////////////////
			///////////////////////////////////////////////////////////////////////
					 const requestemail={
						 dataemail:location.state.emailnya
					 }				
					 const responsEKIRIMULANG = axios.post(apiService+'/daftarbyemail',requestemail).then(res => { 
													//console.warn('data OTP sudah di email',res.data)
													setProsesotp(true)
													setKodecompare(res.data.otpnya)
													setWaktu(Date.now()+61000)											
												})
					
			}else if(location.state.jnsver==='mobile'){
					/////////////////kirim ulang otp ke nomor handphone//////////////////////////////////
			///////////////////////////////////////////////////////////////////////
				    const request1={nomorhpx:location.state.nomorhpx}
				 	const response6 = axios.get(apiService+'/daftarotp',{params:request1}).then(res => { 
					   
						 let hasKey = res.data.hasOwnProperty('success');	
						 if(hasKey){
							 if(res.data.success[0].sts){
								//  console.warn('data OTP GENERATE', res.data.success[0].kode)
								setProsesotpHP(true)
								setKodecompare(res.data.success[0].kode)
								setWaktu(Date.now()+61000)
							 }
						 }
					})
			
			}
			  
	//	}
		
	}
	
	const kirimulangOTPreg=()=>{
		     setCektime(false)
		    const request1={nomorhpx:location.state.nomorhpx}
				 	const response6 = axios.get(apiService+'/daftarotp',{params:request1}).then(res => { 
					   
						 let hasKey = res.data.hasOwnProperty('success');	
						 if(hasKey){
							 if(res.data.success[0].sts){
								//  console.warn('data OTP GENERATE', res.data.success[0].kode)
								//setProsesotpHP(true)
								setKodecompare(res.data.success[0].kode)
								setWaktu(Date.now()+61000)
							 }
						 }
					})
	}
	
	const kirimDaftar=async(nomor)=>{
		const request={
			nomorhp:nomor,
			pwd:password1
		}
		const response6 = axios.post(apiService+'/daftardata',request).then(res => { 
             let hasKey = res.data.hasOwnProperty('errors');
             if(hasKey){
				 let haskindobj1 = res.data.errors[0].hasOwnProperty('nomorx'); 
				 if(haskindobj1){
					 setUname(res.data.errors[0].msg)
				 }
			 }else{
			//	 sessionStorage.setItem('loginData', JSON.stringify(res.data.success[0]));
				  history.push({
							 pathname:"/login"												
							 });
			 }			 
		})
		//  const response2 = await axios.post('http://localhost:4001/daftardata', request)
		
	}
	
	const kirimDaftarwithemail=(emailnya)=>{
		   //  const usern=(emailnya).split('@');
									  //  const originalString = 'H!e@l#l$o %W^o&r*l(d)';
                                //    const cleaneduname = usern[0].replace(/[!@#$%^&*().]/g, '');
                                     const request3={
										 email:emailnya,										
										 pwd:passwrd
										 
									 }						
								     const responsedaftarbyemail = axios.post(apiService+'/daftardatabyemail',request3).then(res => { 
									 let hasKey = res.data.hasOwnProperty('errors');
									 if(hasKey){
										// let haskindobj1 = res.data.errors[0].hasOwnProperty('uname'); 
										// if(haskindobj1){
										//	 setUname(res.data.errors[0].msg)
										// }
										console.warn('errors')
									 }else{
									     // sessionStorage.setItem('loginData', JSON.stringify(res.data.success[0]));
										  history.push({
													 pathname:"/login"												
													 });
										//console.warn('sukses',res.data)
									 }		
								})
	}
	
	 	const handleOtpSubmitemail =(otp)=>{
		console.warn('otp email...',otp);
		console.warn('otp compare email...',kodecompare);
		console.warn('jnsver',location.state.jnsver);
		     if(otp.length===6){
					if(timerComponents.length){
						if(kodecompare===otp){
							if(location.state.jnsver==='mobile'){
								setFormpass(true) 
							}else if(location.state.jnsver==='email'){
								setFormpassbymail(true) 
							/*	const request22={
									emailuser:location.state.emailnya
								}
								
									 const responsedaftarbyemailverified = axios.post('http://localhost:4001/daftardatabyemailverified',request22).then(resdaftar => { 
											let cekSuksesreg=resdaftar.data.hasOwnProperty('success');
												if(cekSuksesreg){
													
														const requestbyemail={emailuser:resdaftar.data.success[0].emailnya}
														 const responselogin= axios.post('http://localhost:4001/loginbyemailotp',requestbyemail,{withCredentials: true}).then(res => {
																let cekSukses=res.data.hasOwnProperty('success');
																if(cekSukses){
																	  if(!res.data.success[0].accessToken){
																			 alert('terjadi kesalahan')
																		}else{
																			 history.push("/");
																		}
																}else{
																	alert('terjadi kesalahan')
																}
														 })
													
												}
									 })*/
							}
						}
					}
			 }
	}
	
	const inputHandler=(e)=>{
		setUsername(e.target.value)
	}
	
	const inputHandlerpass=(e)=>{
		setPasswwrd(e.target.value)
	}
	
	const inputHandlerpass1=(e)=>{
		setPassword1(e.target.value)
	}
	
	function isPageRefreshed() {
    return window.performance && performance.navigation.type === 1;
  }
	

  useEffect(() => {
   // props.apiError("")
  // console.warn('waktu..',timeLeft.detik)
	if(timerComponents.length && performance.navigation.type === 1){
		
	}	
  });
  
 //console.warn('OTP SETELAH REFRESH..',timeLeft)
  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
		{location.state.jnsver==='email'? (
		        <>
				<Row className="justify-content-center">
						<div className="p-3">
									<h4 className="text-muted font-size-14 mb-1 text-center">Verifikasi</h4>
									{
										prosesotp ? (
												<>
													{formpassbymail?(	
														<>									
														  <p className="text-muted text-center">Mohon masukan password untuk keamanan akun anda</p>	
														</>													  
													):(	
													   <>
														 <p className="text-muted text-center">Masukkan kode verifikasi yang sudah dikirim ke email anda</p> 
													   </>
													)}
												</>	
										):(				  	
												<>											
												    <p className="text-muted text-center">Pilih salah satu metode di bawah ini</p> 
												    <p className="text-muted text-center">untuk mendapat kode verifikasi</p> 
												</>														
										)
									}
												
						</div>
				</Row>
				<Row className="justify-content-center">
					<Col md={8} lg={6} xl={8}>
						  <Card className="overflow-hidden">
								<CardBody className="pt-4">
								{!prosesotp ? (
										<Row  className="justify-content-center">						
												 <div  className="media mb-0">
													<div  style={{  borderRadius: '15px',cursor:'pointer',padding:'20px',boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px'}} className="media-body">
														<Label onClick={()=>kirimOTP2()} style={{cursor:'pointer'}} >Kirim Kode OTP melalui email <span style={{fontWeight:800,fontsize:11}}>{location.state.emailnya}</span></Label>												
													</div>
												 </div>
										</Row> ):(
										    
										    formpassbymail ? (
											        <Row  className="justify-content-center">
														<div  className="media mb-0">
															   <AvForm style={{textAlign: 'center'}} >
																  <AvField name="password" label="Password" type="password" 
																	   validate={{
																	required: {value: true, errorMessage: 'Tolong Password di isi'}
																	   }}
																	   onChange={inputHandlerpass}
																  />
																 		   <Button onClick={()=>kirimDaftarwithemail(location.state.emailnya)} size="sm" className="btn btn-primary " type="submit">Submit</Button>
																</AvForm>
													
														</div>
												   </Row>
												):(
													<Row  className="justify-content-center">
														<div  className="media mb-0">
															
															<div  style={{  borderRadius: '15px',textAlign:'center', padding:'20px',boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px'}} className="media-body">
																		<label>Kode telah di kirim ke <span style={{fontWeight:800,fontsize:11}}>{location.state.emailnya}</span></label>
																		{ 
																			cektime ? (
																				  <div className="text-center">
																					  <Label>{timerComponents.length ? timerComponents : 'Waktu telah berakhir!'}</Label>
																				  </div>
																			):(
																				<div className="spinner">								
																				  <div className="spinner-icon">  <i className="fa fa-spinner" /></div>
																				</div>
																			)
																		  }	
																		<OtpField numDigits={6} onOtpChange={handleOtpSubmitemail} />												
															</div>
														</div>
													</Row>	
											   ) 									
										)
								}										
								</CardBody>
						  </Card>
						  <div className="mt-5 text-center">

						  </div>
					</Col>
				 </Row>
				</>
		  ):(
		        <>
		        <Row className="justify-content-center">
						<div className="p-3">
									<h4 className="text-muted font-size-14 mb-1 text-center">Verifikasi</h4>
									<p className="text-muted text-center">Pilih salah satu metode di bawah ini</p> 
									<p className="text-muted text-center">untuk mendapat kode verifikasi</p>  					
						</div>
				</Row>	
				<Row className="justify-content-center">
					<Col md={8} lg={6} xl={8}>
						  <Card className="overflow-hidden">
								<CardBody className="pt-4">
								{!prosesotpHP ? (
										<Row  className="justify-content-center">						
												 <div  className="media mb-0">
													<div  style={{  borderRadius: '15px',cursor:'pointer',padding:'20px',boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px'}} className="media-body">
														<Label onClick={()=>kirimOTP2()} style={{cursor:'pointer'}} >Kirim Kode OTP melalui Nomor HP anda <span style={{fontWeight:800,fontsize:11}}>{location.state.nomorhpx}</span></Label>												
													</div>
												 </div>
										</Row> ):( <>
										{formpass ? (
										        <Row  className="justify-content-center">
													<div  className="media mb-0">
													     <div  style={{  borderRadius: '15px',textAlign:'center', padding:'20px',boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px'}} className="media-body">
															   <AvForm style={{textAlign: 'center'}} >
																  <AvField name="password" label="Password" type="password" onChange={inputHandlerpass1}
																	   validate={{
																	required: {value: true, errorMessage: 'Tolong Password di isi'}
																	   }}
																  />
																  <Button onClick={()=>kirimDaftar(location.state.nomorhpx)}  size="sm" className="btn btn-primary " type="submit">Submit</Button>
																</AvForm>
														 </div>
													</div>
												</Row>
											):(
											   	<Row  className="justify-content-center">
													<div  className="media mb-0">
													
														<div  style={{  borderRadius: '15px',textAlign:'center', padding:'20px',boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px'}} className="media-body">
																	 { 
																		cektime ? (
																			  <div className="text-center">
																				  <Label>{timerComponents.length ? timerComponents : 'Waktu telah berakhir!'}</Label>
																			  </div>
																		):(
																			<div className="spinner">								
																			  <div className="spinner-icon">  <i className="fa fa-spinner" /></div>
																			</div>
																		)
																	  }	
																	<OtpField numDigits={6} onOtpChange={handleOtpSubmitemail} />		
																		{
																			cektime ? (
																			    <div className="text-center">
																				{timerComponents.length ? null: (<span onClick={()=>kirimulangOTPreg()} style={{fontSize:'13px',cursor:'pointer'}}>Kirim ulang OTP!</span>)}
																				</div>
																			):null
																		}	
														</div>
													</div>
										        </Row>	
											)
											}
								          </>
										)
								}										
								</CardBody>
						  </Card>
						  <div className="mt-5 text-center">

						  </div>
					</Col>
				 </Row>
                </>				
		  )}
        </Container>
      </div>

    </React.Fragment>
  )
}

export default Verifikasi;

/*Register.propTypes = {
  registerUser: PropTypes.func,
  registerUserFailed: PropTypes.func,
  registrationError: PropTypes.any,
  user: PropTypes.any,
}

const mapStatetoProps = state => {
  const { user, registrationError, loading } = state.Account
  return { user, registrationError, loading }
}

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(Register)*/
