import { all, put, call, takeEvery,takeLatest } from "redux-saga/effects";
import * as actiontype from "./actionTypes";
import {loginBymail} from '../../../helpers/backend'





function* usermasuk(action){
	try{
  console.warn('data payload saga LOGIN',action.payload);

		const logindataresponse=yield call(loginBymail,action.payload)
		 //  console.warn('data response saga LOGIN',logindataresponse);
		yield put({type:actiontype.LOGIN_USER_SUKSES,payload:logindataresponse})
	}catch (err){
		console.warn('terjadi kesalahan')
	}
}


function* loginsaga() {

		yield takeEvery(actiontype.LOGIN_USER,usermasuk)
}

export default loginsaga