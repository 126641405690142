import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AboutUs from './components/AboutUs';
import AboutUsTwo from './components/AboutUs/AboutUsTwo';
import Contact from './components/Contact';
import Error from './components/Error';
import Loader from './components/Helper/Loader';
import ScrollToTop from './components/Helper/ScrollToTop';
import HomeDark from './components/HomeDark';
import HomeEight from './components/HomeEight';
import HomeFive from './components/HomeFive';
import HomeFour from './components/HomeFour';
import HomeOne from './components/HomeOne';
import HomeRtl from './components/HomeRtl';
import HomeSeven from './components/HomeSeven';
import HomeSix from './components/HomeSix';
import HomeThree from './components/HomeThree';
import Hometwo from './components/HomeTwo';
import News from './components/News';
import SingleNews from './components/News/SingleNews';
import Service from './components/Service';
import Shops from './components/Shops';
import ShopDetails from './components/Shops/Details';

import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import Verifikasi from './components/Auth/Verifikasi';
import ForgetPassword from './components/Auth/ForgetPassword';
import Resetpassword from './components/Auth/Resetpassword';
import Userprofile from './components/Profile';
import Pagemobileinvoice from './components/Profile/Invoicemobile'

import Pembayaran from './components/Pembayaran';
import Tespage from './components/Profile/Tespage'

import Orderbayar from './components/HomeOne/Orderbayar'
import Orderbayar2 from './components/HomeOne/Orderbayar2'
import Paysukses from './components/HomeOne/Paysukses'


function Routes() {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        window.scrollTo(0, 0);
    });
    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    });
    return (
        <>
            {loading && (
                <div className={`appie-loader ${loading ? 'active' : ''}`}>
                    <Loader />
                </div>
            )}
            <div className={`appie-visible ${loading === false ? 'active' : ''}`}>
                <Router>
                    <ScrollToTop>
                        <Switch>
                            <Route exact path="/" component={HomeOne} />
                            <Route exact path="/home-two" component={Hometwo} />
                            <Route exact path="/home-three" component={HomeThree} />
                            <Route exact path="/home-four" component={HomeFour} />
                            <Route exact path="/home-five" component={HomeFive} />
                            <Route exact path="/home-six" component={HomeSix} />
                            <Route exact path="/home-seven" component={HomeSeven} />
                            <Route exact path="/home-eight" component={HomeEight} />
                            <Route exact path="/home-dark" component={HomeDark} />
                            <Route exact path="/home-rtl" component={HomeRtl} />
                            <Route exact path="/news" component={News} />
                            <Route exact path="/news/single-news" component={SingleNews} />
                            <Route exact path="/service" component={Service} />
                            <Route exact path="/about-us" component={AboutUs} />
                            <Route exact path="/contact" component={Contact} />
                            <Route exact path="/error" component={Error} />
                            <Route exact path="/about-us-another" component={AboutUsTwo} />
                            <Route exact path="/shops" component={Shops} />
							
							<Route exact path="/tespage" component={Tespage} />
							
							<Route exact path="/login" component={Login} />
							<Route exact path="/register" component={Register} />
							<Route exact path="/verifikasi" component={Verifikasi}/>
							<Route exact path="/shops/shop-details" component={ShopDetails} />
							<Route exact path="/forgot-password" component={ForgetPassword} />
							<Route exact path="/reset-password" component={Resetpassword} />
							<Route exact path="/order-bayar" component={Orderbayar} />
							<Route exact path="/order-bayar-2" component={Orderbayar} />
							<Route exact path="/user-profile" component={Userprofile} />
							<Route exact path="/pembayaran-order" component={Pembayaran}/>
							<Route exact path="/m-page-invoice" component={Pagemobileinvoice}/>
							<Route exact path="/paymentsukses" component={Paysukses}/>
                            <Route component={Error} />
                        </Switch>
                    </ScrollToTop>
                </Router>
            </div>
        </>
    );
}

export default Routes;
