import React, { useState, useRef,useEffect } from 'react';
import { useLocation,Link,useHistory } from "react-router-dom";
import styled from 'styled-components';
import apiService from '../../api/apiService'
import axios from 'axios'
import userimage from '../../assets/images/user.jpg';
import { Row, Col,Button, CardBody, Alert, Container, Input, Label,  Modal,Text } from "reactstrap";
import { AvForm, AvField,AvInput } from "availity-reactstrap-validation";
import {
  CardMeta,
  CardHeader,
  CardDescription,
  CardContent,
  Card,
  Icon,
  Image,GridColumn, Grid,
} from 'semantic-ui-react'
//import { PaperClipIcon } from '@heroicons/react/20/solid'
const OtpContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const OtpInput = styled.input`
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 18px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 0 5px;

  &:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  }

  @media (max-width: 600px) {
    width: 30px;
    height: 30px;
  }
`;

//const Formdatapribadi = ({ numDigits,onOtpChange,aktifasi  }) => {
//,backgroundColor:'#E3EDF8'  alamatuser={alamat2} emailuser={email2} nouser={nohp2} companyuser={company2}
const Formdatapribadi = ({namauser,alamatuser,emailuser,nouser,companyuser }) => {
//	alert(alamatuser)
	  const [modal_ALAMAT, setmodal_ALAMAT] = useState(false)
	  const [email,setEmail]=useState('')
	  const [editalamat,setEditalamat]=useState(false)
	  const [editemail,setEditemail]=useState(false)
	  const [editcompany,setEditcompany]=useState(false)
	  const [alamat2,setAlamat2]=useState('')
	  const [email2,setEmail2]=useState('')
	  const [company2,setCompany2]=useState('');
	  const [company,setCompany]=useState('');	  
	  const [modal_center, setmodal_center] = useState(false)
	  const [modal_email,setModal_email]=useState(false)
	  const [alamat,setAlamat]=useState('');
	   const history = useHistory();
  const ovalStyle = {
    width: '100px', // Set your desired width
    height: '100px', // Set your desired height
	marginTop: '30px',
    borderRadius: '50%', // This creates a circular shape, for an oval shape adjust the radii accordingly
    //backgroundColor: '#fff', // Set your desired background color
	border:'solid 2px #E7F6FF',
	backgroundImage:`url(${userimage})`,
	backgroundSize:'cover',
	position:'absolute',
	zIndex:'11'
  };
    function tog_center_ALAMAT() {
    setmodal_ALAMAT(!modal_ALAMAT)
    removeBodyCss()
  }
  
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

    function tog_modal_email(){
    setModal_email(!modal_email)
    removeBodyCss()
  }
  	const inputHandlerAlamat=(e)=>{
		setAlamat(e.target.value)
	}
	
	 const inputhandleemail=(e)=>{
    setEmail(e.target.value)
  }

    function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }
	
	const inputhandlerCompany=(e)=>{
		setCompany(e.target.value)
	}
	
	 const simpanEmail=()=>{
	   const requestEmail={
			emailuser:email,
			nama:namauser,
	   }
	   const responseCompanysave = axios.post(apiService+'/simpanemail',requestEmail).then(resemail => {
			setEmail2(resemail.data.emailuserfix)
			setEditemail(true)
			setModal_email(false)
	   })
    } 
	
	 const simpanCompany=()=>{
	  const requestCompany={
		  emailuser:emailuser,
          nama:namauser,
		  companyuser:company
	    }
	    const responseCompanysave = axios.post(apiService+'/simpancompanyuser',requestCompany).then(rescompany => {
												   setCompany2(rescompany.data.companyuserfix)
												   setEditcompany(true)
												      setmodal_center(false)
												})
    }
	
	  const kembali=()=>{
        history.push("/");
   }
   
  // 	useEffect(() => {
     // alert(alamatuser)

   // }, []);

	
	  const simpanAlamat=()=>{
		
	  const requestAlamat={
		  emailuser:emailuser,
          nama:namauser,
		  alamatuser:alamat
	   }
	  	 const responseAlamatsave = axios.post(apiService+'/simpanalamatuser',requestAlamat).then(resalamat => {
												     setAlamat2(resalamat.data.alamatuserfix)
													   setEditalamat(true)
												     setmodal_ALAMAT(false)
												})
       }
	   
	  const batalEditalamat =()=>{
		    setEditalamat(false)
		  setmodal_ALAMAT(false)
	  }
	  
	  const batalEditcompany=()=>{
		  setEditcompany(false)
		  setmodal_center(false)
	  }
	  
	  const batalEditemail=()=>{
		  setEditemail(false)
		    setModal_email(false)
	  }
	  
  return (
  
   <div className="row" style={{padding:'0px'}} >
       <div className="row">
		  <div  onClick={()=>kembali()} color="primary" style={{width:'85px',cursor:'pointer',height:'15px',position:'absolute',marginLeft:'-13px',zIndex:'4',borderTop:'15px solid #555',borderRight:' 15px solid transparent',borderBottom:' 15px solid #555',borderTopLeftRadius:'10px'}}>
			<span  style={{color:'#fff',position:'absolute',marginTop:'-8px',marginLeft:'-4px',fontSize:'12px'}}>Kembali</span>
		  </div>
	   </div>
	   <div className="row justify-content-end bg-light"  style={{height:'100px',borderRadius:'10px'}}>
	         <h4 className="text-base font-bold mt-5 leading-4 text-gray-900 sm:truncate sm:text-base sm:tracking-tight">Biodata User</h4>
			 <p style={{marginTop:'-6px'}} className="text-sm leading-2 text-gray-300">{namauser}</p>
           <div style={ovalStyle}></div>
       </div>
	   {/*  <div className="row justify-content-start" >		 
		    <div style={{fontSize:'15px',fontWeight:'500px',marginTop:'15px'}} ><span >Nama</span></div>     
	   </div>
	   <div className="row justify-content-start" >		 
		    <div style={{fontSize:'15px',fontWeight:'500px',marginTop:'15px'}} ><span >Nama</span></div>     
	   </div>*/}
		<div className="row justify-content-center" style={{marginTop:'35px'}}>
		<Grid className="border-bottom">
			<GridColumn width={4}>
			   <dt className="text-sm font-base leading-6 text-gray-900">Nama</dt>
			</GridColumn>
			<GridColumn width={8}>
			  <dd className="mt-1 text-sm  font-small leading-3 text-gray-700 sm:col-span-2 sm:mt-0">{namauser}</dd>
			</GridColumn>
			<GridColumn width={3}>
			   <p> |<Icon  className="text-danger" style={{marginLeft:'9px'}}  name='edit' /></p>
			</GridColumn>
		</Grid>
	  
		<Grid className="border-bottom">
			<GridColumn width={4}>
				<dt className="text-base font-medium leading-6 text-gray-900">Alamat</dt>
			</GridColumn>
			<GridColumn width={8}>
				<dd className="mt-1 text-sm  font-small leading-3 text-gray-700 sm:col-span-2 sm:mt-0">
				{editalamat?alamat2:alamatuser}</dd>
			</GridColumn>
			<GridColumn width={3}>
				<p > |<Icon className="text-danger" onClick={()=>tog_center_ALAMAT()} style={{marginLeft:'9px',cursor:'pointer'}} name='edit' /></p>
			</GridColumn>
		</Grid>
	  
		<Grid className="border-bottom">
			<GridColumn width={4}>
				<dt className="text-sm font-medium leading-6 text-gray-900">Email</dt>
			</GridColumn>
			<GridColumn width={8}>
				<dd className="mt-1 text-sm  font-small leading-3 text-gray-700 sm:col-span-2 sm:mt-0">{editemail?email2:emailuser}</dd>
			</GridColumn>
			<GridColumn width={3}>
				<p> |<Icon className="text-danger" onClick={()=>tog_modal_email()} style={{marginLeft:'9px',cursor:'pointer'}}  name='edit' /></p>
			</GridColumn>
		</Grid>
	  
		<Grid className="border-bottom">
			<GridColumn width={4}>
				<dt className="text-sm font-medium leading-6 text-gray-900">No Telp</dt>
			</GridColumn>
			<GridColumn width={8}>
				  <dd className="mt-1 text-sm  font-small leading-3 text-gray-700 sm:col-span-2 sm:mt-0">{nouser}</dd>
			</GridColumn>
			<GridColumn width={3}>
				<p> |<Icon className="text-danger" style={{marginLeft:'9px',cursor:'pointer'}}  name='edit' /></p>
			</GridColumn>
		</Grid>
	  
		<Grid className="border-bottom">
			<GridColumn width={4}>
				<dt className="text-sm font-medium leading-6 text-gray-900">Company</dt>
			</GridColumn>
			<GridColumn width={8}>
				  <dd className="mt-1 text-sm  font-small leading-3 text-gray-700 sm:col-span-2 sm:mt-0">{editcompany?company2:companyuser}</dd>
			</GridColumn>
			<GridColumn width={3}>
				<p> |<Icon className="text-danger" onClick={()=>tog_center()} style={{marginLeft:'9px',cursor:'pointer'}}  name='edit' /></p>
			</GridColumn>
		</Grid>
	  </div>

	   
	      {/*<div className="mt-20 border-t border-gray-100">
			
			  <div className="px-1 py-6  sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
				<dt className="text-sm font-medium leading-6 text-gray-900">Full name</dt>
				<dd className="mt-1 text-xs  font-small leading-3 text-gray-700 sm:col-span-2 sm:mt-0">Margot Foster</dd>
			  </div>
			  <div className="divide-y-2 divide-blue-200">
			  ss
		      </div>
			  <div className="px-1 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
				<dt className="text-sm font-medium leading-6 text-gray-900">Application for</dt>
				<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">Backend Developer</dd>
			  </div>
			  <div className="px-1 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
				<dt className="text-sm font-medium leading-6 text-gray-900">Email address</dt>
				<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">margotfoster@example.com</dd>
			  </div>
			  <div className="px-1 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
				<dt className="text-sm font-medium leading-6 text-gray-900">Salary expectation</dt>
				<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">$120,000</dd>
			  </div>		   
			
       </div>
	<Card>   
		<CardContent>
		  <CardHeader>Matthew</CardHeader>
		  <CardMeta>
			<span className='date'>Joined in 2015</span>
		  </CardMeta>
		  <CardDescription>
			Matthew is a musician living in Nashville.
		  </CardDescription>
		</CardContent>
		<CardContent extra>
		  <a>
			<Icon name='user' />
			22 Friends
		  </a>
		</CardContent>
	   </Card>*/}
	   {/*//////////////////////////////////////////////////////////MODAL/////////////////////////////////////////////*/}
	     <Modal
                    isOpen={modal_ALAMAT}
                    toggle={() => {
                      tog_center_ALAMAT()
                    }}
                    centered={true}
                  >
                    <div className="modal-header">
                      <h5 className="modal-title mt-0">Center Modal</h5>
                      <button
                        type="button"
                        onClick={() => {
						  batalEditalamat();
                          
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
						 <Row>
						    <Col xs={2}><span  style={{justifyContent: 'center',alignItems: 'center'}}>Alamat</span></Col>
							<Col xs={10}>
							    <AvForm style={{textAlign: 'center'}} >

                                 <AvInput type="textarea" onChange={inputHandlerAlamat} name="address" id="address" placeholder="Address" required />
								</AvForm>
							</Col>

						 </Row>
                    </div>
					   <div className="modal-footer">
                      <Button  type="button" color="secondary" onClick={()=>simpanAlamat()} size="sm">
                        Save changes
                            </Button>
							{/* <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
						size="sm"
                      >Close
                            </button>*/}
                    </div>
                  </Modal>
				   {/*//////////////////////////////////////////FORM MODAL UBAH EMAIL//////////////////////////////////////////////////*/}

              <Modal
                      isOpen={modal_email}
                      toggle={() => {
                        tog_modal_email()
                      }}
                      centered={true}
                    >
                      <div className="modal-header">
                        <h5 className="modal-title mt-0">Form ubah Email</h5>
                        <button
                          type="button"
                          onClick={() => {
                           batalEditemail()
                          }}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
  						 <Row>
  						    <Col xs={2}><span  style={{justifyContent: 'center',alignItems: 'center'}}>Email</span></Col>
  							<Col xs={10}>
  							    <AvForm style={{textAlign: 'center'}} >
  									  <AvField name="email" type="email"  size="sm" onChange={inputhandleemail}
  									   validate={{
  									required: {value: true, errorMessage: 'Tolong Email di isi'}
  									   }}
  									/>
                                   {/*<AvInput type="textarea" name="address" id="address" placeholder="Address" required />*/}
  								</AvForm>
  							</Col>

  						 </Row>
                      </div>
  					   <div className="modal-footer">
                        <Button type="button"  color="secondary" onClick={()=>simpanEmail()} size="sm">
                          Save changes
                              </Button>
  							{/* <button
                          type="button"
                          className="btn btn-secondary"
                          data-dismiss="modal"
  						size="sm"
                        >Close
                              </button>*/}
                      </div>
                    </Modal>
			   	  {/*//////////////////////////////////////////FORM MODAL UBAH COMPANY//////////////////////////////////////////////////*/}
			      <Modal
                    isOpen={modal_center}
                    toggle={() => {
                      tog_center()
                    }}
                    centered={true}
                  >
                    <div className="modal-header">
                      <h5 className="modal-title mt-0">Center Modal</h5>
                      <button
                        type="button"
                        onClick={() => {
                          batalEditcompany()
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
						 <Row>
						    <Col xs={2}><span  style={{justifyContent: 'center',alignItems: 'center'}}>Company</span></Col>
							<Col xs={10}>
							    <AvForm style={{textAlign: 'center'}} >
									  <AvField name="company" type="text"  size="sm" onChange={inputhandlerCompany}
									   validate={{
									required: {value: true, errorMessage: 'Tolong Company di isi'}
									   }}
									/>
                                 {/*<AvInput type="textarea" name="address" id="address" placeholder="Address" required />*/}
								</AvForm>
							</Col>

						 </Row>
                    </div>
					   <div className="modal-footer">
                      <Button type="button" color="secondary" onClick={()=>simpanCompany()} size="sm">
                        Save changes
                            </Button>
							{/* <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
						size="sm"
                      >Close
                            </button>*/}
                    </div>
                  </Modal>
	   
	   
   </div>
 
  
  );
};

export default Formdatapribadi;
