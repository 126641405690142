import React, { useState,useEffect,useRef } from "react"
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import FeaturesHomeOne from '../HomeOne/FeaturesHomeOne';
import PricingHomeOne from '../HomeOne/PricingHomeOne';
import BlogHomeThree from '../HomeThree/BlogHomeThree';
import DownloadHomeThree from '../HomeThree/DownloadHomeThree';
import CounterArea from '../HomeTwo/CounterArea';
import ServicesHomeTwo from '../HomeTwo/ServicesHomeTwo';
import VideoPlayerHomeTwo from '../HomeTwo/VideoPlayerHomeTwo';
import Drawer from '../Mobile/Drawer';
import FooterHomeSix from './FooterHomeSix';
import HeaderHomeSix from './HeaderHomeSix';
import Pembayaran from './Pembayaran';
import { useLocation,useHistory } from "react-router-dom";


function HomeSix() {
		  const location = useLocation();
	   const history = useHistory();
    const [drawer, drawerAction] = useToggle(false);
		const [logurlpay,setLogurlpay]=useState(false)
	 useEffect(() => {
	
			    const params = new URLSearchParams(window.location.search);
                const paramValue = params.get('order_id');
				//console.warn('parammmmmmmmmmmmmmmmmmmmmmmmmmvalueeeee',paramValue)
				if(paramValue===null){
					 setLogurlpay(false)
					
				}else{
					setLogurlpay(true)
					 const requestbayar={
					 idpayment:paramValue,
			   //    emailpel:mailuser			
					}			 
					history.push({
					pathname:"/order-bayar-2",
					state :requestbayar
					});
				}
			 //  console.warn('reeeeeeeeeeeeeeeeeeeeeeeeeeee',location.state)
			/*  if(location.state){
				  setLogurlpay(true)
				  history.replace({
					...location,
					state: undefined,
				  });
			  }*/
		  },[])
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HeaderHomeSix action={drawerAction.toggle} />
			
            <Pembayaran
	         setLogurlpay={setLogurlpay}
			logurlpay={logurlpay}
			/>
    
            <FooterHomeSix />
        </>
    );
}

export default HomeSix;
