import React, { useEffect,useState } from 'react';
import apiService from '../../api/apiService'
import logo7 from '../../assets/images/logo-7.png';
import logo from '../../assets/images/logo.png';
import StickyMenu from '../../lib/StickyMenu';
import Navigation from '../Navigation';
import jwt_decode from "jwt-decode";
import Cookies from 'js-cookie';
//import { FaBeer } from 'react-icons/fa';
 // import { library } from '@fortawesome/fontawesome-svg-core'
  //import { fab } from '@fortawesome/free-brands-svg-icons'
  import { faCheckSquare, faCoffee } from '@fortawesome/free-solid-svg-icons'
 import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope,faCartShopping } from '@fortawesome/free-solid-svg-icons'
 // import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { connect,useDispatch,useSelector } from "react-redux";
// import images
//import logoLightPng from "../../assets/images/logo-light.png"
import * as actionType from "../../store/actions";
  //library.add(fab, faCheckSquare, faCoffee)
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardFooter,CardHeader,CardText,Button,
  Modal,
    Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap"
import { withRouter, Link,useHistory } from "react-router-dom"
import { googleLogout, useGoogleLogin, } from '@react-oauth/google';
import axios from 'axios';
import AuthService from '../../lib/Authservice'
function HomeOneHeader({
    lang,
    darkEnable = false,
    action,
    langEnabled = false,
    changeMode,
    changeModeLan,
    dark,
	cartToggle,
    className,
	actions,
	datasharing,
	sharedState,
	setSharedState,
	datakeranjang,setDatakeranjang,
}) {
	  const [modal_center, setmodal_center] = useState(false)

    useEffect(() => {
        StickyMenu();
    });



   function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
    const [menu, setMenu] = useState(false)
    const [keranjang,setKeranjang]=useState(false)
    const [ user, setUser ] = useState([]);
    const [ profile, setProfile ] = useState([]);
    const [countorder,setCountorder]=useState(0);
    const [name, setName] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [currentUser, setCurrentUser] = useState(undefined);
    const [loginData, setLoginData] = useState(null);
    const history = useHistory();
 /*   const login =  useGoogleLogin({
       onSuccess:  (codeResponse) => {
			//localStorage.setItem("authUser", JSON.stringify(codeResponse))
			    sessionStorage.setItem("authUser", JSON.stringify(codeResponse));
				console.warn('data 11111111111',codeResponse)
	          setmodal_center(false)
		},

		//onSuccess: codeResponse => localStorage.setItem("authUser", JSON.stringify(codeResponse)),
        // flow: 'auth-code',
        onError: (error) => console.log('Login Failed:', error)

    });*/

 useEffect(() => {
  //  const user = AuthService.getCurrentUser();

  //  if (user) {
  //    setCurrentUser(user);
  //  }
  refreshToken();
  }, []);

  const userLogout =async()=>{
	  const response6 = await axios.get(apiService+'/logout',{withCredentials: true}).then(res => {
		  let hasKey = res.data.hasOwnProperty('success')
		  if(hasKey){
			  if(res.data.success[0].jenis==='auth'){
				  setLoginData(null)
				  setSharedState(0)
			  }
		  }
	  })
  }
   const refreshToken = async () => {
      //  try {
            /*const response = await axios.get('http://localhost:5000/token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);*/
			const specificCookieValue = Cookies.get('refreshToken');
		//	console.warn('coookiiiiiiiesnya',specificCookieValue)
			   	const response6 = await axios.get(apiService+'/token',{withCredentials: true}).then(res => {
					//   console.warn('response', res.data)
					 let hasKey = res.data.hasOwnProperty('errors');
					 if(hasKey){
                       // userLogout()
						setLoginData(null)
					 }else{
					     setToken(res.data.accessToken);
						 const decoded = jwt_decode(res.data.accessToken);
						 setName(decoded.username);
						 setExpire(decoded.exp);
						 setLoginData(res.data.accessToken)
                         setUser(decoded.email)
					 }


				})
      //  } catch (error) {
      //      if (error.response) {
              //   history.push("/");
      //      }
      //  }
    }
	
	const pageProfil=()=>{
	    	const request1={
				datauser1:user,
				nama:name
			}
		
			const response6 = axios.post(apiService+'/getdatapenyewa', request1).then(res => {
		             	    const datauser={
									  emailuser:user,
									  hpuser:res.data.nohpuser,
									  alamatuser:res.data.alamatuser,
									  companyuser:res.data.prshuser,
									  usernam:name,
                                      panelactiv:'biodata'
								  }
									history.push({
									 pathname:"/user-profile",
									 state :datauser
									});
				   })
		                    
	}



	const axiosJWT = axios.create();
	const currentDate1 = new Date();
  // console.warn('expire datenyaaa...',expire)
   //   console.warn('expire perbandingan...',currentDate1.getTime())
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
          /*  const response = await axios.get('http://localhost:5000/token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);*/
		    const response7 = await axios.get(apiService+'/token').then(res => {
				  	 let hasKey = res.data.hasOwnProperty('errors');
					 if(hasKey){
					//	  history.push("/");

					 setLoginData(null)
					 }else{
						config.headers.Authorization = `Bearer ${res.data.accessToken}`;
						setToken(res.data.accessToken);
						const decoded = jwt_decode(res.data.accessToken);
						setName(decoded.username);
						setExpire(decoded.exp);
						setLoginData(res.data.accessToken)
					 }

			})
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });


	const handlelogin=useGoogleLogin({
       onSuccess:   (codeResponse) => {
		           const request1={token: codeResponse.access_token}
			       // const response2 =  axios.post('http://localhost:4001/google-login', request1)
				 /* const res = axios.post('http://localhost:4001/google-login', {
				  method: 'POST',
				  body: JSON.stringify({
					token: codeResponse.access_token,
				  }),
				  headers: {
					'Content-Type': 'application/json',
				  },
				  });*/
				 //  const data = response2.json();

		        	const response6 = axios.get(apiService+'/google-login',{ params: request1}).then(res => {
		             	let arr3 = res.data;
						 setLoginData(arr3);
                         sessionStorage.setItem('loginData', JSON.stringify(arr3));
         				//console.warn('data balik',arr3)
				   })
		},

        onError: (error) => console.log('Login Failed:', error)

    });


    // useEffect(()=>{
	//    googleLogout();
   //  })


    /*useEffect(



       () => {
           if (JSON.parse(sessionStorage.getItem('authUser'))!==null) {
                axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${JSON.parse(sessionStorage.getItem('authUser')).access_token}`, {
                        headers: {
                            Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('authUser')).access_token}`,
                            Accept: 'application/json'
                        }
                    })
                    .then((res) => {
					 console.warn('email',res.data);
							 setProfile(res.data);
                    })
                    .catch((err) => console.log(err));
            }


        },
        [ user ]

    );*/
//	console.warn('logindataaaa.. ',loginData)
  //  console.warn('userrr',JSON.parse('tokennnnnn',localStorage.getItem('authUser')).access_token)
    // log out function to log the user out of google and set the profile array to null
	const pagePayment=()=>{
			 history.push({
						 pathname:"/pembayaran-order"
						// state :{jnsver:'email'}
			 });
	}

    const logOut = () => {
        googleLogout();

	    sessionStorage.clear();
		setLoginData(null);
    };
	//console.warn('session',JSON.parse(sessionStorage.getItem('loginData')))
//	console.warn('jumlah order datakeranjang',datakeranjang)
    return (


        <header className={`appie-header-area appie-sticky ${className || ''}`}>
            <div className="container">
                <div className="header-nav-box">
                    <div className="row align-items-center">
                        <div className="col-lg-2 col-md-4 col-sm-5 col-6 order-1 order-sm-1">
                            <div className="appie-logo-box">
                                {darkEnable && dark ? (
                                    <a href="/">
                                        <img src={logo7} alt="" />
                                    </a>
                                ) : (
                                    <a href="/">
                                        <img src={logo} alt="" />
                                    </a>
                                )}
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-1 col-sm-1 order-3 order-sm-2">
                            <div className="appie-header-main-menu">
                                <Navigation />
                            </div>
                        </div>
						{/*////////////////////////////////////////////CART//////////////////////////////////////////////*/}

                        <div className="col-lg-4  col-md-7 col-sm-6 col-6 order-2 order-sm-3">
                            <div className="appie-btn-box text-right">
                                {/*darkEnable &&
                                    (dark ? (
                                        <span
                                            className="dark__btn__sun"
                                            onClick={(e) => changeMode(e)}
                                        >
                                            <svg
                                                fill="none"
                                                stroke="currentColor"
                                                viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
                                                ></path>
                                            </svg>
                                        </span>
                                    ) : (
                                        <span
                                            className="dark__btn__mon"
                                            onClick={(e) => changeMode(e)}
                                        >
                                            <svg
                                                fill="none"
                                                stroke="currentColor"
                                                viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
                                                ></path>
                                            </svg>
                                        </span>
                                    ))*/}
                                {/*langEnabled &&
                                    (lang ? (
                                        <span
                                            className="align-content-center ml-3"
                                            onClick={(e) => changeModeLan(e)}
                                        >
                                            RTL
                                        </span>
                                    ) : (
                                        <span
                                            className="align-content-center mr-3"
                                            onClick={(e) => changeModeLan(e)}
                                        >
                                            LTR
                                        </span>
                                    ))*/}

									{
										loginData ? (

													<Dropdown
													     isOpen={menu}
														toggle={() => setMenu(!menu)}
														className="d-inline-block">
													  <DropdownToggle 	className="btn header-item waves-effect"
													  tag="button">
												        <a  className="login-btn far fa-home" style={{cursor:'pointer',fontSize:'25px'}}></a>
													  </DropdownToggle>
													  <DropdownMenu className="dropdown-menu">
														<div style={{width:'200px',height:'100%',padding:'5px 15px 30px 15px'}}>
															<div style={{borderBottom:'solid 1px #ccc',fontSize:'12px',height:'25px'}}>{name}</div>
															<div style={{marginTop:'15px'}}>
																   <span style={{cursor:'pointer'}} onClick={()=>pageProfil()}>Biodata</span>
															</div>
															<div style={{marginTop:'5px',borderTop:'solid 1px #ccc',paddingTop:'5px'}}>
																   <span onClick={()=>userLogout()} style={{float:'right',height:'20px',fontSize:'11.5px',fontWeight:'500',cursor:'pointer'}}>Logo Out</span>
															</div>
														</div>
													{/*	<DropdownItem onClick={()=>userLogout()}>
														 Log Out
														</DropdownItem>
														<Card style={{height:'100% !important',width:'200px',border:'0px'}} >
														  <CardHeader style={{padding:'5px',fontSize:'13px'}}>user</CardHeader>
														  <CardBody>
														  <Row >
															 <Col style={{textAlign: 'left',padding:'0px'}} md="12">
															Profile
															 </Col>
														  </Row>
															 <Row  style={{marginBottom:'-5px !important'}}>
																<Col md="6"></Col>
																<Col md="6">
																	Button
																</Col>
															 </Row>
														  </CardBody>
														</Card>*/}

													  </DropdownMenu>
													</Dropdown>
								        ):(
										  <Link to="/login" >
                                           <a  className="login-btn" style={{cursor:'pointer'}}>
											<i className="far fa-user" /><span style={{fontSize:'13px'}} >Login</span>
										  </a>
                                          </Link>

										)
									}

								{/*   <a style={{color:"#fff"}} className="main-btn ml-30">
                                    Get Booking
                                </a>*/}

                                          {/*  <a
                                                onClick={cartToggle}
                                                className="cart-btn amm-shopping-cart-open ml-30"
                                                href="#"
                                            >
												<span style={{ color: "#7B39FE" }} className="badge">
																	<FontAwesomeIcon icon={faCartShopping} size="lg"  style={{ color: "#7B39FE" }}  />{sharedState}
												</span>

										  </a>*/}
												<Dropdown
													    isOpen={keranjang}
														toggle={() => setKeranjang(!keranjang)}
														className="d-inline-block">
													  <DropdownToggle className="btn header-item waves-effect"
													  tag="button">
												        	<span style={{ color: "#7B39FE" }} className="badge">
																	<FontAwesomeIcon icon={faCartShopping} size="lg"  style={{ color: "#7B39FE" }}  />{sharedState}
											               	</span>
													  </DropdownToggle>
													  <DropdownMenu className="dropdown-menu2">
														{ datakeranjang.length>0 ?
															   (
																	  <Card   style={{height:'100% !important',width:'300px',border:'0px'}}>
																		<CardHeader style={{padding:'5px',fontSize:'13px'}}>Keranjang</CardHeader>
																		<CardBody style={{padding:'0px',marginTop:'10px',marginBottom:'0px'}}>
																		{/*<CardTitle>Special Title Treatment</CardTitle>
																		<CardText>With supporting text below as a natural lead-in to additional content.</CardText>*/}
																		<ul>
																		{ datakeranjang.map(cart => {
																			 return(
																					 <li onClick={()=>pagePayment()} style={{paddingLeft:'10px',paddingRight:'10px',cursor:'pointer'}}>
																							<div style={{paddingTop:'0px',paddingBottom:'0px',borderBottom:'solid 1px #000',marginTop:'-6px'}} className="d-flex  mb-3">
																							  <div style={{fontSize:'15px'}} className="p-0">
																							  <div className="row"><span> {'#'+cart.id_order+' '+cart.Nama}</span></div>
																							  <div className="row"><span style={{fontSize:'12px'}}>{
																								  'Jam: '+cart.jamawal+' - '+cart.jamakhir
																								  }</span></div>
																							  </div>
																							  <div style={{fontSize:'12px',marginTop:'5px'}} className="ms-auto p-0 ">Rp{' '+cart.jumlahbayar}</div>
																							</div>
																					 </li>
																			 )
																		}) }
																		</ul>
																		</CardBody>
																	  </Card>  ):(
																  <DropdownItem >
																  <div style={{width:'100%',height:'100%',padding:'10px 10px 10px 10px'}}>
																   Tidak ada pesanan dalam keranjang
																   </div>
																  </DropdownItem>
																)
														  }
													  </DropdownMenu>
													</Dropdown>

                                <div
                                    onClick={(e) => action(e)}
                                    className="toggle-btn ml-30 canvas_open d-lg-none d-block"
                                >
                                    <i className="fa fa-bars" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

				<Modal
                    isOpen={modal_center}

                    centered={true}
                  >
                    <div className="modal-header">
                      <h5 className="modal-title mt-0">Center Modal</h5>
                      <button
                        type="button"
                        onClick={() => {
                          setmodal_center(false)
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
			             <div>
							<h2>React Google Login</h2>
							<br />
							<br />
							{loginData ? (
								<div>

									<img src={loginData.picture} alt="user image" />
									<h3>User Logged in</h3>
									<p>Name: {loginData.name}</p>
									<p>Email Address: {loginData.email}</p>
									<br />
									<br />
									<button onClick={logOut}>Log out</button>
								</div>
							) : (
								<button onClick={() => handlelogin()}>Sign in with Google 🚀 </button>
							)}
						</div>
                    </div>
                  </Modal>
            </div>
        </header>



    );
}

//export default HomeOneHeader;
const mapStateToProps = (state) => {
  //  console.log(state);
    return ({
      //  datasharing: 'tes',

    })
};

const mapDispatchToProps = (dispatch) => ({
    actions: {
        userMasuk: (payload) => {
			//console.warn('data payload',payload)
          //  dispatch(actionType.loginUser(payload));
        },


    },
});
export default connect(mapStateToProps, mapDispatchToProps)(HomeOneHeader);
