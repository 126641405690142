//import PropTypes from "prop-types"
import React, { useEffect,useState,useRef } from "react";
//import MetaTags from 'react-meta-tags';
import { Row, Col,Button, CardBody, Alert, Container, Input,  Modal,Text } from "reactstrap";
import axios from 'axios';
// availity-reactstrap-validation
import jwt_decode from "jwt-decode";
import { AvForm, AvField,AvInput } from "availity-reactstrap-validation";
import moment from 'moment-timezone';
import logo from '../../assets/images/logo.png';

//import Parse from 'parse/dist/parse.min.js';
// action
//import { registerUser, apiError, registerUserFailed } from "../../store/actions"
//import OTPGenerator from 'otp-generator';
// Redux
//import { connect } from "react-redux"
import {
  CardMeta,
  CardHeader,
  CardDescription,
  CardContent,
  Card,
  Icon,Label,
  Image,GridColumn, GridRow,Grid,ListItem, ListHeader, List,ListContent,
} from 'semantic-ui-react'
import {ReactToPrint,useReactToPrint} from 'react-to-print';
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { useLocation,Link,useHistory } from "react-router-dom";
//import * as Icon from 'react-bootstrap-icons';
import Formdatapribadi from './DatadiriMobile'
import DatalistBayar from './ListBayar'
import Datalisttransaksi from './ListTransaksi'
import Cetakinvoice from './cetakinvoice'
import Tespage from './Tespage'
//SweetAlert
//import Timer from './Timer'
//import CountdownTimer from './CountdownTimer';
//import OtpField from './OtpField';
import SweetAlert from "react-bootstrap-sweetalert";
//import MetisMenu from "metismenujs"
import  MetisMenu from 'metismenujs';
import apiService from '../../api/apiService'
//import SimpleBar from "simplebar-react"
// import images
//import logoLightPng from "../../assets/images/logo-light.png"
import logoDark from "../../assets/images/logo-dark.png";
import Sms from "../../assets/images/iconsms.png";
import { googleLogout, useGoogleLogin,GoogleLogin  } from '@react-oauth/google';
import Loader from '../../components/Helper/Loader';
import styled from 'styled-components';


const AccordionItem = styled.div`
  margin-bottom: 10px;
`;

const AccordionHeader = styled.div`
  background-color: #ffffff;
  color: white;
  padding: 10px;
  cursor: pointer;
`;

const AccordionContent = styled.div`
  overflow: hidden;
  transition: max-height 0.3s ease;
  max-height: ${({ isOpen }) => (isOpen ? '500px' : '0')};
`;

const Profile1 = props => {
	  const ref = useRef()
  // handleValidSubmit
 // const handleValidSubmit = (event, values) => {
  //  props.registerUser(values)
 // }
 const [tokenku,setTokenku]=useState('');


      const [loginData, setLoginData] = useState(null);
      const [name, setName] = useState('');
      const [token, setToken] = useState('');
      const location = useLocation();
      const [nama,setNama]=useState('');
      const [nama2,setNama2]=useState('');
      const [email,setEmail]=useState('')
      const [alamat,setAlamat]=useState('');
	  const [bayardata,setBayardata]=useState([]);
      const [alamat2,setAlamat2]=useState('');
      const [nohp,setNohp]=useState('');
      const [nohp2,setNohp2]=useState('');
      const [email2,setEmail2]=useState('')
      const [company,setCompany]=useState('');
      const [expire, setExpire] = useState('');
      const [company2,setCompany2]=useState('');
      const [panel,setPanel]=useState('profile');
      const [collapsed, setCollapsed] = useState(true);
      const [collapsed1, setCollapsed1] = useState(true);
      const [modal_center, setmodal_center] = useState(false)
      const [modal_ALAMAT, setmodal_ALAMAT] = useState(false)
      const [modal_TELP, setmodal_TELP] = useState(false)
      const [modal_email,setModal_email]=useState(false)
      const [currentuser,setCurrentuser]=useState('');
	  const [tgltransaksi,seTgltransaksi]=useState('')
	  const [transaksidata,setTransaksidata]=useState([])
	  const [modal_detiltransaksi, setmodal_detiltransaksi]=useState(false)
	  const [currentdetil, setCurrentdetil]=useState({})
	  const [currentbayar,setCurrentbayar]=useState({})
	  const [totbayarinv,setTotbayarinv]=useState(0)
	  const [currentinvoice,setCurrentinvoice]=useState({})
	  const [descjam,setDescjam]=useState([])
	  const [descinv,setDescinv]=useState([])
	  const [descbayar,setDescbayar]=useState([])
	  const [ispopup,setIspopup]=useState(null)
	  const popupRef = useRef(null);
	  const [modal_detpembayaran,setModal_detpembayaran]=useState(false)
	  const [modal_pageinvoice,setModal_pageinvoice]=useState(false)

      const history = useHistory();

      useEffect(()=>{
		  
        refreshToken ();
		//if(location.state.panelactiv==='biodata'){}else{}
      },[])

      const refreshToken = async () => {
      //  try {
            /*const response = await axios.get('http://localhost:5000/token');
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);*/
          const response6 = await axios.get(apiService+'/token',{withCredentials: true}).then(res => {
         //    console.warn('response ++++++++++++++++++++++++++++++++++', res.data)
           let hasKey = res.data.hasOwnProperty('errors');
           if(hasKey){
               history.push("/login");
             setLoginData(null)
			 //  userLogout()
           }else{
               setToken(res.data.accessToken);
             const decoded = jwt_decode(res.data.accessToken);
             setName(decoded.username);
             setExpire(decoded.exp);
             setLoginData(res.data.accessToken)
          //   getDetailorder(decoded.email)
              setCurrentuser(decoded.email)

                // const updatedorderpilih = { ...orderpilih,
              //		user:decoded.username,
              //		mail:decoded.email
              //  };
                  //  setOrderpilih(updatedorderpilih);
           }

        })
      //  } catch (error) {
      //      if (error.response) {
              //   history.push("/");
      //      }
      //  }
    }

	  const userLogout =async()=>{
	  const response6 = await axios.get(apiService+'/logout',{withCredentials: true}).then(res => {
		  let hasKey = res.data.hasOwnProperty('success')
		  if(hasKey){
			  if(res.data.success[0].jenis==='auth'){
				  setLoginData(null)
				
			  }
		  }
	  })
  }

   const axiosJWT = axios.create();
   // console.warn('expire datenyaaa...',expire)
   // console.warn('expire perbandingan...',currentDate1.getTime())
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
          /*  const response = await axios.get('http://localhost:5000/token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);*/
        const response7 = await axios.get(apiService+'/token',{withCredentials: true}).then(res => {
             let hasKey = res.data.hasOwnProperty('errors');
           if(hasKey){
           // history.push("/");
		   //  userLogout()
                         history.push("/login");
           setLoginData(null)
           }else{
            config.headers.Authorization = `Bearer ${res.data.accessToken}`;
            setToken(res.data.accessToken);
            const decoded = jwt_decode(res.data.accessToken);
            setName(decoded.username);
              setExpire(decoded.exp);
            setLoginData(res.data.accessToken)
           }
      })
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

   const kembali=()=>{
        history.push("/");
   }
  const toggleCollapse = () => {
    setCollapsed(!collapsed);
	setCollapsed1(true);
  };

    const toggleCollapse1 = () => {
    setCollapsed1(!collapsed1);
	setCollapsed(true);
  };

  const Ubahcompany=(data)=>{
	    tog_center();
  }

    function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  function tog_center_ALAMAT() {
    setmodal_ALAMAT(!modal_ALAMAT)
    removeBodyCss()
  }
  
  function tog_modal_detiltransaksi(){
	   setmodal_detiltransaksi(!modal_detiltransaksi)
	   removeBodyCss()
  }
  
  function tog_modal_detilpembayaran(){
	  setModal_detpembayaran(!modal_detpembayaran)
	  removeBodyCss()
  }
  
   function tog_modal_pageinvoice(){
	  setModal_pageinvoice(!modal_pageinvoice)
	  removeBodyCss()
  }

  function tog_modal_email(){
    setModal_email(!modal_email)
    removeBodyCss()
  }

    function tog_center_TELP() {
    setmodal_TELP(!modal_TELP)
    removeBodyCss()
  }
    function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  	const inputHandlerAlamat=(e)=>{
		setAlamat(e.target.value)
	}
	
	const inputHandlernoHp=(e)=>{
		setNohp(e.target.value)
	}

	const inputhandlerCompany=(e)=>{
		setCompany(e.target.value)
	}

  const inputhandleemail=(e)=>{
    setEmail(e.target.value)
  }

  const simpanAlamat=()=>{
	  const requestAlamat={
		  emailuser:email2,
      nama:nama2,
		  alamatuser:alamat
	  }
	  	 const responseAlamatsave = axios.post(apiService+'/simpanalamatuser',requestAlamat).then(resalamat => {
												   setAlamat2(resalamat.data.alamatuserfix)
												     setmodal_ALAMAT(false)
												})
  }
/*///////////////////////////////////////PANEL PEMBAYARAN////////////////////////////////////*/
const detilPembayaran=()=>{
  setPanel('pembayaran')
   const dataparam={
   emailuser : location.state.emailuser
 }
//  console.log('data param user ###################################################',location.state.emailuser)
  const caridata =  axios.post(apiService+'/caridatapembayaran',dataparam).then(resdatauser => {
       //  console.warn('data bayar ====',resdatauser.data)
		 setBayardata(resdatauser.data)
   })
}
const detilTransaksi=()=>{
  setPanel('transaksi')
  const paramtransaksi={
	   emailuser : location.state.emailuser
  }
  
  const caridatatransaksi= axios.post(apiService+'/caridatatransaksi',paramtransaksi).then(restdata=>{
	  setTransaksidata(restdata.data)
	//  console.warn('data transaksiiiiiiii',restdata.data)
  })
}
const detilBiodata=()=>{
  setPanel('biodata')
}

 const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount





const tglOrder=(tglcreate)=>{
		const tgl = new Date(tglcreate);
		const tglkonversi = moment(tgl).tz('Asia/Bangkok');
		const monthNames = [
				'Januari', 'Februari', 'Maret', 'April',
				'Mei', 'Juni', 'Juli', 'Agustus',
				'September', 'Oktober', 'November', 'Desember'
				];
		const tahun = tgl.getFullYear();
		const monthIndex = tgl.getMonth();
		const monthName = monthNames[monthIndex];
		const tglfix = tgl.getDate();
		//seTgltransaksi(`${tglfix}`)
		const hasil=tglfix+' '+monthName+' '+tahun
		return hasil;
}

const tglExp=(tglexpire)=>{
	    const tgl = new Date(tglexpire);
		const tglkonversi = moment(tgl).tz('Asia/Bangkok');
				const monthNames = [
				'Jan', 'Feb', 'Maret', 'April',
				'Mei', 'Juni', 'Juli', 'Agustus',
				'Sep', 'Okt', 'Nov', 'Des'
				];
		const jamnya=tglkonversi.format('HH:mm');
		const monthIndex = tgl.getMonth();
		const monthName = monthNames[monthIndex];
		const tglfix = tgl.getDate();
		const tglexpired =tglfix+' '+monthName+','+jamnya
		return tglexpired;
}

const tglOrdertransaksi=(tglcreate)=>{
		const tgl = new Date(tglcreate);
		const tglkonversi = moment(tgl).tz('Asia/Bangkok');
		const monthNames = [
				'Jan', 'Feb', 'Maret', 'April',
				'Mei', 'Juni', 'Juli', 'Agustus',
				'Sep', 'Okt', 'Nov', 'Des'
				];
		const tahun = tgl.getFullYear();
		const monthIndex = tgl.getMonth();
		const monthName = monthNames[monthIndex];
		const tglfix = tgl.getDate();
		//seTgltransaksi(`${tglfix}`)
		const hasil=tglfix+' '+monthName+' '+tahun
		return hasil;
}

const waktuOrder=(tglorder)=>{
	       const tgl = new Date(tglorder);
		const tglkonversi = moment(tgl).tz('Asia/Bangkok');
				const monthNames = [
				'Jan', 'Feb', 'Maret', 'April',
				'Mei', 'Juni', 'Juli', 'Agustus',
				'September', 'Oktober', 'November', 'Desember'
				];
		const jamnya=tglkonversi.format('HH:mm');
		const monthIndex = tgl.getMonth();
		const monthName = monthNames[monthIndex];
		const tahun = tgl.getFullYear();
		const tglfix = tgl.getDate();
		const tglORDER1 =tglfix+' '+monthName+' '+tahun+', '+jamnya+' WIB'
		return tglORDER1;
}

const WaktuInvoice=(tglorder)=>{
	       const tgl = new Date(tglorder);
		const tglkonversi = moment(tgl).tz('Asia/Bangkok');
				const monthNames = [
				'01', '02', '03', '04',
				'05', '06', '07', '08',
				'09', '10', '11', '12'
				];
		const jamnya=tglkonversi.format('HH:mm');
		const monthIndex = tgl.getMonth();
		const monthName = monthNames[monthIndex];
		const tahun = tgl.getFullYear();
		const tglfix = tgl.getDate();
		const tglORDER1 =tahun+'/'+monthName+'/'+tglfix
		return tglORDER1;
}

const getDayName = (dateString) => {
  const days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];
  const date = new Date(dateString);
  const dayIndex = date.getDay();
  return days[dayIndex];
};


const tglOrderdetil=(tglcreate)=>{
		const tgl = new Date(tglcreate);
		const tglkonversi = moment(tgl).tz('Asia/Bangkok');
		const monthNames = [
				'Jan', 'Feb', 'Maret', 'April',
				'Mei', 'Juni', 'Juli', 'Agustus',
				'Sep', 'Okt', 'Nov', 'Des'
				];
		const tahun = tgl.getFullYear();
		const monthIndex = tgl.getMonth();
		const monthName = monthNames[monthIndex];
		const tglfix = tgl.getDate();
		//seTgltransaksi(`${tglfix}`)
		const hasil=tglfix+' '+monthName+' '+tahun
		return hasil;
}

const hitungjambooking=(jam1,jam2)=>{
	const selisih=jam1.split(':')
	const selisih2=jam2.split(':')
	const hasil=Number(selisih2[0])-Number(selisih[0])
	return hasil
}

const jamdetilinv=(tglbook,jmawal,jmakhir)=>{
	let format1= jmawal+':00'
	let format2=jmakhir+':00'
	return tglOrder(tglbook)+', '+format1+' - '+format2
}

const hitungtotbiaya=(akhir,awal)=>{
	const hitung=Number(akhir)-Number(awal)
	const hasil=hitung*125000
	   const formattedCurrency = new Intl.NumberFormat('id-ID', {
				  style: 'currency',
				  currency: 'IDR',
				  minimumFractionDigits: 2,
				}).format(hasil);
	return formattedCurrency
}

const formattotbiayainv=(nominal)=>{
	
	   const formattedCurrency = new Intl.NumberFormat('id-ID', {
				  style: 'currency',
				  currency: 'IDR',
				  minimumFractionDigits: 0,
				}).format(nominal);
				
				return formattedCurrency
}

const hitungvat=(nominal)=>{
	let hasilakhir=(nominal*11)/100
	const formattedCurrency = new Intl.NumberFormat('id-ID', {
				  style: 'currency',
				  currency: 'IDR',
				  minimumFractionDigits: 0,
				}).format(hasilakhir);
				
				return formattedCurrency
}

const grandtot=(total)=>{
	let vat=(total*11)/100
	let grand=total+vat
		const formattedCurrency = new Intl.NumberFormat('id-ID', {
				  style: 'currency',
				  currency: 'IDR',
				  minimumFractionDigits: 0,
				}).format(grand);
				
				return formattedCurrency
}

const updateNohp=()=>{
	//console.warn('data nohp',nohp2)
	if(nohp2===null){
		const requesthp={
			emailuser:email2,
			nama:nama2,
			nohpupdt:nohp,
		}
			  const editnohp = axios.post(apiService+'/simpannohp',requesthp).then(resnohp => {
				                          // console.warn('data nohp',resnohp.data)
				                                   let hasKey = resnohp.data.hasOwnProperty('errors');
				                                   if(hasKey){
													alert(resnohp.data.errors[0].msg)
												   }else{
													   setNohp2(resnohp.data.nohpfix)
												       setmodal_TELP(false)
												   }
												   
												})
	}
}


 const simpanCompany=()=>{
	  const requestCompany={
		  emailuser:email2,
          nama:nama2,
		  companyuser:company
	  }
	  const responseCompanysave = axios.post(apiService+'/simpancompanyuser',requestCompany).then(rescompany => {
												   setCompany2(rescompany.data.companyuserfix)
												      setmodal_center(false)
												})
  }

 const simpanEmail=()=>{
	   const requestEmail={
			emailuser:email,
			nama:nama2,
	   }
	   const responseCompanysave = axios.post(apiService+'/simpanemail',requestEmail).then(resemail => {
			setEmail2(resemail.data.emailuserfix)
			setModal_email(false)
	   })
 }
 
  const boxStyle = {
    width: '70px', // Sesuaikan lebar kotak sesuai kebutuhan
    border: '1px solid #ccc', // Contoh batas kotak
   // Contoh padding kotak
    wordWrap: 'break-word', // Properti untuk memastikan teks di dalam kotak dapat wrap sesuai lebar kotak
  };
    const boxStyleorder = {
    width: '400px', // Sesuaikan lebar kotak sesuai kebutuhan
    //border: '1px solid #ccc', Contoh batas kotak
	fontSize:'12px',
   // Contoh padding kotak
    wordWrap: 'break-word', // Properti untuk memastikan teks di dalam kotak dapat wrap sesuai lebar kotak
  };

  const dotsStyle = {

    position: 'absolute',
    top: '25%',
    left: '10px',
    transform: 'translateY(-50%)',
    fontSize: '24px', // Sesuaikan ukuran font sesuai kebutuhan
    color: '#778899', // Sesuaikan warna sesuai kebutuhan
	cursor:'pointer',
  };
  
  const popupDetil={	  
	   animationName: 'DropDownSlide',
  animationDuration: '.3s',
  animationFillMode: 'both',
  backgroundColor:'#fff',
  marginTop: '10px',
    padding:'4px 4px 4px 4px',
	border:'solid 1px #ccc',
	width:'120px',
	fontSize:'13px',
  marginLeft: '-80px',
  borderRadius:'8px',
  marginTop:'30px',
  position: 'absolute',
  zIndex: '1000'
  }


const lingkaran= {
  width: '25px',
  marginLeft:windowSize.widht<1200?'15px':'35px',
  marginTop:'-10px',
  height: '25px',
  borderRadius: '50%',
  backgroundColor: '#4caf50',
  display: 'flex',
  alignItems: 'center',
   position: 'absolute',
  justifyContent: 'center'
}

const lingkarancancel= {
  width: '25px',
  marginLeft:windowSize.widht<1200?'15px':'35px',
  marginTop:'-10px',
  height: '25px',
  borderRadius: '50%',
  backgroundColor: '#4caf50',
  display: 'flex',
  alignItems: 'center',
   position: 'absolute',
  justifyContent: 'center'
}


const centang= {
  fontSize: '14px',
  color: '#fff',
  fontWeight: 'bold'
}
  useEffect(() => {
   // This code will run only once when the component mounts
//   console.log('Component mounted');
		if(location.state){
		 const dataparam={
		   nama : location.state.usernam
		 }
		  const caridata =  axios.post(apiService+'/caridatauser',dataparam).then(resdatauser => {
			//  alert(resdatauser.data[0].Alamat)
				  setNama2(resdatauser.data[0].Nama)
				  setAlamat2(resdatauser.data[0].Alamat)
				  setEmail2(resdatauser.data[0].email)
				  setNohp2(resdatauser.data[0].NoHp)
				  setCompany2(resdatauser.data[0].Company)
				//  console.warn('data userrr',resdatauser.data[0])
		   })
		}else{
			 history.push("/login");
             setLoginData(null)
		}
  
 }, []);
 
 
// Custom comparator function for sorting
  const compareDates = (a, b) => {
    return a.ordertgl - b.ordertgl;
    // To sort in descending order, use: return b.date - a.date;
  };
  

 
 const detilTransaksibooking=(currdetiltransaksi)=>{
	 setDescjam([])
//console.warn('data detiiiiilllllll',currdetiltransaksi)
const datafix=[]
//const jsonArray = Object.values(currdetiltransaksi);
 Object.keys(currdetiltransaksi.detilorder).forEach(interval => {
	// console.warn('tesss',jsonArray)
		  datafix.push(
			currdetiltransaksi.detilorder[interval]
			 // console.warn('okkkkkk',currdetiltransaksi.detilorder[interval])
		  )
  //  setDescjam(prevArray => [...prevArray, currdetiltransaksi.detilorder[interval]]);
  })
  const sortedData = datafix.slice().sort(compareDates);
  
 /*   const uniqueIds = new Set();

  // Filter out duplicates
    const mergedArray = sortedData.filter((item) => {
    if (!uniqueIds.has(item.ordertgl)) {
      uniqueIds.add(item.ordertgl)
      return true;
    }
    return false;
  })*/
  
  const datasort=[]
  sortedData.map((itemsort,index)=>{
	 
	   if(index===0){
		   		datasort.push({jamakhir:itemsort.jamakhir,jamawal:itemsort.jamawal,namalap:itemsort.namalap,ordertgl:itemsort.ordertgl,jumlahbayar:itemsort.jumlahbayar})
	   }else{		    
			// console.warn('cekkkkkkkkk data 2',sortedData[index-1].ordertgl)
			//  console.warn('cekkkkkkkkk data',sortedData[index].ordertgl)
			if(sortedData[index].ordertgl===sortedData[index-1].ordertgl){
				if(sortedData[index].namalap===sortedData[index-1].namalap){
					datasort.push({jamakhir:itemsort.jamakhir,jamawal:itemsort.jamawal,jumlahbayar:itemsort.jumlahbayar})
				}else{
					datasort.push({jamakhir:itemsort.jamakhir,jamawal:itemsort.jamawal,namalap:itemsort.namalap,jumlahbayar:itemsort.jumlahbayar})
				}
				
			}else{
				datasort.push({jamakhir:itemsort.jamakhir,jamawal:itemsort.jamawal,namalap:itemsort.namalap,ordertgl:itemsort.ordertgl,jumlahbayar:itemsort.jumlahbayar})
			}
		   
	   }
	
  })
 // console.warn('setelah di sortir///////',datasort)
	setDescjam(datasort);
  // console.warn('data detail order==',sortedData)
//let datab={status:jsonArray[0],tgltransaksi:jsonArray[1]}
//setDescjam(prevArray => [...prevArray, currdetiltransaksi.detilorder]);
   //  setCurrentdetil(jsonArray)
   setCurrentdetil({
	   ...currentdetil,
	   status:currdetiltransaksi.status,
	   idpenyewa:currdetiltransaksi.idpenyewa,
	   tgltransaksi:currdetiltransaksi.tgltransaksi,
	   idpembayaran:currdetiltransaksi.idpembayaran,
	   jumlahbayar:currdetiltransaksi.jumlahbayar,
	   invno:currdetiltransaksi.invno[0],
       detilclient:currdetiltransaksi.detilpenyewa,
	   detilorder:currdetiltransaksi.detilorder
   })
	 setmodal_detiltransaksi(true)
 }
 
  const kembaliOrder=()=>{
    history.push("/");
	//const jalurkosong={...location,state:undefined}
	//history.replace(jalurkosong)
  }
  
  
  const detilpembayaranBooking=(itembayar)=>{
	  console.warn('Data bayar......',itembayar)
	 setDescbayar([])
	const datafixpembayaran=[]
	Object.keys(itembayar.detilorder).forEach(interval => {
	  datafixpembayaran.push(
		itembayar.detilorder[interval]		
	  )
	})
	const sortedDatapembayaran = datafixpembayaran.slice().sort(compareDates);
	
	 const datasort=[]
  sortedDatapembayaran.map((itemsort,index)=>{
	 
	   if(index===0){
		   		datasort.push({jamakhir:itemsort.jamakhir,jamawal:itemsort.jamawal,namalap:itemsort.namalap,ordertgl:itemsort.ordertgl,jumlahbayar:itemsort.jumlahbayar})
	   }else{		
        	 if(sortedDatapembayaran[index].ordertgl===sortedDatapembayaran[index-1].ordertgl){
				if(sortedDatapembayaran[index].namalap===sortedDatapembayaran[index-1].namalap){
					datasort.push({jamakhir:itemsort.jamakhir,jamawal:itemsort.jamawal,jumlahbayar:itemsort.jumlahbayar})
				}else{
					datasort.push({jamakhir:itemsort.jamakhir,jamawal:itemsort.jamawal,namalap:itemsort.namalap,jumlahbayar:itemsort.jumlahbayar})
				}				
			}else{
				datasort.push({jamakhir:itemsort.jamakhir,jamawal:itemsort.jamawal,namalap:itemsort.namalap,ordertgl:itemsort.ordertgl,jumlahbayar:itemsort.jumlahbayar})
			}		   
	   }
	
  })
  setDescbayar(datasort)	
	setCurrentbayar({
	   ...currentbayar,
	   status:itembayar.status,
	   idpenyewa:itembayar.idpenyewa,
	   tgltransaksi:itembayar.tgltransaksi,
	   idpembayaran:itembayar.idpembayaran,
	   jumlahbayar:itembayar.jumlahbayar,
       akunbank:itembayar.akunbank,
	   detilorder:itembayar.detilorder
   })	
	  setModal_detpembayaran(true)
  }
 
 
 const divInvoice=(datadetil)=>{	
	  datadetil.detilclient.map(iteminv=>{
			setCurrentinvoice({
				...currentinvoice,
				tgltransaksi:datadetil.tgltransaksi,
				invno:datadetil.invno,
				namapel:iteminv.nama,
				alamatpel:iteminv.alamat,
				hppel:iteminv.hp,
				emailpel:iteminv.mail,
				perusahaanpel:iteminv.prusahaan,
				  dataorder:datadetil.detilorder	
			})
	  })
	
	const datadescinv=[];
	setDescinv([])
	let totbiaya222=0
    datadetil.detilorder.map(itemorder=>{
		
		let pecah1=itemorder.jamawal.split(':');
		let pecah2=itemorder.jamakhir.split(':');
		let jam=Number(pecah2[0])-Number(pecah1[0])
		let total0=jam*125000;
		let total=parseFloat2Decimals(total0);
		totbiaya222 = total+totbiaya222;
		datadescinv.push({
			                  jamawal:pecah1[0],
							   jamakhir:pecah2[0],
							   namalap:itemorder.namalap,
							   ordertgl:itemorder.ordertgl,
							   jumlahbayar:itemorder.jumlahbayar
		})
	 })
	 
	 
	 //console.warn('Dataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa',totbiaya222)
	 setTotbayarinv(totbiaya222);
	 setDescinv(datadescinv)
	 setModal_pageinvoice(true)
 }
  
  	function parseFloat2Decimals(value) {
    return parseInt(value)
    }
  
   const openpopUp = (itemId) => {
    setIspopup(itemId === ispopup ? null : itemId);
  };
 
  const convertToUppercase = (inputString) => {
    return inputString.toUpperCase();
   };
     const convertToUppercase2 = (inputString) => {
    return inputString.toUpperCase();
   };
   
     const formatAsRupiah = (number) => {
    return new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
    }).format(number);
  };
  
  const potongString=(varjam)=>{
	const datajam= varjam.split(':')  
	const hasilsplit= datajam[0]+':'+datajam[1]
	
	return hasilsplit
  }
  
    const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIspopup(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
//const [opsver,setOpsver]=useState(location.state.jnsver==='mobile')
  useEffect(()=>{
	/*  if(location.state.alamatuser===''){
		  setAlamat2('')
	  }else{
		    setAlamat2(location.state.alamatuser)
	  }

	  if(location.state.companyuser===''){
		  setCompany2('')
	  }else{
		  setCompany2(location.state.companyuser)
	  }*/
  })
  
   
  
  
  const AccordionItem = styled.div`
  margin-bottom: 10px;

`;

const AccordionHeader = styled.div`
  background-color: #ffffff; 
  border-radius:10px;
  color: #000;
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  cursor: pointer;
`;

const AccordionContent = styled.div`
  overflow: hidden;
  transition: max-height 0.3s ease;
  
  max-height: ${({ isOpen }) => (isOpen ? '500px' : '0')};
`;


const SubMenu = styled.ul`
  margin-left: 20px;
  margin-top:7px;
  padding: 0;
  color:#000;
  list-style-type: none;
`;
  
  const navMenumobile=()=>{
	  return(
		  <div className="row" style={{border:'solid 1px #ddd'}}>
			  <span>menu mobile</span>
		  </div>
	  )
  }
  
  const Accordion = () => {
  /*const [items, setItems] = useState([
    { id: 1, title: 'Item 1', content: 'Content for item 1', isOpen: false },
    { id: 2, title: 'Item 2', content: 'Content for item 2', isOpen: false },   
  ]);*/
    const [items, setItems] = useState([
    {
      id: 1,
      title: 'Pesanan',
      content: [
        { id: 4, title: 'Menunggu pembayaran', content: 'Content for subitem 1' },
        { id: 5, title: 'Daftar transaksi', content: 'Content for subitem 2' },
      
      ],
    },
    {
      id: 2,
      title: 'Profil saya',
        content: [
        { id: 9, title: 'Biodata diri', content: 'Content for subitem 6' },        
      ],
    },
   /* {
      id: 3,
      title: 'Item 3',
      content: [
        { id: 7, title: 'Subitem 4', content: 'Content for subitem 4' },
        { id: 8, title: 'Subitem 5', content: 'Content for subitem 5' },
      ],
    },*/
  ]);
  /*   const [items, setItems] = useState([
    {
      id: 1,
      title: 'Item 1',
      content: ['Subitem 1', 'Subitem 2', 'Subitem 3'],
    },
    {
      id: 2,
      title: 'Item 2',
      content: 'Content for item 2',
    },
    {
      id: 3,
      title: 'Item 3',
      content: ['Subitem 4', 'Subitem 5'],
    },
  ]);

 const toggleItem = (id) => {
    setItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id ? { ...item, isOpen: !item.isOpen } : {...item, isOpen:false }
      )
    );
  };*/
  // const [openItems, setOpenItems] = useState(null);

 /* const toggleItem = (id) => {
    setOpenItems((prevOpenItems) =>
      prevOpenItems.includes(id)
        ? prevOpenItems.filter((item) => item !== id)
        : [...prevOpenItems, id]
    );
  };
  
    const renderSubMenu = (submenuItems) => (
    <SubMenu>
      {submenuItems.map((subitem) => (
        <li key={subitem.id}>{subitem}</li>
      ))}
    </SubMenu>
  );
  */

 /*    const toggleItem = (id) => {
		setOpenItems((prevOpenItems) => {
      if (prevOpenItems.includes(id)) {
        return prevOpenItems.filter((item) => item !== id);
      } else {
        return [...prevOpenItems, id];
      }
    });
  };*/
  
/*  const toggleItem = (id) => {
		setOpenItems((prevOpenItems) => 
      prevOpenItems === id ? null:id
    );
  };

  const toggleSubItem = (subItemId) => {
	  
    setOpenItems([subItemId]);
  };
  
  const renderSubMenu = (submenuItems) => (
    <SubMenu  >
      {submenuItems.map((subitem) => (
        <li style={{fontSize:'12px',color:'#000'}} key={subitem.id} onClick={() => toggleSubItem(subitem.id)}>
          {subitem.title}
        </li>
      ))}
    </SubMenu>
  );



  return (
    <div className="row" style={{padding:'25px 25px 15px 0px',borderRadius:'10px',backgroundColor:'#ffffff',border:'solid 1px #ebecf1'}}>
	  <div className="row" style={{marginTop:'10px'}}>
	     <span style={{fontsize:'18px',color:'#000',fontWeight:'550'}}>User profil</span>
	  </div>
	  <div className="row" style={{marginTop:'10px'}}>
		  {items.map((item) => (
			<AccordionItem key={item.id}>
			  <AccordionHeader onClick={() => toggleItem(item.id)}>
				<span style={{fontSize:'13px'}}>{item.title}</span>
			  </AccordionHeader>
			  <AccordionContent style={{fontSize:'12px',color:'#000'}} isOpen={openItems.includes(item.id)}>
				 {Array.isArray(item.content) ? (
				  renderSubMenu(item.content)
				) : (
				  <span style={{fontSize:'12px',color:'#000',marginLeft:'20px', marginTop:'7px'}}>{item.content}</span>
				)}
			  </AccordionContent>
			</AccordionItem>
		  ))}
	  </div>
    </div>
  );
};*/

 const [openItemId, setOpenItemId] = useState(null);

  const toggleItem = (id) => {
    setOpenItemId((prevOpenItemId) =>
      prevOpenItemId === id ? null : id
    );
  };

  const toggleSubItem = (subItemId) => {
	 // console.warn('panel aktifff',subItemId)
	 if(subItemId===4){
		detilPembayaran()
	 }else if(subItemId===5){
		 detilTransaksi()
	 }else if(subItemId===9){
		 detilBiodata()
	 }
    setOpenItemId(subItemId);
	
  };

  const renderSubMenu = (submenuItems) => (
    <SubMenu>
      {submenuItems.map((subitem) => (
        <li style={{marginTop:'5px',marginBottom:'5px'}} key={subitem.id} onClick={() => toggleSubItem(subitem.id)}>
          {subitem.title}
        </li>
      ))}
    </SubMenu>
  );
  //#E7F6FF HIJAU PASTEL
  return (
    <div className="row" style={{padding:'25px 25px 15px 0px',borderRadius:'10px',backgroundColor:'#FFFFFF',border:'solid 1px #ebecf1'}}>
	  <div className="row" style={{marginTop:'0px'}}>
	     <span style={{fontSize:'22px',color:'#000',fontWeight:'550'}}>Data user</span>
	  </div>
	  <div className="row" style={{marginTop:'10px'}}>
      {items.map((item) => (
        <AccordionItem key={item.id}>
          <AccordionHeader onClick={() => toggleItem(item.id)}>
            <span style={{fontSize:'13px',marginLeft:'15px'}}>{item.title}</span>
          </AccordionHeader>
          <AccordionContent style={{fontSize:'12px',color:'#000'}} isOpen={openItemId === item.id}>
            {Array.isArray(item.content) ? (
              renderSubMenu(item.content)
            ) : (
              <span style={{fontSize:'12px',color:'#000',marginLeft:'20px', marginTop:'7px'}}>{item.content}</span>
            )}
          </AccordionContent>
        </AccordionItem>
      ))}
	  </div>
    </div>
  );
};



const componentRef = useRef();
const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  
  const coba=(currentinvoice,descinv,totbayarinv)=>{

	                            	history.push({
									 pathname:"/tespage",
									 state :{currentinvoice1:currentinvoice,descinv1:descinv,totbayarinv1:totbayarinv}
									});
  }
  
  const tutupInvoice=()=>{
	    setModal_pageinvoice(false)
		   setmodal_detiltransaksi(false)
  }
//console.warn('lebarrrrrrrrrrrrrrrrrrrrrrr', windowSize.width)
  return (
       <>
            <section className="shop-details-area pt-100 pb-100">
                <div className={windowSize.width < 995?"container-lg":"container" } >
                    <div className="row" style={{padding:'0px'}}>
                        <div className="col-md-3" style={{padding:'0px'}}>
						{						
						    windowSize.width < 550 ? (
							 <Accordion />
							):(
								<div className="blog-sidebar">
									{/*<aside className="widget widget-search">
									<form className="search-form" action="#" method="post">
										<input type="search" name="s" placeholder="Search..." />
										<button type="submit">
											<i className="fal fa-search"></i>
										</button>
									</form>
								  </aside>*/}

									<aside style={{width:'100%',padding:windowSize.width <1200?'30px 10px 10px 5px':'30px 10px 10px 30px'}} className="widget widget-categories">
											 <h3 className="widget-title">Data User</h3>
											 <div className="collapse-menu" >
												<ul >
												<li>
													<a className="toggle-button"  onClick={toggleCollapse}>Pesanan</a>
														{/*<span>(24)</span>*/}
													{!collapsed && (
													 <ul className="sub-menu"  >
														<li style={{cursor:'pointer'}} onClick={()=>detilPembayaran()}>
															<span style={{fontSize:windowSize.width <1200?'12px':'13px',color:'#404656',marginLeft:windowSize.width <1200?'0px':''}}>Menunggu pembayaran</span>
														</li>
														<li style={{cursor:'pointer'}} onClick={()=>detilTransaksi()}>
														    <span style={{fontSize:windowSize.width <1200?'12px':'13px',color:'#404656',marginLeft:windowSize.width <1200?'0px':''}}>Daftar transaksi</span>
															
														</li>
													 </ul>
													)}
												</li>
													<li>
													<a  className="toggle-button" onClick={toggleCollapse1}>Profil saya</a>
														{/*<span>(24)</span>*/}
													{!collapsed1 && (
													 <ul className="sub-menu" >
														<li style={{cursor:'pointer'}} onClick={()=>detilBiodata()}>
														<span style={{fontSize:windowSize.width <1200?'12px':'13px',color:'#404656',marginLeft:windowSize.width <1200?'0px':''}}>Biodata diri</span>
														  
														</li>
													 </ul>
													)}
												</li>
												</ul>
										  </div>						
									</aside>
								</div>							
							)
						}
							
								
					
						
                   </div>
    {panel === 'pembayaran' ? (
	   windowSize.width < 550 ? (
	        <div className="col-md-8" style={{borderRadius:'10px',marginTop:'10px',padding:'0px'}}>
	          <DatalistBayar namauser={nama2} datapembayaran={bayardata} />
		    </div>
	   ):(
			<div className="col-md-9">
				  <div onClick={()=>kembali()}  color="primary" style={{width:'110px',cursor:'pointer',height:'10px',position:'absolute',marginLeft:'-3px',zIndex:'4',borderTop:'20px solid #555',borderRight:' 20px solid transparent',borderBottom:' 20px solid #555'}}>
						<span style={{color:'#fff',position:'absolute',marginTop:'-12px',marginLeft:'10px'}}>Kembali</span>
					</div>
				  <div style={{paddingTop:'60px'}} className="contact-form">
						<h4>Order yang harus di bayar</h4>
						<ol role="list">
						  { bayardata.map(itembayar => {
							  return(
								   <li className="listOrderpembayaran">
									   {/*<h3>Discovery and assessment</h3>
									   <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Adipiscing diam donec adipiscing tristique risus.</p>*/}
									   <div style={{
										   width:'100%',
										   border:'solid 1px #ccc',
										   padding:'4px',
										   boxShadow: 'rgba(0, 0, 0, 0.7) 0px 1px 3px',
											borderRadius: '4px',
											marginTop:'25px',
										   height:'100%'
									   }}>
										   <div className="row" style={{height:'40px'}}>
											   <div  className="text-muted col-sm-4"><span style={{fontSize:'14px', marginLeft:'10px',fontWeight:'700'}}>Order</span> <span style={{fontSize:'12px'}}>{tglOrder(itembayar.tgltransaksi)}</span></div>
											 
											   <div className="col-sm-4"></div>
											   <div className="text-muted  col-sm-4"><span style={{fontSize:'12px'}}>Bayar sebelum</span><span style={{fontSize:'12px',fontWeight:'700',marginLeft:'6px'}}>{tglExp(itembayar.expiretime)}</span></div>
										   </div>
										   <div className="row" style={{paddingBottom:'5px'}}>
											   <div className="text-muted col-sm-2" style={boxStyle} ><p style={{fontWeight:'500',fontSize:'13px'}}>{convertToUppercase(itembayar.akunbank)}</p></div>
											   <div className="text-muted col-sm-3" >
												   <div className="row text-muted"><span style={{fontSize:'12px'}}>Metode pembayaran</span></div>
												   <div className="row" style={{height:'20px'}}><span style={{fontSize:'17px',color:'#000'}}>{convertToUppercase(itembayar.akunbank)}</span></div>
											   </div>								   
											   <div className="text-muted col-sm-4" style={{borderLeft:'solid 1px #ccc',height:'45px'}}>
												   <div className="row text-muted"><span style={{fontSize:'12px'}}>Nomor Virtual Account</span></div>
												   <div className="row text-muted"  style={{height:'20px'}}><span style={{fontSize:'17px',color:'#000'}}>{itembayar.kodegatepay}</span></div>
											   </div>
											   <div className="text-muted col-sm-3" style={{borderLeft:'solid 1px #ccc',height:'45px'}}>
												   <div className="row text-muted"><span style={{fontSize:'12px'}}>Total Pembayaran</span></div>
												   <div className="row text-muted"  style={{height:'20px'}}><span style={{fontSize:'17px',color:'#000'}}>{formatAsRupiah(itembayar.jumlahbayar)}</span></div>
											   </div>
										   </div>
										   <div className="row" style={{height:'40px'}}>
											   <div className="text-muted col-sm-5" ><p></p></div>
											   <div onClick={()=>detilpembayaranBooking(itembayar)} className="text-muted col-sm-3 text-center" style={{padding:'0px 5px 0px 0px'}}  >
												  <div style={{width:'100%',height:'35px',border:'solid 1px #ccc',borderRadius:'4px',paddingTop:'5px',cursor:'pointer'}}><span style={{fontSize:'12px',fontWeight:'800'}}>Lihat detail</span></div>
											   </div>
											   <div className="text-muted col-sm-3 text-center" style={{padding:'0px 0px 0px 5px'}} >
												  <div style={{width:'100%',height:'35px',border:'solid 1px #ccc',borderRadius:'4px',paddingTop:'5px',cursor:'pointer' }}><span style={{fontSize:'12px',fontWeight:'800'}}>Cara pembyaran</span></div>
											   </div>
												  <div className="text-muted col-sm-1 text-center" style={{padding:'0px 0px 0px 5px'}} >
												   <span style={dotsStyle}>&hellip;</span>
												  </div>
										   </div>
									   </div>
								   </li>
							  )				  
						  })}     
					</ol>		     
				  </div>
			</div>
		)
    ):panel==='transaksi'? (
	    windowSize.width < 550 ?(
		     <div className="col-md-8" style={{borderRadius:'10px',marginTop:'10px',padding:'0px'}}>
			     <Datalisttransaksi namauser={nama2} emailuser={email2} datatransaksi={transaksidata}/>
			 </div>
		):(
			<div className="col-md-9">
					<div onClick={()=>kembali()}  color="primary" style={{width:'110px',cursor:'pointer',height:'10px',position:'absolute',marginLeft:'-3px',zIndex:'4',borderTop:'20px solid #555',borderRight:' 20px solid transparent',borderBottom:' 20px solid #555'}}>
								<span style={{color:'#fff',position:'absolute',marginTop:'-12px',marginLeft:'10px'}}>Kembali</span>
				    </div>
				  
				  {windowSize.width <1200?(
				      
					          <div style={{paddingTop:'60px',paddingLeft:'20px',paddingRight:'15px'}} className="contact-form">							  
							      <h4>Daftar Transaksi</h4>
								    { transaksidata.map((itemtransaksi,index) => {
										 return(
												<Grid className="p-0" style={{															
												   border:'solid 1px #ccc',										 
												   boxShadow: 'rgba(0, 0, 0, 0.7) 0px 1px 3px',
												   borderRadius: '4px',
												   marginTop:'25px'}}
												>									
													  <GridRow className="pt-0 pl-2 mt-1"  >
													       <GridColumn className="p-0"  width={4}>
													       {															   
															windowSize.widht<860?(	<span style={{fontSize:'11px',paddingLeft:'2px'}}>icon </span>)	:null								  
														   }
														   <span style={{fontSize:windowSize.widht<1200?'13px':'14px',marginLeft:'3px',fontWeight:'700'}}>Booking Lapangan</span>
														  </GridColumn>										  
														  <GridColumn className="p-0" width={3}>						
															  <span style={{fontSize:windowSize.widht<1200?'11px':'13px'}}>{tglOrdertransaksi(itemtransaksi.tgltransaksi)}</span>	
														  </GridColumn>
														  {
															  windowSize.widht<1200?(
															     <GridColumn className="p-0" width={1}>	
														   	     </GridColumn>
															  ):null
														  }
														
														    <GridColumn className="p-0" width={8}>	
														    	<span style={{fontSize:'12px',color:itemtransaksi.status === 'success'?'#008000':'#ccc',backgroundColor:itemtransaksi.status === 'success'?'#7fff00':''}}>{itemtransaksi.status}</span><span style={{fontSize:windowSize.widht<1200?'12px':'13px',marginLeft:'3px'}}>{itemtransaksi.invno}</span>
															</GridColumn>
															 <GridColumn className="p-0" width={1}>	
															                 <div style={{
																				width: '25px',
																				marginLeft:windowSize.widht<1200?'35px':'19px',
																				marginTop:'-10px',
																				height: '25px',
																				borderRadius: '50%',
																				backgroundColor:itemtransaksi.status === 'success'? '#4caf50':'red',
																				display: 'flex',
																				alignItems: 'center',
																				position: 'absolute',
																				justifyContent: 'center'																				 
																			 }}>
																			 {itemtransaksi.status === 'success'?(
																			      <div style={centang}>&#10003;</div>
																			 ):(
																			       <div style={centang}>&#128473;</div>
																			 )}
																			
																			  
																			</div>
														   	  </GridColumn>
													</GridRow>
													<GridRow className="pt-0 pl-2 mt-1"  >
													     <GridColumn className="p-0" width={11}>	
														     {  itemtransaksi.detilorder.map(itemdetil=>{
																				 return (
																				 <>
																				 <span style={{fontWeight:'600'}}>{itemdetil.namalap}</span><span style={{marginLeft:'5px',marginRight:'5px'}}>{'Jam '+potongString(itemdetil.jamawal)+' - '+potongString(itemdetil.jamakhir)}</span>
																				 </>
																				 )
																			  })													      
																		   }
														 </GridColumn>
														 <GridColumn   width={4} style={{borderLeft:'solid 1px #ccc'}}>
														   <div className="row text-muted "><span style={{fontSize:'12px'}}>Total bayar</span></div>
														   <div className="row text-muted "><span style={{fontSize:'15px',color:'#000'}}>{formatAsRupiah(itemtransaksi.jumlahbayar)}</span></div>
														 </GridColumn>														
													</GridRow>
													 <GridRow className="pt-0 pl-2 mt-1" style={{height:'40px', marginTop:'8px'}}>
														     <GridColumn   width={4} ></GridColumn>
															  <GridColumn width={5} style={{padding:'0px 0px 0px 0px'}}  >
																	  <div className="text-center" onClick={()=>detilTransaksibooking(itemtransaksi)} style={{width:'100%',height:'35px',border:'solid 1px #ccc',borderRadius:'4px',paddingTop:'5px',cursor:'pointer',paddingLeft:'5px',paddingRight:'5px'}}><span style={{fontSize:'12px',fontWeight:'800'}}>Lihat detail Transaksi</span></div>
															  </GridColumn>
															   <GridColumn onClick={()=>kembaliOrder()} width={4} style={{padding:'0px 0px 0px 0px'}} >
																	  <div className="text-center" style={{width:'100%',height:'35px',border:'solid 1px #ccc',borderRadius:'4px',paddingTop:'5px',cursor:'pointer' }}><span style={{fontSize:'12px',fontWeight:'800'}}>Order lapangan lagi</span></div>
															   </GridColumn>
															   <GridColumn onClick={()=>openpopUp(index)} width={2}>
															     <span className="text-center" style={dotsStyle}>&hellip;</span>
																	   {ispopup === index && (
																		  <div ref={popupRef}  style={popupDetil}>tes popup</div>
																		)
																	   }
															   </GridColumn>
														 </GridRow>
													
												</Grid>
										   )
										})
									}									
							  </div>
					   				  
				  ):(
				  
				      					      
						     <div style={{paddingTop:'60px'}} className="contact-form">
									<h4>Daftar Transaksi</h4>
									<ol role="list">
									   { transaksidata.map((itemtransaksi,index) => {
										   return(
												   <li className="listOrderpembayaran">					 
															<div style={{
															   width:'100%',
															   border:'solid 1px #ccc',
															   padding:'4px',
															   boxShadow: 'rgba(0, 0, 0, 0.7) 0px 1px 3px',
															   borderRadius: '4px',
															   marginTop:'25px',
															   height:'100%'
															}}>						   
															   <div className="row" style={{height:'40px'}}>
																   <div  className="text-muted col-sm-3" style={{padding:'0px 0px 0px 4px'}}><span style={{fontSize:'13px'}}>icon</span><span style={{fontSize:'16px',marginLeft:'7px',fontWeight:'700'}}>Booking Lapangan</span></div>											 
																   <div className="text-muted text-left  col-sm-2" style={{padding:'0px'}}><span style={{fontSize:'13px'}}>{tglOrdertransaksi(itemtransaksi.tgltransaksi)}</span></div>
																   <div className="text-muted  col-sm-6" style={{padding:'0px'}}><span style={{fontSize:'12px',color:itemtransaksi.status === 'success'?'#008000':'#ccc',color:itemtransaksi.status === 'success'? '#4caf50':'red'}}>{itemtransaksi.status}</span><span style={{fontSize:'13px',marginLeft:'7px'}}>{itemtransaksi.invno}</span></div>
																   <div className="text-muted  col-sm-1 text-right" >
																			<div style={{width: '25px',
																				marginLeft:windowSize.widht<1200?'35px':'19px',
																				marginTop:'-10px',
																				height: '25px',
																				borderRadius: '50%',
																				backgroundColor:itemtransaksi.status === 'success'? '#4caf50':'red',
																				display: 'flex',
																				alignItems: 'center',
																				position: 'absolute',
																			justifyContent: 'center'}}>
																			 
																			     {itemtransaksi.status === 'success'?(
																			          <div style={centang}>&#10003;</div>
																				 ):(
																					   <div style={centang}>&#128473;</div>
																				 )}
																			
																			</div>
																   </div>
																</div>
															   <div className="row" >
																   <div className="col-sm-9 text-muted " >
																		<div className="row-sm-9" style={boxStyleorder}>
																		   {  itemtransaksi.detilorder.map(itemdetil=>{
																				 return (
																				 <>
																				 <span style={{fontWeight:'600'}}>{itemdetil.namalap}</span><span style={{marginLeft:'5px',marginRight:'5px'}}>{'Jam '+potongString(itemdetil.jamawal)+' - '+potongString(itemdetil.jamakhir)}</span>
																				 </>
																				 )
																			  })													      
																		   }
																		 </div>
																		 <div className="row-sm-9 text-muted "><span style={{fontSize:'13px'}}>{itemtransaksi.idpenyewa}</span></div>
																   </div>
																   <div className="col-sm-3" style={{borderLeft:'solid 1px #ccc'}}>
																		 <div className="row text-muted "><span style={{fontSize:'12px'}}>Total bayar</span></div>
																		 <div className="row text-muted "><span style={{fontSize:'15px',color:'#000'}}>{formatAsRupiah(itemtransaksi.jumlahbayar)}</span></div>
																   </div>
																</div>
																<div className="row" style={{height:'40px', marginTop:'8px'}}>
																   <div className="text-muted col-sm-5" ><p></p></div>
																   <div className="text-muted col-sm-3 text-center" style={{padding:'0px 5px 0px 0px'}}  >
																	  <div onClick={()=>detilTransaksibooking(itemtransaksi)} style={{width:'100%',height:'35px',border:'solid 1px #ccc',borderRadius:'4px',paddingTop:'5px',cursor:'pointer'}}><span style={{fontSize:'12px',fontWeight:'800'}}>Lihat detail Transaksi</span></div>
																   </div>
																   <div onClick={()=>kembaliOrder()}  className="text-muted col-sm-3 text-center" style={{padding:'0px 0px 0px 5px'}} >
																	  <div style={{width:'100%',height:'35px',border:'solid 1px #ccc',borderRadius:'4px',paddingTop:'5px',cursor:'pointer' }}><span style={{fontSize:'12px',fontWeight:'800'}}>Order lapangan lagi</span></div>
																   </div>
																   <div onClick={()=>openpopUp(index)} className="text-muted col-sm-1 text-center" style={{padding:'0px 0px 0px 5px'}} >
																	  <span style={dotsStyle}>&hellip;</span>
																	   {ispopup === index && (
																		  <div ref={popupRef}  style={popupDetil}>tes popup</div>
																		)
																	   }
																   </div>
																  
																  
																 </div>									   
																									   
														   </div>
												   </li>
												 )	   
										   }) 
										}
									</ol>						
				              </div>				  
				  
				  )}
				 
				  
				  
				  
				  
				  
			</div>
		)
    ):(
	            windowSize.width < 550 ? (
				      <div className="col-md-8" style={{borderRadius:'10px',marginTop:'10px',padding:'0px'}}>
					     <Formdatapribadi namauser={nama2} alamatuser={alamat2} emailuser={email2} nouser={nohp2} companyuser={company2} />
					  </div>
				  ):(
                        <div className="col-md-8">
						  <div onClick={()=>kembali()}  color="primary" style={{width:'110px',cursor:'pointer',height:'10px',position:'absolute',marginLeft:'-3px',zIndex:'4',borderTop:'20px solid #555',borderRight:' 20px solid transparent',borderBottom:' 20px solid #555'}}>
							<span style={{color:'#fff',position:'absolute',marginTop:'-12px',marginLeft:'10px'}}>Kembali</span>
						  </div>
						  <div style={{paddingTop:'60px'}} className="contact-form pt-12 text-muted">
                                <h4>Biodata Diri</h4>
                                <p>Lengkapi data pribadi anda untuk mendapatkan info terupdate dari Satria Futsal</p>
								     <Row>
									   <Col style={{fontSize:'14px',fontWeight:'600'}} md={3}>
										      Nama
										 </Col>
										 <Col style={{fontSize:'12px'}} md={7}>
											 {nama2}
										 </Col>
										 <Col style={{fontSize:'16px'}} md={2}>
										      <span className="text-danger"  style={{cursor:'pointer'}}> <Icon name='edit' size={20} /></span>
										 </Col>
									</Row>
									 <Row>
									     <Col style={{fontSize:'14px',fontWeight:'600'}} md={3}>
										      Alamat
										 </Col>
										 <Col style={{fontSize:'12px'}} md={7}>
										 {alamat2}
										 </Col>
										 <Col style={{fontSize:'16px'}} md={2}>
										      <span style={{cursor:'pointer'}} className="text-danger" onClick={()=>tog_center_ALAMAT()}> <Icon name='edit' size={20}  /></span>
										 </Col>
									</Row>
									 <Row>
									     <Col style={{fontSize:'14px',fontWeight:'600'}} md={3}>
										      Email
										 </Col>
										 <Col style={{fontSize:'12px'}} md={7}>
										 {email2}
										 </Col>
										  <Col style={{fontSize:'16px'}} md={2}>
										      <span style={{cursor:'pointer'}} className="text-danger"  onClick={()=>tog_modal_email()}  > <Icon name='edit' size={20} /></span>
										 </Col>
									</Row>
									 <Row>
									     <Col style={{fontSize:'14px',fontWeight:'600'}} md={3}>
										      Nomor Telp
										 </Col>
										 <Col style={{fontSize:'12px'}} md={7}>
										   {nohp2}
										 </Col>
										 <Col style={{fontSize:'16px'}} md={2}>
										      <span style={{cursor:'pointer'}} className="text-danger"  onClick={()=>tog_center_TELP()}> <Icon name='edit' size={20} /></span>
										 </Col>
									</Row>
									 <Row>
									   <Col style={{fontSize:'14px',fontWeight:'600'}} md={3}>
										      Company
										 </Col>
										 <Col style={{fontSize:'12px'}} md={7}>
                                            { company2 }

										 </Col>
										 <Col style={{fontSize:'16px'}} md={2}>
										     <span style={{cursor:'pointer'}} className="text-danger"  onClick={()=>tog_center()}> <Icon name='edit' size={20} /></span>
										 </Col>
									</Row>

								        {/*<form action="#" method="post" className="row">
                                    <div className="col-md-6">
                                        <input type="text" name="f-name" placeholder="First Name" />
                                    </div>
                                    <div className="col-md-6">
                                        <input type="text" name="l-name" placeholder="Last Name" />
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder="Email Address"
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            type="number"
                                            name="phone"
                                            placeholder="Phone Number"
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <input type="text" name="suject" placeholder="Subject" />
                                    </div>
                                    <div className="col-md-12">
                                        <textarea
                                            name="message"
                                            placeholder="How can we help?"
                                        ></textarea>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="condition-check">
                                            <input id="terms-conditions" type="checkbox" />
                                            <label htmlFor="terms-conditions">
                                                I agree to the <a href="#">Terms & Conditions</a>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-6 text-right">
                                        <input type="submit" name="submit" value="Send Message" />
                                    </div>
							      		</form>*/}
                            </div>
                        </div>)
                        )}



                    </div>
                </div>
            </section>
            {/*//////////////////////////////////////////FORM MODAL UBAH EMAIL//////////////////////////////////////////////////*/}

              <Modal
                      isOpen={modal_email}
                      toggle={() => {
                        tog_modal_email()
                      }}
                      centered={true}
                    >
                      <div className="modal-header">
                        <h5 className="modal-title mt-0">Form ubah Email</h5>
                        <button
                          type="button"
                          onClick={() => {
                            setModal_email(false)
                          }}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
  						 <Row>
  						    <Col xs={2}><span  style={{justifyContent: 'center',alignItems: 'center'}}>Email</span></Col>
  							<Col xs={10}>
  							    <AvForm style={{textAlign: 'center'}} >
  									  <AvField name="email" type="text"  size="sm" onChange={inputhandleemail}
  									   validate={{
  									required: {value: true, errorMessage: 'Tolong Email di isi'}
  									   }}
  									/>
                                   {/*<AvInput type="textarea" name="address" id="address" placeholder="Address" required />*/}
  								</AvForm>
  							</Col>

  						 </Row>
                      </div>
  					   <div className="modal-footer">
                        <Button type="button" onClick={()=>simpanEmail()} color="secondary" size="sm">
                          Save changes
                              </Button>
  							{/* <button
                          type="button"
                          className="btn btn-secondary"
                          data-dismiss="modal"
  						size="sm"
                        >Close
                              </button>*/}
                      </div>
                    </Modal>
			   	  {/*//////////////////////////////////////////FORM MODAL UBAH COMPANY//////////////////////////////////////////////////*/}
			      <Modal
                    isOpen={modal_center}
                    toggle={() => {
                      tog_center()
                    }}
                    centered={true}
                  >
                    <div className="modal-header">
                      <h5 className="modal-title mt-0">Center Modal</h5>
                      <button
                        type="button"
                        onClick={() => {
                          setmodal_center(false)
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
						 <Row>
						    <Col xs={2}><span  style={{justifyContent: 'center',alignItems: 'center'}}>Company</span></Col>
							<Col xs={10}>
							    <AvForm style={{textAlign: 'center'}} >
									  <AvField name="company" type="text"  size="sm" onChange={inputhandlerCompany}
									   validate={{
									required: {value: true, errorMessage: 'Tolong Company di isi'}
									   }}
									/>
                                 {/*<AvInput type="textarea" name="address" id="address" placeholder="Address" required />*/}
								</AvForm>
							</Col>

						 </Row>
                    </div>
					   <div className="modal-footer">
                      <Button type="button" onClick={()=>simpanCompany()} color="secondary" size="sm">
                        Save changes
                            </Button>
							{/* <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
						size="sm"
                      >Close
                            </button>*/}
                    </div>
                  </Modal>
				  {/*//////////////////////////////////////////FORM MODAL UBAH ALAMAT//////////////////////////////////////////////////*/}
				   <Modal
                    isOpen={modal_ALAMAT}
                    toggle={() => {
                      tog_center_ALAMAT()
                    }}
                    centered={true}
                  >
                    <div className="modal-header">
                      <h5 className="modal-title mt-0">Center Modal</h5>
                      <button
                        type="button"
                        onClick={() => {
                          setmodal_ALAMAT(false)
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
						 <Row>
						    <Col xs={2}><span  style={{justifyContent: 'center',alignItems: 'center'}}>Alamat</span></Col>
							<Col xs={10}>
							    <AvForm style={{textAlign: 'center'}} >

                                 <AvInput type="textarea" onChange={inputHandlerAlamat} name="address" id="address" placeholder="Address" required />
								</AvForm>
							</Col>

						 </Row>
                    </div>
					   <div className="modal-footer">
                      <Button onClick={()=>simpanAlamat()} type="button" color="secondary" size="sm">
                        Save changes
                            </Button>
							{/* <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
						size="sm"
                      >Close
                            </button>*/}
                    </div>
                  </Modal>
				  			  {/*//////////////////////////////////////////FORM MODAL UBAH Nomor Telp//////////////////////////////////////////////////*/}
				   <Modal
                    isOpen={modal_TELP}
                    toggle={() => {
                      tog_center_TELP()
                    }}
                    centered={true}
                  >
                    <div className="modal-header">
                      <h5 className="modal-title mt-0">Center Modal</h5>
                      <button
                        type="button"
                        onClick={() => {
                          setmodal_TELP(false)
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
						 <Row>
						    <Col xs={3}><span  style={{justifyContent: 'center',alignItems: 'center'}}>Nomor Hp</span></Col>
							<Col xs={9}>
							    <AvForm style={{textAlign: 'center'}} >

                                 	  <AvField name="nohp" type="text"  size="sm" onChange={inputHandlernoHp} 
									   validate={{
								    	required: {value: true, errorMessage: 'Tolong Nomor Hp di isi'}
									   }}
									/>
								</AvForm>
							</Col>

						 </Row>
                    </div>
					   <div className="modal-footer">
                      <Button type="button" onClick={()=>updateNohp()} color="secondary" size="sm">
                        Save changes
                            </Button>
							{/* <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
						size="sm"
                      >Close
                            </button>*/}
                    </div>
                  </Modal>
				  
				  {/*/////////////////////////////////////////MODAL DETAIL TRANSAKSI//////////////////////////////*/}
				   <Modal
				      backdrop="static" keyboard={false}
					  size="lg"
                      isOpen={modal_detiltransaksi}
                      toggle={() => {
                      tog_modal_detiltransaksi()
                      }}
                      centered={true}
                      >
					   <div className="modal-header">
                      <h5 className="modal-title mt-0">Center Modal</h5>
                      <button
                        type="button"
                        onClick={() => {
                          setmodal_detiltransaksi(false)
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
					   <div className="modal-body">
					       <div className="row">
						      <div className="text-muted col-sm-3 "><span style={{fontSize:'13px'}}>No Invoice</span></div>
							  <div className="text-muted col-sm-9 text-left"><span onClick={()=>divInvoice(currentdetil)} style={{fontSize:'15px',fontWeight:'500',cursor:'pointer',color:'#008000'}}>{currentdetil.invno}</span></div>
						   </div>
						    <div className="row">
						      <div className="col-sm-3 text-muted "><span style={{fontSize:'13px'}}>Tanggal Pembelian</span></div>
							  <div className="col-sm-9 text-muted text-left"><span style={{fontSize:'15px',fontWeight:'500'}}>{waktuOrder(currentdetil.tgltransaksi)}</span></div>
						   </div>
						   <div className="row" style={{marginTop:'20px'}}>
						      <div className="col-sm-4" style={{borderBottom:'solid 1px #ccc',fontSize:'16px', fontWeight:'700'}}>Detail Booking lapangan</div>
							  <div className="col-sm-8"></div>
						   </div>
						   <div className="row" style={{marginTop:'20px'}}>
						       <div className="col-sm-9" >
							   {    
							      
									descjam.map(itemh=>{
										return (
										<>
										    <div className="row">
											  <div className="col-sm-2 text-muted text-left" style={{padding:'0px',fontSize:'13px',fontWeight:'600'}}>
											     <span >{itemh.ordertgl?tglOrderdetil(itemh.ordertgl):''}</span>
											  </div>
											   <div className="col-sm-2 text-muted text-left" style={{padding:'0px',fontSize:'13px',fontWeight:'600'}}>
											     <span>{itemh.namalap?itemh.namalap:''}</span>
											  </div>
											  <div className="col-sm-2 text-muted text-left"  style={{padding:'0px',fontSize:'13px',marginLeft:'-10px'}}> 
											      <span style={{marginLeft:'5px'}} >{potongString(itemh.jamawal)} -</span><span style={{marginLeft:'5px'}}>{potongString(itemh.jamakhir)}</span>
											  </div>
											  <div className="col-sm-3 text-muted text-left" style={{padding:'0px',fontSize:'13px',marginLeft:'0px'}}>
											      <span>{hitungjambooking(itemh.jamawal,itemh.jamakhir)} Jam</span>
											  </div>
											  <div className="col-sm-2 text-muted text-left" style={{padding:'0px',fontSize:'13px'}}>
											     <span >{formatAsRupiah(itemh.jumlahbayar)}</span>
											  </div>
											
											</div>
										</>
										)
									})
							   }
							   </div>
							   <div className="col-sm-3" style={{borderLeft:'solid 1px #ccc'}}>
							      <div className="row"><span style={{fontSize:'16px'}}>Total Pembayaran</span></div>
								  <div className="row"><span style={{fontSize:'14px',fontWeight:'700'}}>{formatAsRupiah(currentdetil.jumlahbayar)}</span></div>
							     
							   </div>
							   <div className="row" style={{marginTop:'20px'}}>
							      <div className="col-sm-9" ></div>
								   <div className="col-sm-3" > 
								       <div onClick={()=>kembaliOrder()} className="row text-muted text-center" style={{width:'100%',height:'35px',border:'solid 1px #ccc',borderRadius:'5px',  display: 'flex', justifyContent: 'center', alignItems: 'center',cursor:'pointer'}}>
									       <span style={{fontSize:'14px',fontWeight:'600', textAlign: 'center',}}>Order lapangan lagi</span>
									   </div>
								   </div>
							   </div>
							   <div className="row" style={{height:'50px'}}></div>
						   </div>
					   </div>
					  
				   </Modal>
				   
				   {/*////////////////////////////////////////////////////MODAL DETAIL PEMBAYARAN//////////////////////*/}
				     <Modal
				      backdrop="static" keyboard={false}
					  size="md"
                      isOpen={modal_detpembayaran}
                      toggle={() => {
                      tog_modal_detilpembayaran()
                      }}
                      centered={true}
                      >
					   <div className="modal-header">
                      <h5 className="modal-title mt-0">Detail Pembayaran</h5>
                      <button
                        type="button"
                        onClick={() => {
                          setModal_detpembayaran(false)
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
					   <div className="modal-body">
					       <div className="row">
						      <div className="col-sm-3 text-muted text-left"><span style={{fontSize:'15px', fontWeight:'700'}}>Total Bayar</span></div>
							  <div className="col-sm-6"></div>
							  <div className="col-sm-3 text-muted text-right"><span style={{fontSize:'14px', fontWeight:'700'}}>{formatAsRupiah(currentbayar.jumlahbayar)}</span></div>
						   </div>
						   {descbayar.map(itemdetbayar=>{
							   return(
							         <>
							           <div className="row">
									     <div className="col-sm-3 text-muted text-left"><span style={{fontSize:'12px'}}>{itemdetbayar.ordertgl?tglOrderdetil(itemdetbayar.ordertgl):''}</span></div>
										 <div className="col-sm-3 text-muted text-left" >
											     <span style={{fontSize:'12px',marginLeft:'-33px'}}>{itemdetbayar.namalap?itemdetbayar.namalap:''}</span>
										 </div>
										 <div className="col-sm-2 text-muted text-left" >
											      <span style={{marginLeft:'-75px',fontSize:'12px'}} >{potongString(itemdetbayar.jamawal)+' - '+potongString(itemdetbayar.jamakhir)}</span>
										 </div>
										  <div className="col-sm-1 text-muted text-left" >
											      <span style={{marginLeft:'-75px',fontSize:'12px'}} >{hitungjambooking(itemdetbayar.jamawal,itemdetbayar.jamakhir)+' jam'}</span>
										 </div>
						                 <div className="col-sm-3 text-muted text-right"><span style={{fontSize:'13px'}}>{formatAsRupiah(itemdetbayar.jumlahbayar)}</span></div>
										  
									   </div>
									 </>
							   )
						   })}
						    <div className="row" style={{borderTop:'dotted 2px #ccc',paddingTop:'10px',marginTop:'7px'}}>
							      <div className="col-sm-3 text-muted text-left">
								      <div className="row"><span style={{fontSize:'15px', fontWeight:'600'}}>Total</span></div>
									  <div className="row"><span style={{fontSize:'15px'}}>{currentbayar.akunbank?convertToUppercase2(currentbayar.akunbank):''}</span></div>
								  </div>
								  <div className="col-sm-6"></div>
								  <div className="col-sm-3 text-muted text-right"><span style={{fontSize:'14px', fontWeight:'700'}}>{formatAsRupiah(currentbayar.jumlahbayar)}</span></div>
							</div>
					   </div>
					   
					</Modal>
					{/*//////////////////////////////////////////////////////////MODAL INVOICE//////////////////////////////////////////////////////////////////////*/}
					<Modal
				      backdrop="static" keyboard={false}
					 
					  size="lg"
                      isOpen={modal_pageinvoice}
                      toggle={() => {
                      tog_modal_pageinvoice()
                      }}
                      centered={true}
                      >
					   <div className="modal-header">
                      <h5 className="modal-title mt-0">Invoice</h5>
                      <button
                        type="button"
                        onClick={() => {
                                tutupInvoice()
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
					<div className="row">
							<div className="row" style={{borderBottom:'4px double #ced4da'}}>
								 
													<div className="col-md-3 align-self-center">                                                
													     <img style={{widht:'260px',height:'38px'}} src={logo} alt="" />                                  
													</div>
													<div className="col-md-9 align-self-center" >
														   
														<ul className="list-inline mb-0 contact-detail float-right" style={{display:'flex',justifyContent:'center',alignItems:'center'}}>                                                   
															<li className="list-inline-item">
																<div className="pl-3" >
																	<i className="mdi mdi-web"></i>
																	<p className="text-muted mb-0" style={{fontSize:'13px',fontWeight:'650'}}>www.satriafutsal.com</p>
															
																</div>                                                
															</li>
															<li className="list-inline-item">
																<div className="pl-3">
																	<i className="mdi mdi-phone"></i>
																	<p className="text-muted mb-0" style={{fontSize:'13px',fontWeight:'650'}}>+123 123456789</p>
																	
																</div>
															</li>
															<li className="list-inline-item">
																<div className="pl-3">
																	<i className="mdi mdi-map-marker"></i>
																	<p style={{fontSize:'13px',fontWeight:'650'}} className="text-muted mb-0">JL. Hayam wuruk No 54</p>
																	<p style={{fontSize:'13px',fontWeight:'650'}} className="text-muted mb-0">Kec Petemon, Surabaya, Jawa Timur</p>
																</div>
															</li>
														</ul>
												  </div>
											 
							</div>
							<div className='row' style={{paddingTop:'15px'}}>
							                     	
													<div className="col-md-3">
														<div className="row" style={{padding:'0px'}}>
															<h6 className="mb-0" style={{fontSize:'15px'}}><b>Tgl Order : </b>{WaktuInvoice(currentinvoice.tgltransaksi)}</h6><br />
															
														
														<h6 style={{fontSize:'14px',fontWeight:'600',marginTop:'10px'}}><b>Nomor :</b></h6><br />
														<div className="row" style={{wordWrap:'break-word',padding:'0px' }}><p style={{fontSize:'12px'}}>{currentinvoice.invno}</p></div>
														</div>
													</div>
													<div className="col-md-6">     
																								
														<div className="float-left">
															<address className="font-13">
															<strong className="font-14">Pelanggan :</strong><br />
															<span style={{fontSize:'13px'}}>{currentinvoice.namapel}</span><br />
																	<span style={{fontSize:'13px'}}>{currentinvoice.alamatpel}</span><br />
																	<span style={{fontSize:'13px'}}>{currentinvoice.perusahaanpel}</span><br />
																		<span style={{fontSize:'13px'}}>{currentinvoice.emailpel}</span><br />
																<abbr style={{fontSize:'13px'}} title="Phone">Telp</abbr> 	<span style={{fontSize:'13px'}}>{currentinvoice.hppel}</span>
															</address>
														</div>
													</div>
													{/*<div className="col-md-3">
														<div className="">
															<address className="font-13">
																<strong className="font-14">Shipped To:</strong>
																Joe Smith
																795 Folsom Ave
																San Francisco, CA 94107
																<abbr title="Phone">P:</abbr> (123) 456-7890
															</address>
														</div>
													</div>*/}                                           
													
													<div className="col-md-3">
														<div className="text-center bg-light p-3 mb-3">
															<h5 style={{fontSize:'16px'}} className="bg-primary mt-0 p-2 text-white d-sm-inline-block">Metode Pembayaran</h5>
															<h6 style={{fontSize:'12px'}} >Online Banking, Transfer, Retail Outlet :</h6>
															<p style={{fontSize:'12px'}} className="mb-0 text-muted">satriaFutsal@gmail.com</p>
															<p style={{fontSize:'12px'}} className="mb-0 text-muted">Visa, Master Card, Chaque</p>
														</div>                                              
													</div> 
							</div>
							<div className="row">
                                            <div className="col-lg-12">
                                                <div className="table-responsive">
                                                    <table className="table table-bordered mb-0" >
                                                        <thead style={{fontSize:'14px'}}>
                                                            <tr>
                                                                <th>Quantitas</th>
                                                                <th>Item</th>
                                                                <th>Deskripsi</th>                                                    
                                                                <th>Harga / Unit</th>
                                                                <th>Total</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody style={{fontSize:'12px'}}>
														{
															descinv.map(itemdesc=>{
																return(
																  <>
																    <tr>
																		<th>{
																			
																			Number(itemdesc.jamakhir)-Number(itemdesc.jamawal)+' Jam'}</th>
																		<td>{itemdesc.namalap}</td>
																		<td>{jamdetilinv(itemdesc.ordertgl,itemdesc.jamawal,itemdesc.jamakhir)}</td>
																		<td>Rp. 125.000</td>
																		<td>{hitungtotbiaya(itemdesc.jamakhir,itemdesc.jamawal)}</td>
																	</tr>
																  </>
																)																
															})
															
														   
														}
														{/*   <tr>
                                                                <th>2</th>
                                                                <td>LCD</td>
                                                                <td>There are many variations of passages of Lorem Ipsum available.</td>
                                                                <td>$555.00</td>
                                                                <td>$1110.00</td>
                                                            </tr>
                                                            <tr>
                                                                <th>1</th>
                                                                <td>Mobile</td>
                                                                <td>It is a long established fact that a reader will be distracted.</td>
                                                                <td>$100.00</td>
                                                                <td>$100.00</td>
                                                            </tr>
                                                            <tr>
                                                                <th>3</th>
                                                                <td>Chair</td>
                                                                <td>Contrary to popular belief, Lorem Ipsum is not simply random text.</td>
                                                                <td>$30.00</td>
                                                                <td>$90.00</td>
                                                            </tr>
                                                            <tr>
                                                                <th>1</th>
                                                                <td>Camera</td>
                                                                <td>Aldus PageMaker including versions of Lorem Ipsum.</td>
                                                                <td>$999.00</td>
                                                                <td>$999.00</td>
                                                            </tr>
                                                            <tr>
                                                                <th>3</th>
                                                                <td>Headphone</td>
                                                                <td>Various versions have evolved over the years, sometimes by accident.</td>
                                                                <td>$20.00</td>
                                                                <td>$60.00</td>
</tr>*/}
                                                            <tr>                                                        
                                                                <td colspan="3" className="border-0"></td>
                                                                <td className="border-0 font-14"><b>Sub Total</b></td>
                                                                <td className="border-0 font-14"><b>{formattotbiayainv(totbayarinv)}</b></td>
                                                            </tr>
                                                            <tr>
                                                                <th colspan="3" className="border-0"></th>                                                        
                                                                <td className="border-0 font-14"><b>Tax Rate 11%</b></td>
                                                                <td className="border-0 font-14"><b>{hitungvat(totbayarinv)}</b></td>
                                                            </tr>
                                                            <tr className="bg-dark text-white">
                                                                <th colspan="3" className="border-0"></th>                                                        
                                                                <td className="border-0 font-14"><b>Total</b></td>
                                                                <td className="border-0 font-14"><b>{grandtot(totbayarinv)}</b></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>                                            
                                            </div>                                        
                                 </div>
							          <div className="row justify-content-center">
                                            <div className="col-lg-6">
                                                <h5 className="mt-4">Syarat dan Ketentuan :</h5>
                                                <ul className="pl-3">
                                                    <li><small>Harap melakukan pembayaran 30 menit sebelum jam booking. </small></li>
                                                    <li><small>Pembayaran bisa di lakukan melalui ATM, Qris, Gerai Outlet dan di Satria futsal.</small></li>
                                                                                         
                                                </ul>
                                            </div>                                        
                                            <div className="col-lg-6 align-self-end">
                                                <div className="w-25 float-right">
                                                    <small>Hormat kami</small>
                                                    <img src="assets/images/signature.png" alt="" className="" height="48"/>
                                                    <p className="border-top">Signature</p>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row d-flex justify-content-center">
                                            <div className="col-lg-12 col-xl-4 ml-auto align-self-center">
                                                <div className="text-center text-muted"><small>Trima kasih sudah berkunjung ke Satria Futsal!</small></div>
                                            </div>
                                            <div className="col-lg-12 col-xl-4">
                                                <div className="float-right d-print-none">
                                                    <a onClick={()=>handlePrint()} className="btn btn-info text-light"><i className="fa fa-print"></i></a>
													{/* <a href="#" className="btn btn-primary text-light">Submit</a>
                                                    <a href="#" className="btn btn-danger text-light">Cancel</a>
													<div style={{display:'none'}}><Cetakinvoice datainfoice={currentinvoice} deskripsiinv={descinv} datatotbayarinv={totbayarinv} ref={componentRef} /></div>*/}
													<div style={{display:'none'}}><Tespage datainfoice={currentinvoice} deskripsiinv={descinv} datatotbayarinv={totbayarinv} ref={componentRef} /></div>
                                                </div>
                                            </div>
                                        </div>
							
							
							
							
							
				   </div>
			   </Modal>
        </>
  )
}

export default Profile1;

/*Register.propTypes = {
  registerUser: PropTypes.func,
  registerUserFailed: PropTypes.func,
  registrationError: PropTypes.any,
  user: PropTypes.any,
}

const mapStatetoProps = state => {
  const { user, registrationError, loading } = state.Account
  return { user, registrationError, loading }
}

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(Register)*/
