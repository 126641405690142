//import PropTypes from "prop-types"
import React, { useEffect,useState,useRef } from "react"
//import MetaTags from 'react-meta-tags';
import { Row, Col,Button, CardBody, Card, Alert, Container, Input, Label } from "reactstrap"
import axios from 'axios'
import apiService from '../../api/apiService'
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"
//import Parse from 'parse/dist/parse.min.js';
// action
//import { registerUser, apiError, registerUserFailed } from "../../store/actions"
//import OTPGenerator from 'otp-generator';
// Redux
//import { connect } from "react-redux"
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';

import { useLocation,Link,useHistory } from "react-router-dom";
//SweetAlert
//import Timer from './Timer'
//import CountdownTimer from './CountdownTimer';
import OtpField from './OtpField';
import SweetAlert from "react-bootstrap-sweetalert"
// import images
//import logoLightPng from "../../assets/images/logo-light.png"
import logoDark from "../../assets/images/logo-dark.png"
import Sms from "../../assets/images/iconsms.png"
import { googleLogout, useGoogleLogin,GoogleLogin  } from '@react-oauth/google';
import Loader from '../../components/Helper/Loader';
import styled from 'styled-components';
const OtpContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const OtpInput = styled.input`
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 18px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 0 5px;

  &:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  }
`;

const Resetpass = props => {

 const [tokenku,setTokenku]=useState('');
 	  const [loginData, setLoginData] = useState(
       sessionStorage.getItem('loginData')
      ? JSON.parse(sessionStorage.getItem('loginData'))
      : null
  );
  const location = useLocation();
  const history = useHistory();
  const [otpnya,setOtpnya]=useState(null)
  const [aktifnya,setAktifnya]=useState(false)
  const [waktu,setWaktu]=useState(Date.now())
  const [respassword,setRespassword]=useState(true)
  const [password1,setPassword1]=useState('')
   const [password2,setPassword2]=useState('')
  const [cektime,setCektime]=useState(false)
  const [cekdup,setCekdup]=useState(false)
  
  useEffect(()=>{
	 
	  if(location.state){
		  if(location.state.jnsver==='hp'){
			   const reqparam={datanohp:location.state.nohpnya}
			   axios.post(apiService+'/otpreset',reqparam).then(res =>{
				   	let cekSuksesotp=res.data.hasOwnProperty('success');
					if(cekSuksesotp){
						// console.warn('kode otpnyaaaa.........', res.data.success[0].kode)
						setOtpnya(res.data.success[0].kode)
						 setWaktu(Date.now()+61000);
					}else{
						console.warn('terjadi kesalahan')
					}
			   })
		  }else{
			  
			     const reqparam={dataemail:location.state.emailnya}
			     axios.post(apiService+'/daftarbyemail',reqparam).then(res =>{
				//  console.warn('data dari api otpppppppppppp.........',res.data)
					//	 console.warn('kode otpnyaaaa.........', res.data.otpnya)
						setOtpnya(res.data.otpnya)
						 setWaktu(Date.now()+61000);
				
			   })
		  }
	  }else{
		  
		   history.push("/forgot-password");
	  }
  },[])
  
   const handleValidSubmit = async(event, values) => {
	   if(password1===password2){
		   if(location.state.jnsver==='hp'){
				const requestparam ={varpass:password2,datauser:location.state.nohpnya}
				axios.post(apiService+'/ubahpassword',requestparam).then(res2=>{
						let suksesupdate=res2.data.hasOwnProperty('success');
						if(suksesupdate){
							  history.push("/login");
						}
			   })
		   }else{
			   //console.warn('dataaaaaaaaa.......................mail',location.state.emailnya)
			   const requestparam ={varpass:password2,datauser:location.state.emailnya}
			   axios.post(apiService+'/ubahpasswordbymail',requestparam).then(res2=>{
			     	let suksesupdate=res2.data.hasOwnProperty('success');
					if(suksesupdate){
						  history.push("/login");
					}
		       })
		   }
		}else{
			//setCekdup(false)
		}
   }

  useEffect(()=>{
	 // if(timerComponents.length){
	 //	  const cekTime=timerComponents.split(' ')
	 //			console.warn('TMERRR....',cekTime[0])
	 // }
	      console.warn('timeLeftnyaaaaaaaaaaaaaa...',timeLeft.detik)
	   if(timeLeft.detik < 60){
		   if(timeLeft.detik===59){
		
			 setCektime(true)
		   }
	   }
	
  })

	const handleOtpSubmit = (otp) => {
    // Lakukan sesuatu dengan OTP yang diinputkan
    console.warn('isi OTP',otp)
       if(otp.length===6){
			if(timerComponents.length){
				if(otpnya===otp){
					setRespassword(false)
					//alert('otp benar')
					/* const request1={nohplogin:nomorhp}
					 const responselogin= axios.post(apiService+'/loginbyhp',request1,{withCredentials: true}).then(res => {
							let cekSukses=res.data.hasOwnProperty('success');
							if(cekSukses){
								  if(!res.data.success[0].accessToken){
										 alert('terjadi kesalahan')
									}else{
										 history.push("/");
									}
							}else{
								alert('terjadi kesalahan')
							}
					 })*/
				}else{
					alert('otp salah')
				}

			}
	  }
	}
	const inputHandlerpass1=(e)=>{
		setPassword1(e.target.value)
	}
		const inputHandlerpass2=(e)=>{
		setPassword2(e.target.value)
	
	}
	
		const kirimulangOTPreset=()=>{
		     setCektime(false)
		    const request1={nomorhpx:location.state.nohpnya}
				 	const response6 = axios.get(apiService+'/daftarotp',{params:request1}).then(res => { 
					   
						 let hasKey = res.data.hasOwnProperty('success');	
						 if(hasKey){
							 if(res.data.success[0].sts){
								//  console.warn('data OTP GENERATE', res.data.success[0].kode)
								//setProsesotpHP(true)
								setOtpnya(res.data.success[0].kode)
								setWaktu(Date.now()+61000)
							 }
						 }
					})
	}
	
		const kirimulangOTPresetbyemail=()=>{
		     setCektime(false)
		    const request1={dataemail:location.state.emailnya}
				 	const response6 = axios.post(apiService+'/daftarbyemail',request1).then(res => { 
					   
					
						
								//  console.warn('data OTP GENERATE', res.data.success[0].kode)
								//setProsesotpHP(true)
								setOtpnya(res.data.otpnya)
								setWaktu(Date.now()+61000)
							 
						 
					})
	}
	
	
	
	 const calculateTimeLeft = () => {
		//  const expiryTimestamp = Date.now() + 60000;
      const difference = waktu - Date.now();
	//console.warn('difference',difference)
	//const difference =60000;
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        detik: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  };
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    useEffect(() => {
     const timer = setTimeout(() => {
     setTimeLeft(calculateTimeLeft());
	 }, 1000);

   return () => clearTimeout(timer);
   });

     const timerComponents = [];
   Object.keys(timeLeft).forEach(interval => {
	// console.warn('ISI timeLeft',timeLeft[interval])
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span key={interval}>

        {timeLeft[interval]} {interval}{''}
      </span>
    );
  });

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
			<Row className="justify-content-center">
				<div className="p-3">
					<h4 className="text-muted font-size-14 mb-1 text-center">Verifikasi</h4>
					{location.state.jnsver==='hp'?(
					   <>											
						<p className="text-muted text-center">Masukan kode OTP yang telah dikirim ke nomor anda</p> 
						<p className="text-muted text-center">Mohon periksa pesan teks pada nomor anda</p> 
					</>	
					 ):(
					 <>											
						<p className="text-muted text-center">Masukan kode OTP yang telah dikirim ke email anda</p> 
						<p className="text-muted text-center">Mohon periksa email anda</p> 
				  	</>	
					 )
					
					}
														
				</div>
				  {respassword ?(
						<div className="p-3">
						
					     	{cektime ? (
										  <div className="text-center">
											  <Label>{timerComponents.length ? timerComponents : 'Waktu telah berakhir!'}</Label>
										  </div>
									):(
										<div className="spinner">								
										  <div className="spinner-icon">  <i className="fa fa-spinner" /></div>
										</div>
									)
							}		   
						   
						   
							 <OtpField numDigits={6} aktifasi={aktifnya} onOtpChange={handleOtpSubmit} />	
								{
									cektime ? (
										<div className="text-center">
										{timerComponents.length ? null: location.state.jnsver==='hp'?(<span onClick={()=>kirimulangOTPreset()} style={{fontSize:'13px',cursor:'pointer'}}>Kirim ulang OTP!</span>):(<span onClick={()=>kirimulangOTPresetbyemail()} style={{fontSize:'13px',cursor:'pointer'}}>Kirim ulang OTP!</span>)}
										</div>
									):null
								}								 
						</div>
					  ):(
					    	<div className="p-3" >	
							    <div className="row">
								     <div className="col-md-4">
									 </div>
								     <div className="col-md-4">
										<AvForm style={{textAlign: 'center'}}
                                              onValidSubmit={(e, v) => {
												handleValidSubmit(e, v)
											  }}
										>
										  <AvField name="password" label="Password" type="password" onChange={inputHandlerpass1}
											   validate={{
											required: {value: true, errorMessage: 'Tolong Password di isi'}
											   }}
										  />
										  <AvField name="password2" label="Ketik ulang Password" type="password" onChange={inputHandlerpass2}
											   validate={{
											   required: {value: true, errorMessage:'Tolong Password di isi'},
											   match:{value:'password', errorMessage:'Password yang anda ketikan tidak sama'}
											   }}
											   
										  />
										  <Button  type="submit"  size="sm" className="btn btn-primary " type="submit">Submit</Button>
										</AvForm>
									</div>
									 <div className="col-md-4">
									 </div>
								</div>
							</div>
					  )
				  }
		    </Row>
        </Container>
      </div>

    </React.Fragment>
  )
}

export default Resetpass;

