import axios from "axios"

const API_URL = "http://localhost:4001"

const axiosApi = axios.create({
  baseURL: API_URL,
})

//axiosApi.defaults.headers.common["Authorization"] = token

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function post(url, data, config = {}) {
	console.warn('data sampai backen helper',data)
		console.warn('config sampai backen helper',config)
  return await axiosApi.post(url, { ...data }, { ...config }).then(response => response.data) 
}

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function del(url,data, config = {}) {
	//console.warn("api function iss calling delete",data)
  return await axiosApi
    .delete(url,data, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
		console.warn("api function iss calling edit",data)
  return axiosApi
    .put(url, data , { ...config })
    .then(response => response.data)
}