import PropTypes from 'prop-types'
//import MetaTags from 'react-meta-tags';

import React, { useEffect,useState,useRef } from "react"
import { Row, Col, Alert, Card, CardBody, Container } from "reactstrap"
import apiService from '../../api/apiService'
import axios from 'axios'
// Redux
//import { connect } from "react-redux"
import { withRouter, Link,useHistory } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// action
//import { userForgetPassword } from "../../store/actions"

// import images
//import logoLightPng from "../../assets/images/logo-light.png"
//import logoDark from "../../assets/images/logo-dark.png"

const ForgetPasswordPage = props => {
	  const history = useHistory ();
	  const [frmotp,setFrmotp]=useState(false)
	  const [pesanerror,setPesanerror]=useState('')
	  
  function handleValidSubmit(event, values) {
	// console.warn('okeeeee.... reset password',values)
   // props.userForgetPassword(values, props.history)
   let {datauser}=values
   const request1={
	   datax:datauser
   }
        const response6 = axios.post(apiService+'/prosesresetpass',request1).then(res => {  
		                // console.warn('nilai balik ressetttttttttttt',res.data)
		             	//let arr3 = res.data; 	
						// setLoginData(arr3);
                       //  sessionStorage.setItem('loginData', JSON.stringify(arr3));
         				//console.warn('data balik',arr3)		
                        let cekSukses=res.data.hasOwnProperty('exist');
							if(cekSukses){
								if(res.data.exist[0].sts==='exist'){
									//setFrmotp(true)
								 history.push({
									pathname:"/reset-password",
									state :{jnsver:'hp',nohpnya:res.data.exist[0].nohp}
								 });
								}
							}else{
								
								     let cekSuksesemail=res.data.hasOwnProperty('mail');
								if(cekSuksesemail){
								
									if(res.data.mail[0].jns==='exist'){
										 history.push({
											pathname:"/reset-password",
											state :{jnsver:'email',emailnya:res.data.mail[0].email}
										 });
									}else if(res.data.mail[0].jns==='validmail'){
									setPesanerror(res.data.mail[0].msg)
								    }else if(res.data.noexist[0].jns==='valid'){
									setPesanerror(res.data.noexist[0].msg)
									}else if(res.data.noexist[0].jns==='notvalidnumber'){
										setPesanerror(res.data.noexist[0].msg)
									}else if(res.data.noexist[0].jns==='bukannumber'){
										setPesanerror(res.data.noexist[0].msg)
									}
								}
								
								let cekdatanumbernoexist=res.data.hasOwnProperty('noexist')
								if(cekdatanumbernoexist){
									if(res.data.noexist[0].jns==='valid'){
									  setPesanerror(res.data.noexist[0].msg)
									}else if(res.data.noexist[0].jns==='notvalidnumber'){
										setPesanerror(res.data.noexist[0].msg)
									}else if(res.data.noexist[0].jns==='bukannumber'){
										setPesanerror(res.data.noexist[0].msg)
									}
								}
							}   						
				   })
				   
       
  }
  
  const inputHandledata=(e)=>{
	  setPesanerror('')
  }

  return (
    <React.Fragment>

      <div className="account-pages my-1 pt-sm-1">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <h3 className="text-center mt-5 mb-4">
                    <Link to="/" className="d-block auth-logo">
					{/* <img src={logoDark} alt="" height="30" className="auth-logo-dark" />
					<img src={logoLightPng} alt="" height="30" className="auth-logo-light" />*/}
					 <img  alt="" height="30" className="auth-logo-dark" />
					<img alt="" height="30" className="auth-logo-light" />
                    </Link>
                  </h3>
				  <div className="p-0">
				    <div className="row mb-1 p-3">
					  <h4 className="text-muted font-size-18 mb-3 text-center">Atur ulang Password</h4>
						<div style={{fontSize:'12px'}} className="alert alert-info" role="alert">
						  Masukkan email atau Nomor Hp anda untuk mendapatkan intruksi selanjutnya
						</div>
					</div>
				  </div>
                  <div className="p-0">
                    
                    {/*props.forgetError && props.forgetError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {props.forgetError}
                      </Alert>
                    ) : null*/}
                    {/*props.forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {props.forgetSuccessMsg}
                      </Alert>
                    ) : null*/}

                    <AvForm
                      className="form-horizontal mt-0"
                      onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                    >
                      <div className="row mb-2 p-1">
                        <AvField
                          name="datauser"
                          label="Email atau Nomor Hp"
                          className="form-control"
                          placeholder="Masukan email atau Nomor Hp"   	
						  required
                          onChange={inputHandledata}						  
                        />
						
                
					  <span  className="text-danger" style={{fontSize:'12px', marginTop:'-10px'}}>{pesanerror}</span>
					  </div>
                      <div className="row mb-3 mt-2 p-3">
                  
                          <button 
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                          >
                            Reset
                          </button>
                  
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Ingat password ?{" "}
                  <Link to="login" className="text-primary">
                    Log In
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Satria Futsal 
				</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
	  
    </React.Fragment>
  )
}
export default ForgetPasswordPage;
/*ForgetPasswordPage.propTypes = {
  forgetError: PropTypes.any,
  forgetSuccessMsg: PropTypes.any,
  history: PropTypes.object,
  userForgetPassword: PropTypes.func
}

const mapStatetoProps = state => {
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword
  return { forgetError, forgetSuccessMsg }
}

export default withRouter(
  connect(mapStatetoProps, { userForgetPassword })(ForgetPasswordPage)
)*/
