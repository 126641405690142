import React, { useState,useEffect,useRef } from "react"
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer';
import { useLocation,useHistory } from "react-router-dom";
import FooterHomeOne from './FooterHomeOne';
import HeroHomeOne from './HeroHomeOne';
import HomeOneHeader from './HomeOneHeader';
import Cart from '../Cart';
import { connect,useDispatch,useSelector } from "react-redux";
function HomeOne({datasharing}) {
	 const location = useLocation();
	   const history = useHistory();
        const [drawer, drawerAction] = useToggle(false);
       const [cart, cartAction] = useToggle(false);
	    const [sharedState, setSharedState] = useState(0);
		const [logurlpay,setLogurlpay]=useState(false)
		const [datakeranjang,setDatakeranjang]=useState([]);
		  useEffect(() => {
			    const params = new URLSearchParams(window.location.search);
                const paramValue = params.get('order_id');
				console.warn('parammmmmmmmmmmmmmmmmmmmmmmmmmvalueeeee',paramValue)
				if(paramValue===null){
					 setLogurlpay(false)
					
				}else{
					setLogurlpay(true)
					 const requestbayar={
					 idpayment:paramValue,
			   //    emailpel:mailuser			
					}			 
					history.push({
					pathname:"/order-bayar-2",
					state :requestbayar
					});
				}
			 //  console.warn('reeeeeeeeeeeeeeeeeeeeeeeeeeee',location.state)
			/*  if(location.state){
				  setLogurlpay(true)
				  history.replace({
					...location,
					state: undefined,
				  });
			  }*/
		  },[])
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HomeOneHeader
			action={drawerAction.toggle}
			sharedState={sharedState}
			setSharedState={setSharedState}
			datakeranjang={datakeranjang}
			setDatakeranjang={setDatakeranjang}
			cartToggle={cartAction.toggle} />


            <HeroHomeOne
			setLogurlpay={setLogurlpay}
			logurlpay={logurlpay}
			sharedState={sharedState}
			setSharedState={setSharedState}
			datakeranjang={datakeranjang}
			setDatakeranjang={setDatakeranjang}
			/>

            <FooterHomeOne className="appie-footer-about-area"/>
            <BackToTop />
        </>
    );
}

//export default HomeOne;
const mapStateToProps = (state) => {
  //  console.log(state);
    return ({
        datasharing: 1,

    })
};

const mapDispatchToProps = (dispatch) => ({
    actions: {
        userMasuk: (payload) => {
			//console.warn('data payload',payload)
          //  dispatch(actionType.loginUser(payload));
        },


    },
});
export default connect(mapStateToProps, mapDispatchToProps)(HomeOne);
