//import PropTypes from "prop-types"
import React, { useEffect,useState,useRef } from "react"
//import MetaTags from 'react-meta-tags';
import { Row, Col,Button, CardBody, Card, Alert, Container, Input, Label } from "reactstrap"
import axios from 'axios'
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"
//import Parse from 'parse/dist/parse.min.js';
// action
//import { registerUser, apiError, registerUserFailed } from "../../store/actions"
//import OTPGenerator from 'otp-generator';
// Redux
//import { connect } from "react-redux"
import moment from 'moment-timezone';
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { useLocation,Link,useHistory } from "react-router-dom";
import apiService from '../../api/apiService'
//SweetAlert
//import Timer from './Timer'
//import CountdownTimer from './CountdownTimer';
//import OtpField from './OtpField';
import SweetAlert from "react-bootstrap-sweetalert"
// import images
//import logoLightPng from "../../assets/images/logo-light.png"
import logoDark from "../../assets/images/logo-dark.png"
import vecsoccer from '../../assets/images/soccer1.jpg';
import Sms from "../../assets/images/iconsms.png"
import { googleLogout, useGoogleLogin,GoogleLogin  } from '@react-oauth/google';
import Loader from '../../components/Helper/Loader';
import styled from 'styled-components';
const OtpContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const OtpInput = styled.input`
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 18px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 0 5px;

  &:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  }
`;

const Orderbayar = props => {
  // handleValidSubmit
 // const handleValidSubmit = (event, values) => {
  //  props.registerUser(values)
 // }
 const [tokenku,setTokenku]=useState('');
 	  const [loginData, setLoginData] = useState(
       sessionStorage.getItem('loginData')
      ? JSON.parse(sessionStorage.getItem('loginData'))
      : null
  );
 const location = useLocation();
  const [startDate, setStartDate] = useState('');
  const [akunbank,setAkunbank]=useState('');
  const [endDate, setEndDate] = useState('');
  const [nova,setNova]=useState('');
  const [bayar,setBayar]=useState(0);
  const [dateDifference, setDateDifference] = useState(null);
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  useEffect(() => {
  // const params = new URLSearchParams(window.location.search);
  //  const paramValue = params.get('order_id');
  //  console.warn('param-------------------------',paramValue); 
  }, []);
  
  
     const [result, setResult] = useState('');
    const [waktu,setWaktu]=useState(Date.now())
     useEffect(()=>{
		  const params = new URLSearchParams(window.location.search);
    const paramValue = params.get('order_id');
	 		//console.warn('tesssssssssssssssssssssss111111',location.state)
			
		  const datacari={
	   //  idbayar:paramValue,
		 idbayar:location.state.idpayment
	 //  emailuser:location.state.emailpel
	     }     
	    const konfig={		
			headers:{"Content-Type":"application/json"},
            withCredentials: true		  
            }
			
	       const responseOrder =  axios.post(apiService+'/getstatusorder', datacari).then(resultorder=>{
		   		//console.warn('tesssssssssssssssssssssss',resultorder.data)
				// setStartDate('2023-12-07 09:10:22');
				// setEndDate('2023-12-08 09:10:22');
				const start = Date.now();
				//const end = new Date(location.state.success[0].databalik.expiry_time);
				const end = new Date(resultorder.data.tglexpire);
				//const end =  Date.now();
				//inputDate.format('YYYY-MM-DD HH:mm:ss')
				const inputDate = moment(end).tz('Asia/Bangkok');
				// const monthName = inputDate.format('MMMM');
				const jamnya=inputDate.format('HH:mm');
				const dayOfMonth = end.getDate();
				const year = end.getFullYear();
				const monthNames = [
				'Januari', 'Februari', 'Maret', 'April',
				'Mei', 'Juni', 'Juli', 'Agustus',
				'September', 'Oktober', 'November', 'Desember'
				];

				const monthIndex = end.getMonth();
				const monthName = monthNames[monthIndex];
				const differenceInMilliseconds = end - start;
				// calculateDateDifference();
				// console.warn('tesssssssssssssssssssssss',differenceInMilliseconds)
				//waktu=resultorder.data.totwaktu;
				setWaktu(Date.now()+differenceInMilliseconds)

				const days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];
				const dayIndex = end.getDay();
				setResult(`${days[dayIndex]}, ${dayOfMonth} ${monthName} ${year} ${jamnya}`);
				
				//setAkunbank(convertToUppercase(location.state.success[0].databalik.va_numbers[0].bank));
				//setNova(location.state.success[0].databalik.va_numbers[0].va_number);
				//setBayar(formatAsRupiah(location.state.success[0].databalik.gross_amount));
				setAkunbank(convertToUppercase(resultorder.data.akun));
				setNova(resultorder.data.vanumber);
				setBayar(resultorder.data.totalbayar);
				
      })	
	 
	 // return()=>{
		  
	 // }
  },[])
  
   const convertToUppercase = (inputString) => {
    return inputString.toUpperCase();
   };
   
   const formatAsRupiah = (number) => {
    return new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
    }).format(number);
  };
   
  	   const calculateDateDifference = () => {
		const start = new Date(startDate);
		const end = new Date(endDate);

		// Calculate the difference in milliseconds
		const differenceInMilliseconds = end - start;

		// Convert milliseconds to days
		const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));

		setDateDifference(differenceInDays);
		};
   

  const balikHome=()=>{
	   history.push("/");
  }
  
//const [opsver,setOpsver]=useState(location.state.jnsver==='mobile')
const calculateTimeLeft = () => {
	//	 const expiryTimestamp = Date.now() + 60000;
     const difference = waktu - Date.now();
	//console.warn('difference',difference)
	//const difference =60000;
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        detik: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  };
  

  
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    useEffect(() => {
     const timer = setTimeout(() => {
     setTimeLeft(calculateTimeLeft());
	 }, 1000);

   return () => clearTimeout(timer);
   });

     const timerComponents = [];
	//console.warn('ISI timeLeft',timeLeft)
   Object.keys(timeLeft).forEach(interval => {
	//console.warn('ISI timeLeft',interval)
    if (!timeLeft[interval]) {
      return;
    }
   timerComponents.push(
      <span key={interval}>       
        {interval==='detik'?(timeLeft[interval]<10?'0'+timeLeft[interval]:timeLeft[interval]):(timeLeft[interval]<10?'0'+timeLeft[interval]+':':timeLeft[interval]+':')}
      </span>
    );
  });
  
  
  const formatAsTwoDigits = (number) => {
    // Using toLocaleString
    // const formattedNumber = number.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });

    // Using string manipulation
    const formattedNumber = number < 10 ? `0${number}` : `${number}`;

    return formattedNumber;
  };
    
	
	  const containerStyle = {

		width:'650px',
					 
		height:'400px',
		display: 'flex',
		justifyContent: 'center',
		 alignitems: 'center'
  };

  const history = useHistory ();
 // console.warn('lebar data',timerComponents)
  return (
    <React.Fragment>
      <div className="account-pages my-2 pt-sm-2">
        <Container>
		   <Row className="justify-content-center">
				<div className="p-3">
					 <h4 className="text-muted font-size-14 mb-1 text-center">Selesaikan pembayaran dalam</h4>
					 <p className="text-muted text-center">{timerComponents.length? timerComponents:'--:--:--'}</p>
				   
				</div>
				<div className="p-1">
				   <p className="text-muted text-center">Batas akhir pembayaran</p>
				   <h5 className="text-muted font-size-14 mb-1 text-center">{result}</h5>
				   
				</div>

                 <div style={containerStyle} >
					 {/*<img style={{marginTop:'30px'}} src={vecsoccer} alt="" />*/}
						<div 
						style={{width:'450px',
						position: 'absolute',
						marginTop:'20px',
						backgroundColor:'#fff',
						height:'350px',
						padding:'6px 0px 0px 0px',
						border:'solid 1px #ccc',
						boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.03)',
						borderRadius: '8px',zIndex:9999}}>
							<div style={{borderBottom:'solid 1px #ccc', padding:'6px 0px 6px 0px'}} className="row">
							   <div  className="text-muted text-center  font-size-16 mb-1  col-sm-3"><h6 className="text-muted font-size-16 mb-1 text-center" >{akunbank}</h6></div>
							   <div className="col-sm-6"></div>
							   <div className="text-muted text-center col-sm-3"><p >logo</p></div>
							</div>
							<div style={{marginTop:'30px'}} className="row">
							   <div  className="col-sm-6">
								   <div className="row ">
									  <p style={{fontSize:'14px'}} className="text-muted text-left">Nomor Virtual Account</p>
								   </div>
									 <div className="row">
									   <p style={{fontSize:'18px',fontWeight:'600'}} className="text-muted text-left">{nova}</p>
								   </div>
							   </div>
							   <div className="col-sm-3"></div>
							   <div className={windowSize.width < 550 ? "text-muted col-sm-3":"text-muted text-right col-sm-3" }>
								   <div style={{height:'20px'}} className="row">
									   
								   </div>
									 <div className="row">
									   <p style={{fontSize:'14px'}}>Salin</p>
								   </div>
							   </div>
							</div >
							<div style={{marginTop:'30px'}} className="row">
							   <div  className="col-sm-6">
								   <div className="row ">
									  <p style={{fontSize:'14px'}} className="text-muted text-left">Total Pembayaran</p>
								   </div>
									 <div className="row">
									   <p style={{fontSize:'18px',fontWeight:'600'}} className="text-muted text-left">{bayar}</p>
								   </div>
							   </div>
							   <div className="col-sm-3"></div>
							   <div className={windowSize.width < 550 ? "text-muted col-sm-3":"text-muted text-right col-sm-3" }>
								   <div style={{height:'20px'}} className="row">
									   
								   </div>
									 <div className="row">
									   <p style={{fontSize:'14px'}}>Salin</p>
								   </div>
							   </div>
							</div >
							<div style={{height:'50px'}} className="row"></div>
							<div style={{marginTop:'30px'}} className="row">
							   <div  className="col-sm-6 text-muted text-center" 
							   style={{height:'45px',display: 'flex',justifyContent: 'center',alignitems: 'center',padding:'3px 5px 3px 8px'}}>
							         <div style={{width:'100%',height:'100%',border:'solid 1px #ccc',borderRadius:'4px',paddingTop:'6px',cursor:'pointer'}}><p style={{fontSize:'14px'}} className="text-muted text-center">Cek status pembayaran</p></div>
							   </div>
							    <div className="col-sm-6 text-muted text-center" 
								style={{height:'45px',display: 'flex',justifyContent: 'center',alignitems: 'center',padding:'3px 8px 3px 5px'}}>
								   <div onClick={()=>balikHome()} style={{width:'100%',height:'100%',border:'solid 1px #ccc',borderRadius:'4px',paddingTop:'6px',cursor:'pointer'}}><p style={{fontSize:'14px'}} className="text-muted text-center">Booking lapangan lagi</p></div>
								</div>
							</div>
						</div>
				</div>
		  </Row>

        </Container>
      </div>
    </React.Fragment>
  )
}

export default Orderbayar;

/*Register.propTypes = {
  registerUser: PropTypes.func,
  registerUserFailed: PropTypes.func,
  registrationError: PropTypes.any,
  user: PropTypes.any,
}

const mapStatetoProps = state => {
  const { user, registrationError, loading } = state.Account
  return { user, registrationError, loading }
}

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(Register)*/
