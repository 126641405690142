//import PropTypes from "prop-types"
import React, { useEffect,useState } from "react"
//import MetaTags from 'react-meta-tags';
import { Row, Col,Button, CardBody, Card, Alert, Container,Modal } from "reactstrap"
import axios from 'axios'
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"
import apiService from '../../api/apiService'
//import Parse from 'parse/dist/parse.min.js';
// action
//import { registerUser, apiError, registerUserFailed } from "../../store/actions"
import Loader from '../../components/Helper/Loader';
// Redux
//import { connect } from "react-redux"
import { Link,useHistory  } from "react-router-dom"

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"
//import { google } from 'googleapis';
// import images
//import logoLightPng from "../../assets/images/logo-light.png"
import logoDark from "../../assets/images/logo-dark.png"
import { googleLogout, useGoogleLogin,GoogleLogin  } from '@react-oauth/google';
const Register = props => {
  // handleValidSubmit
 // const handleValidSubmit = (event, values) => {
  //  props.registerUser(values)
 // }
 const [tokenku,setTokenku]=useState('');
 	  const [loginData, setLoginData] = useState(
       sessionStorage.getItem('loginData')
      ? JSON.parse(sessionStorage.getItem('loginData'))
      : null
  );
const [modal_small, setmodal_small] = useState(false)
const [username, setUsername] = useState('');
const [password, setPassword] = useState('');
const [currentUser, setCurrentUser] = useState(null);
const [judul,setjudul]=useState('')
const [existmsg,setExistmsg]=useState('')
const [stsnohp,setStsnohp]=useState(false)
const [dataoke,setDataoke]=useState(false)
const [stsvalid,setStsvalid]=useState(false)
const [msgdatavalid,setMsgdatavalid]=useState('')
const [mail,setMail]=useState('')
const [simpan,setSimpan]=useState(false)

const [loading, setLoading] = useState(false);
  const history = useHistory ();
   const prosesDaftar=async()=>{
	
	    const request1 = {datauser:inputnmr}
	   	const response6 = axios.post(apiService+'/daftar',request1).then(res => {  
		            //   console.warn(res.data);
					   if(inputnmr===''){
						      setStsvalid(true)
							  setjudul('Mohon isi dengan benar')
					   }else{
						// if(res.data.hasOwnProperty('invalid')){
						//	 setStsvalid(true)
						// }else{
								 let hasKey = res.data.hasOwnProperty('errors');			
								  //  setStsnohp(hasKey)
								 if(hasKey){
									// let haskeyexist=
									setStsnohp(hasKey)
									 let haskindobj1 = res.data.errors[0].hasOwnProperty('nohp'); 
									// let haskindobj2 = res.data.errors[0].hasOwnProperty('email'); 
									 if(haskindobj1){
										// console.warn('masuk no HP sudah ada')
										setjudul( res.data.errors[0].nohp)
										setExistmsg(res.data.errors[0].msg)
									 }else{
										setjudul( res.data.errors[0].email)
										setExistmsg(res.data.errors[0].msg)
										// console.warn('masuk Email sudah ada')
									 }
									//const nohpexist = res.data.errors.map(result2 => return result2.nohp);
									//res.data.errors.map(function(item) { 
								   //   })
									//console.warn('masuk data sudah ada')
								 }else{
									 //setDataoke(true)
									if(res.data.exist[0].jns==='valid'){
									    setjudul( res.data.exist[0].nohp)
										setMsgdatavalid(res.data.exist[0].msg)
										if(simpan){
											 history.push({
												 pathname:"/verifikasi",
												 state :{jnsver:'mobile',nomorhpx:res.data.exist[0].nohp}
												 });
											 /* history.push({
												   pathname: '/secondpage',
												   search: '?query=abc',
												   state: { detail: 'some_value' }
											   });*/
                                          //   window.location.reload();
										}else{
											setDataoke(true)
										}
									}else if(res.data.exist[0].jns==='notvalidnumber'){
									    setStsvalid(true)
									    setjudul( res.data.exist[0].msg)
									}else if(res.data.exist[0].jns==='bukannumber'){
									    setStsvalid(true)
									    setjudul( res.data.exist[0].msg)
									}else if(res.data.exist[0].jns==='validmail'){
										//const requestemail={dataemail:res.data.exist[0].email}
										         history.push({
												    pathname:"/verifikasi",
												    state :{jnsver:'email',emailnya:res.data.exist[0].email}
												 });
									/*    const response7 = axios.post('http://localhost:4001/daftarbyemail',requestemail).then(res => { 
										    //console.warn('data OTP sudah di email',res.data)
											 history.push({
												 pathname:"/verifikasi",
												 state :{jnsver:'email',emailnya:res.data.emailx}
												 });
										})*/
									}
								 }
						 }
		             	//let arr3 = res.data; 	
						// setLoginData(arr3);
                       //  sessionStorage.setItem('loginData', JSON.stringify(arr3));
         			//	console.warn('data balik',res.data)			
				   }) .catch(error => {
            // Handle error
			if (error.response) {
			  // The request was made and the server responded with a status code
			  console.warn(error.response.status);
			  console.warn(error.response.data);
			} else if (error.request) {
			  // The request was made but no response was received
			  console.warn(error.request);
			} else {
			  // Something happened in setting up the request that triggered an Error
			  console.warn('Error:', error.message);
			}
			console.warn(error.config);
		  });
   }

const setStatussave =()=>{
	setDataoke(false)
	setSimpan(true)
}
  const [inputnmr,setInputnmr]=useState('')
  const inputHandler = (e) => {
   //setform({ ...form, [e.target.name]: e.target.value })   
   setInputnmr(e.target.value)
  }
  
 /* const checkEmailExists = async (email) => {
  try {
    const oauth2Client = new google.auth.OAuth2();

    // Set access and refresh tokens if available
    // oauth2Client.setCredentials({ access_token: 'ACCESS_TOKEN', refresh_token: 'REFRESH_TOKEN' });

    const gmail = google.gmail({ version: 'v1', auth: oauth2Client });

    const response = await gmail.users.getProfile({ userId: email });

    if (response && response.data) {
      // Email exists
      console.log('Email exists');
    } else {
      // Email does not exist
      console.log('Email does not exist');
    }
  } catch (error) {
    // Handle error
    console.log('Error:', error);
  }
};*/
  
    function tog_small() {
    setmodal_small(!modal_small)
    removeBodyCss()
  }
      function tutup() {
       setmodal_small(false)	
    }
   function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  
 	const handlelogin=useGoogleLogin({
       onSuccess:   (codeResponse) => {
		           const request1={token: codeResponse.access_token}
			       // const response2 =  axios.post('http://localhost:4001/google-login', request1)
				 /* const res = axios.post('http://localhost:4001/google-login', {
				  method: 'POST',
				  body: JSON.stringify({
					token: codeResponse.access_token,
				  }),
				  headers: {
					'Content-Type': 'application/json',
				  },
				  });*/
				 //  const data = response2.json();		
                  setLoading(true)				 
		        	const response6 = axios.get(apiService+'/daftarbygoogle',{ params: request1}).then(res => {  
		             	//let arr3 = res.data; 	
						// setLoginData(arr3);
                       //  sessionStorage.setItem('loginData', JSON.stringify(arr3));
         				
						/* let hasKey = res.data.hasOwnProperty('errors');
						 if(hasKey){
							 if(res.data.errors[0].jenis==='noakun'){				
									 console.warn('email tidak terverifikasi')								 
							 }
						 }		*/	
						 	 	//	console.warn('data balikdfgggggggggggggggggg',res.data)	
                         setLoading(true)						 
						 let hasSucces=res.data.hasOwnProperty('success')
						 if(hasSucces){
						
							 if(res.data.success[0].verifid && res.data.success[0].sts==='exist'){
								  
								 const request4={
									 usermail:res.data.success[0].email
								 }
								 const responselogin= axios.post(apiService+'/loginbygooglelogin',request4,{withCredentials: true}).then(reslog => {
									   if(reslog.data.success[0].jenis==='email'){
										   setLoading(false)
										   history.push({
									        	pathname:"/"	 											
										 });
									  }
								
								  })
								  	 
							 }else if(res.data.success[0].verifid && res.data.success[0].sts==='noexist'){
							        
									setMail(res.data.success[0].email)
									setUsername(res.data.success[0].username)
								   setmodal_small(true)
								   setLoading(false)
							 }
						 }else{
							 console.warn('terjadi kesalahan di daftar by gmail')
						 }
				   })
		},
	
        onError: (error) => console.log('Login Failed:', error)
		
    });	
	
	const daftarBygmail=async(emailnya,unamenya)=>{
	
		const request3={
			uname:unamenya,emailuser:emailnya
		}
		 const responsedaftarbyemailverified = axios.post(apiService+'/daftardatabyemailverified',request3).then(resdaftar => { 
		       	 let hasSucces=resdaftar.data.hasOwnProperty('success')
				 if(hasSucces){
					 const requeslogin={
						 usermail: resdaftar.data.success[0].emailnya
					 }
					 
						 const responDaftarbygooglelogin= axios.post(apiService+'/loginbygooglelogin',requeslogin,{withCredentials: true}).then(reslogin => {
									   if(reslogin.data.success[0].jenis==='email'){
										   setLoading(false)
										   history.push({
									        	pathname:"/"	 											
										 });
									  }
									//  console.warn('data balik data',reslog.data )
								  })
				 }
		 })
	}

  useEffect(() => {
   // props.apiError("")
  }, []);
  

	/*    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 2000);
      });*/
	  
	  
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
	  useEffect(() => {
    // Simulasi pengambilan data dari server dengan setTimeout
    setTimeout(() => {
    //  setData([1, 2, 3, 4, 5,6,7,]);
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <React.Fragment>

	  
	     {loading && (
                <div className={`appie-loader ${loading ? 'active' : ''}`}>
                    <Loader />
                </div>
            )}
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <h3 className="text-center mt-5 mb-4">
                    <Link to="/" className="d-block auth-logo">
                      {/*<img src={logoDark} alt="" height="30" className="auth-logo-dark" />
                      <img src={logoLightPng} alt="" height="30" className="auth-logo-light" />*/}
                    </Link>
                  </h3>
                  <div className="p-3">
                    <h4 className="text-muted font-size-18 mb-1 text-center">Daftar sekarang</h4>
                    <p className="text-muted text-center">Daftar akun Satria Futsal gratis sekarang.</p>
                    <AvForm
                      className="form-horizontal mt-4"
                      onValidSubmit={(e, v) => {
                      // handleValidSubmit(e, v)
                      }}
                    >
                      {props.user && props.user ? (
                        <Alert color="success">
                          Register User Successfully
                        </Alert>
                      ) : null}

                      {props.registrationError &&
                        props.registrationError ? (
                          <Alert color="danger">
                            {props.registrationError}
                          </Alert>
                        ) : null}

                      <div className="mb-3">
                        <AvField
                          id="email"
                          name="email"
						  onChange={inputHandler}
                          label="Email or Phone Number"
                          className="form-control"                     
                          type="text"
                          
                        />
                      </div>

					  {/*  <div className="mb-3">
                        <AvField
                          name="username"
                          label="Username"
                          type="text"
                          required
                          placeholder="Enter username"
					  />
                      </div>
                      <div className="mb-3">
                        <AvField
                          name="password"
                          label="Password"
                          type="password"
                          required
                          placeholder="Enter Password"
                        />
                      </div>*/}
					   {stsnohp ? (
								<SweetAlert
								  title={judul}
								   btnSize="sm"
								   size="sm"			
								   confirmBtnText="Ubah"								
								  onConfirm={() => {
									setStsnohp(false)
								  }}
								>
								{existmsg}
								</SweetAlert>
							  ) : null}
							  
							   {dataoke ? (
								<SweetAlert
									title={judul}
									btnSize="sm"
									size="sm"			
									confirmBtnText="Ya, Benar"								
									cancelBtnText="Ubah"						
									showCancel
									confirmBtnBsStyle="success"
									cancelBtnBsStyle="danger"
									onConfirm={() => {
									setStatussave()
									}}
									onCancel={() => {
									setDataoke(false)
									}}
								>
								{msgdatavalid}
								</SweetAlert>
							  ) : null}
							   {stsvalid ? (
								<SweetAlert
								  title={judul}
								  btnSize="sm"
								  size="sm"
								  onConfirm={() => {
									setStsvalid(false)
								  }}
								/>
							  ) : null}

                      <div className="mb-3 row ">

                          <button onClick={()=>prosesDaftar()} style={{width:'100%'}} className="btn btn-primary waves-effect waves-light" type="submit">Daftar</button>
                     
                      </div>
					  
                      <div className="mt-4" >
					           <p className="text-muted text-center">atau daftar dengan</p>
					  </div>
					  <div className="mt-4" >
					   <Button className="text-muted" style={{width:'100%',backgroundColor:'#fff',color:'#000'}} onClick={() => handlelogin()}>
					     <i  style={{marginRight:'5px'}} className="fab fa-google fa-1x"></i>
					   Google </Button>
			
								{/*<GoogleLogin
										   clientId="621705449112-j7drtcdnvclh9lplkho7vfi19d4pkson.apps.googleusercontent.com"
					  onSuccess={credentialResponse => {
						console.log(credentialResponse);
					  }}
					  onError={() => {
						console.log('Login Failed');
					  }}
								/>*/}
					  </div>

                      <div className="mb-0 row">
                        <div className="col-12 mt-4">
						{/*   <p className="text-muted mb-0 font-size-14">By registering you agree to the <Link to="#" className="text-primary">Terms of Use</Link></p>*/}
                        </div>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Apakah anda sudah mempunyai akun ?{" "}
                  <Link to="/login" className="text-primary">
                    {" "}
                    Login
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()}  <span className="d-none d-sm-inline-block"> - di Desain <i className="mdi mdi-heart text-danger"></i> oleh.</span>
                </p>
              </div>
            </Col>
			{/* <div>
							  {isLoading ? (
								    <div className="spinner">								
									  <div className="spinner-icon">  <i className="fa fa-spinner" /></div>
									</div>
							  ) : (
								<ul>
								  {data.map((item) => (
									<li key={item}>{item}</li>
								  ))}
								</ul>
							  )}
			</div>*/}			
          </Row>
		  {/*/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	  ////////////////////////////////////////////////////MODAL NOTIFIKASI/////////////////////////////////////////////////////////////////
	    ///////////////////////////////////////Email belum terdaftar////////////////////////////////////////////////////////////////*/}	  
		             <Modal
                       backdrop="static" keyboard={false}
                      isOpen={modal_small}
                      toggle={() => {
                        tog_small()
                      }}
					  style={{
						    position: 'absolute',
							width:'500px',
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)'
					  }}
                    >
                      <div className="modal-header border-0">
                      
                        <button
                          onClick={() => {
							  tutup()                            
                          }}
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
					  <div className="modal-body">					
						 <h5 style={{  textAlign: 'center', fontWeight: 'bold',fontSize: 18, marginTop: 0}}>Email belum terdaftar</h5>
				      </div>
					   <Row>
						   <Col 
							  style={{  textAlign: 'center'}}			   
							  sm={{
								offset: 1,
								order: 5,
								size: 10
							  }}
							>				
								 <p>Daftar menggunakan email <span style={{fontWeight:900}}>{mail}</span></p>			  
							</Col>
						 
					   </Row>
					    <Row style={{marginBottom:'30px',marginTop:'15px'}}>
						   <Col 
							  style={{  textAlign: 'center'}}			   
							  sm={{
								offset: 1,
								order: 5,
								size: 10
							  }}
							>				
								  <Button onClick={()=>daftarBygmail(mail,username)}  style={{  color: '#fff', letterSpacing: '1px', border: 'none',backgroundColor: '#4070f4', cursor: 'pointer'}}>												  
															 Daftar
														  </Button>		  
							</Col>
						 
					   </Row>
			       </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Register;

/*Register.propTypes = {
  registerUser: PropTypes.func,
  registerUserFailed: PropTypes.func,
  registrationError: PropTypes.any,
  user: PropTypes.any,
}

const mapStatetoProps = state => {
  const { user, registrationError, loading } = state.Account
  return { user, registrationError, loading }
}

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(Register)*/
