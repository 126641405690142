import React, { useEffect,useState } from 'react';
import logo from '../../assets/images/logo.png';
import StickyMenu from '../../lib/StickyMenu';
import Navigation from '../Navigation';
import apiService from '../../api/apiService'
import axios from 'axios'
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardFooter,CardHeader,CardText,Button,
  Modal,
    Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap"
import { useLocation,Link,useHistory } from "react-router-dom";


function HeaderHomeSix({ action }) {
	 const [menu, setMenu] = useState(false)
	 const [loginData, setLoginData] = useState(null);
	 	 const history = useHistory();
    useEffect(() => {
        StickyMenu();
    });

	  const userLogout =async()=>{
	  const response6 = await axios.get(apiService+'/logout',{withCredentials: true}).then(res => {
		//  console.warn('DATA LOGOOOOOOOOOOOOOOOOOOOOOOOOOOOOOUT',res.data)
		  let hasKey = res.data.hasOwnProperty('success')
		  if(hasKey){
			  if(res.data.success[0].jenis==='auth'){
				  setLoginData(null)
				 // setSharedState(0)
				 history.push("/");
			  }
		  }
	  })
  }

    return (
        <>
            <header className="appie-header-area appie-sticky">
                <div className="container">
                    <div className="header-nav-box header-nav-box-6">
                        <div className="row align-items-center">
                            <div className="col-lg-2 col-md-4 col-sm-5 col-6 order-1 order-sm-1">
                                <div className="appie-logo-box">
                                    <a href="/">
                                        <img src={logo} alt="logo" />
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-1 col-sm-1 order-3 order-sm-2">
                                <div className="appie-header-main-menu">
                                    <Navigation />
                                </div>
                            </div>
                            <div className="col-lg-4  col-md-7 col-sm-6 col-6 order-2 order-sm-3">
                                <div className="appie-btn-box text-right">

                                    <form action="#">
                                        <div className="input-box">
                                            <i className="fal fa-search"></i>
                                            <input type="text" placeholder="Search..." />
                                        </div>
                                    </form>
									{/*<a className="login-btn" href="#">
                                        <i className="fal fa-user"></i> Login
                                    </a>*/}
										            <Dropdown
													     isOpen={menu}
														toggle={() => setMenu(!menu)}
														className="d-inline-block"
													>
													  <DropdownToggle 	className="btn header-item waves-effect"
													  tag="button">
												        <a  className="login-btn far fa-home" style={{cursor:'pointer',fontSize:'25px'}}></a>
													  </DropdownToggle>
													  <DropdownMenu className="dropdown-menu">
														<DropdownItem onClick={()=>userLogout()}>
														 Log Out
														</DropdownItem>

													  </DropdownMenu>
													</Dropdown>
                                    <div
                                        onClick={(e) => action(e)}
                                        className="toggle-btn ml-30 canvas_open d-lg-none d-block"
                                    >
                                        <i className="fa fa-bars"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}

export default HeaderHomeSix;
